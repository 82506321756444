import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { CSVLink } from "react-csv";
import { DatePicker, Input, Select } from "antd";
import { Dropdown, Menu, Button, Space, Flex } from "antd";
import { DownOutlined, CaretDownOutlined } from "@ant-design/icons";
import EmptyTransactions from "../components/EmptyTransactions";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const { RangePicker } = DatePicker;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#006fa9",
    color: theme.palette.common.white,
    fontSize: "0.7rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MyTeam_member = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [bill_transations, setbill_transations] = useState([]);
  const [filter_data, setfilter_data] = useState([]);
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    let filter_trans = bill_transations;
    if (search !== "") {
      filter_trans = filter_trans.filter((transaction) => {
        return transaction.mobile.toLowerCase().match(search.toLowerCase());
      });
    }
    if (end_date !== "" && start_date !== "") {
      filter_trans = filter_trans.filter(
        (transaction) =>
          moment(transaction.createdAt).format("YYYY-MM-DD") >= start_date &&
          moment(transaction.createdAt).format("YYYY-MM-DD") <= end_date
      );
    }
    setfilter_data([...filter_trans]);
  }, [search, end_date, start_date, bill_transations]);

  const onChange = (date, dateString) => {
    setstart_date(dateString[0]);
    setend_date(dateString[1]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">Money Transfer</Menu.Item>
      <Menu.Item key="2">BBPs History</Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>
              {loginData.Full_Name} | {loginData.userName}
            </span>
          </div>
          <div className="profile_co_header_right">
            {/* <div className="header_box width2 currsor_po">
              <span>
                <FaPrint className="mx-1" />
                Back to Profile
              </span>
            </div> */}
          </div>
        </div>
        {filter_data.length === 0 ? (
          <>
            <EmptyTransactions />
          </>
        ) : (
          <>
            <div className="transaction_header">
              <RangePicker onChange={onChange} />

              <Input
                placeholder="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  width: "50vh",
                }}
              />
              <TablePagination
                component="div"
                count={0}
                page={0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 100]}
                labelRowsPerPage="Rows:"
                labelDisplayedRows={() => null}
                SelectProps={{
                  inputProps: { "aria-label": "rows" },
                  native: true,
                }}
                ActionsComponent={() => null}
              />
            </div>
            <div className="letter_content">
              <Paper sx={{ width: "100%" }}>
                <TableContainer component={Paper} className="customScrollbar">
                  <Table
                    sx={{ minWidth: 1700, border: "none" }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ border: "none" }}>
                          JOINING DATE
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          AGENT NAME
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          CODE
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          MOBILE
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          EMAIL ADDRESS
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          RANK
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ border: "none" }}>
                          BALANCE
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ border: "none" }}>
                          ACTIONS
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filter_data
                        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                        .map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              component="th"
                              width={170}
                              scope="row"
                              sx={{
                                border: "none",
                              }}
                            >
                              22-04-2024 05:03:07 PM
                            </StyledTableCell>
                            <StyledTableCell
                              width={350}
                              align="left"
                              sx={{ border: "none" }}
                            >
                              KONAKANCHI SIVARAMA SUBRAMANYA SARMA
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              width={120}
                              sx={{ border: "none" }}
                            >
                              SRPB10647
                            </StyledTableCell>
                            <StyledTableCell
                              width={120}
                              align="left"
                              sx={{ border: "none" }}
                            >
                              9949933011
                            </StyledTableCell>
                            <StyledTableCell
                              width={250}
                              align="left"
                              sx={{ border: "none" }}
                            >
                              parameshonline129@gmail.com
                            </StyledTableCell>

                            <StyledTableCell
                              width={160}
                              align="left"
                              sx={{ border: "none" }}
                            >
                              SUPER DISTRIBUTER
                            </StyledTableCell>
                            <StyledTableCell
                              width={280}
                              align="left"
                              sx={{ border: "none" }}
                            >
                              <Flex gap="small" wrap>
                                <Button>SHOW BALANCE</Button>
                                <Button>ADD FUND</Button>
                              </Flex>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              sx={{ border: "none" }}
                            >
                              <Dropdown overlay={menu}>
                                <Button>
                                  <Space>
                                    VIEW BUSINESS REPORTS
                                    <CaretDownOutlined />
                                  </Space>
                                </Button>
                              </Dropdown>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filter_data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyTeam_member;
