import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetch_bill,
  get_single_biller_info,
  pay_bill_payments,
  paybill_plan,
} from "../redux/services/ServiceSlice";
import { Typography, message } from "antd";
import moment from "moment/moment";

const { Title } = Typography;

const DTH_Services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  const { bbps_services_list, bbps_circle_list } = useSelector(
    (state) => state.services
  );

  const [biller_list_data, setbiller_list_data] = useState([]);
  const [selected_biller, setselected_biller] = useState("");
  const [biller_id, setbiller_id] = useState("");
  const [biller_error, setbiller_error] = useState("");
  const [bill_details, setbill_details] = useState("");
  const [disabled_step_1, setdisabled_step_1] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  // console.log(selected_biller, "selected_biller");

  const [dth_number, setdth_number] = useState("");
  const [dth_number_cnf, setdth_number_cnf] = useState("");
  const [dth_number_error, setdth_number_error] = useState("");

  const [rechanger_amount, setrechanger_amount] = useState("");
  const [rechanger_amount_error, setrechanger_amount_error] = useState("");
  const [rechanger_amount_cnf, setrechanger_amount_cnf] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  const [payment_success, setpayment_success] = useState("");

  useEffect(() => {
    if (btnPress === true) {
      if (selected_biller === "" && biller_id === "") {
        setbiller_error("Require");
      } else {
        setbiller_error("");
      }
      if (dth_number === "") {
        setdth_number_error("Require");
      } else {
        setdth_number_error("");
      }

      if (rechanger_amount === "") {
        setrechanger_amount_error("Require");
      } else {
        setrechanger_amount_error("");
      }
    }
  }, [btnPress, selected_biller, biller_id, dth_number, rechanger_amount]);

  useEffect(() => {
    const get_cat = async () => {
      const get_biller_info = await dispatch(get_single_biller_info("DTH"));
      if (get_biller_info.meta.requestStatus === "fulfilled") {
        const biller_data = get_biller_info.payload.sort((a, b) =>
          a.billerName > b.billerName ? 1 : -1
        );
        setbiller_list_data(biller_data);
      }
    };
    get_cat();
  }, []);

  const select_biller_press = (value) => {
    setbiller_id(value);
    const find_biller = biller_list_data.find(
      (data) => data.billerId === value
    );
    setselected_biller(find_biller);
    setbtnPress(false);
    setdth_number("");
    setdth_number_cnf("");
    setrechanger_amount("");
    setrechanger_amount_cnf("");
    setdth_number_error("");
    setrechanger_amount_error("");
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now
      .toISOString()
      .replace(/[-:.TZ]/g, "")
      .slice(0, 14);
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  const comple_dth_recharge = async (e) => {
    setdisabled_step_1(true);
    setbtnLoading(true);
    setbtnPress(true);

    const currentTime = getCurrentTime();
    const randomCharacter = generateRandomString(21);
    const newString = `${currentTime}${randomCharacter}`;

    if (selected_biller === "" && biller_id === "") {
      setbiller_error("Require");
    } else {
      setbiller_error("");
    }
    if (dth_number === "") {
      setdth_number_error("Require");
    } else {
      setdth_number_error("");
    }

    if (rechanger_amount === "") {
      setrechanger_amount_error("Require");
    } else {
      setrechanger_amount_error("");
    }

    const data_new = new Date();
    const use_date = moment(data_new).format("YYYY-MM-DD");

    if (rechanger_amount !== "" && selected_biller !== "") {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });

      let params = {};
      if (selected_biller.customerParams.length === 1) {
        params = {
          ...params,
          [selected_biller.customerParams[0].paramName]: dth_number,
        };
      } else {
        params = {
          ...params,
          [selected_biller.customerParams[0].paramName]: dth_number,

          [selected_biller.customerParams[1].paramName]: dth_number,
        };
      }

      const billpay = {
        additionalInfo: "",
        amount: rechanger_amount,
        billerId: selected_biller.billerId,
        billerName: selected_biller.billerName,
        category: selected_biller.category,
        customerMobile: loginData.mobile,
        customerMobileNo: loginData.mobile,
        customerName: loginData.Full_Name,
        customerEmailId: loginData.email,
        customerParams: params,
        merchantTrxnRefId: newString,
        billerReferenceNumber: newString,
        paymentId: "FidyPay",
        paymentMode: "WALLET",
        quickPay: "true",
        remark: "Bill Payment",
        splitPay: "",
        splitPayAmount: "",
        userid: loginData._id,
        bill_date: use_date,
      };
      const pay_bill = await dispatch(pay_bill_payments(billpay));
      if (pay_bill.payload.success === true) {
        const responce = pay_bill.payload.transactions;
        setpayment_success(pay_bill.payload.transactions);
        if (responce.code === "0x0200" || responce.code === "200") {
          messageApi.open({
            type: "success",
            content: "Recharge Succefully Done",
          });
        } else {
          messageApi.open({
            type: "error",
            content: `${pay_bill.payload.massage}`,
          });
        }
      } else {
        messageApi.open({
          type: "error",
          content: `${pay_bill.payload.massage}`,
        });
        setdisabled_step_1(false);
        setbill_details("");
      }
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } else {
      setbtnLoading(false);
      setdisabled_step_1(false);
    }
  };

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
          .image_suss_box {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_suss_box img {
  width: 20%;
  height: 50%;
}
  .class_capitalize {
  text-transform: lowercase !important;
}
  .inputs_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2vh;
  padding-top: 3vh;
  display: flex;
  justify-content: center;
  padding-bottom: 3vh;
}
.inputs_container .dn_input_box {
  width: 48%;
  display: flex;
  justify-content: center;
}
  .dn_input_box .name_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vh;
  border-bottom: 1px solid gainsboro;
}
.inputs_container .success_input_box {
  width: 98%;
  display: flex;
  justify-content: center;
}
  .name_details {
  color: #060264;
  font-size: 1rem;
  font-weight: 600;
}
.value_details {
  color: #0c0c10;
  font-size: 1rem;
  font-weight: 600;
}
         </style>
        </head>
        <body>
         <div class="main_fragnent_container">
        <div class="main_recha_container">
          <div class="inputs_container">
                <div class="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NAME :</span>
                    <span class="value_details">
                      ${payment_success.name}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NUMBER :</span>
                    <span class="value_details">
                    ${payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">AMOUNT :</span>
                    <span class="value_details">
                     ${payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">BILLER NAME:</span>
                    <span class="value_details">
                      ${payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">CATEGORY NAME:</span>
                    <span class="value_details">
                      ${payment_success.category}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT MODE :</span>
                    <span class="value_details">
                      ${payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT STATUS :</span>
                    <span class="value_details">
                      ${payment_success.status}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">DATE :</span>
                    <span class="value_details">
                      ${payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">REFERANCE ID :</span>
                    <span class="value_details">
                      ${payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">TRANSACTION ID :</span>
                    <span class="value_details">
                      ${payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div class="success_input_box">
                  <div class="name_box">
                    <span class="name_details">
                      MESSAGE / FAILED REASON :
                      <span class="value_details class_capitalize">
                        TRANSACTION IS PROCESSED AND THE MONEY HAS BEEN CREDITED
                        INTO THE BENEFICIARY ACCOUNT.
                      </span>
                    </span>
                  </div>
                </div>
                <div class="thank_note">
                  <span class="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
          
              </div>
              </div>
              </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container">
        <div className="main_recha_container">
          {payment_success === "" ? (
            <>
              <div className="inputs_container">
                <div className="radio_div_container">
                  <Title level={5} className="mx-2">
                    DTH Recharge
                  </Title>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Your DTH Operator"
                    select
                    className="input_style"
                    error={biller_error !== "" ? true : false}
                    helperText={biller_error}
                    value={biller_id}
                    onChange={(e) => select_biller_press(e.target.value)}
                    size="small"
                    disabled={disabled_step_1}
                  >
                    {biller_list_data &&
                      biller_list_data.map((data, index) => (
                        <MenuItem key={index} value={data.billerId}>
                          {data.billerName}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                {selected_biller !== "" && (
                  <>
                    <div className="dn_input_box">
                      <TextField
                        label="DTH Number"
                        type="text"
                        id="text"
                        className="input_style"
                        error={dth_number_error !== "" ? true : false}
                        helperText={dth_number_error}
                        onChange={(e) => {
                          const value = e.target.value.toLocaleUpperCase();
                          setdth_number_cnf(value);
                          if (selected_biller !== "") {
                            if (
                              value.length >=
                              selected_biller.customerParams[0].minLength
                            ) {
                              setdth_number(value);
                              setdth_number_error("");
                            } else {
                              setdth_number_error(
                                `number should be more then ${selected_biller.customerParams[0].minLength} and less then ${selected_biller.customerParams[0].maxLength}`
                              );
                            }
                          }
                        }}
                        value={dth_number_cnf}
                        autocomplete="off"
                        size="small"
                        inputProps={
                          selected_biller !== ""
                            ? {
                                minLength:
                                  selected_biller.customerParams[0].minLength,
                                maxLength:
                                  selected_biller.customerParams[0].maxLength,
                              }
                            : {}
                        }
                        disabled={disabled_step_1}
                      />
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        label="Recharge Amount"
                        type="text"
                        id="text"
                        className="input_style"
                        error={rechanger_amount_error !== "" ? true : false}
                        helperText={rechanger_amount_error}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          setrechanger_amount_cnf(
                            e.target.value.replace(/\D/g, "")
                          );
                          if (selected_biller !== "") {
                            if (
                              "maxLimit" in
                              selected_biller.paymentModesAllowed[0]
                            ) {
                              if (
                                Number(value) >=
                                  selected_biller.paymentModesAllowed[0]
                                    .minLimit &&
                                selected_biller.paymentModesAllowed[0]
                                  .maxLimit >= Number(value)
                              ) {
                                setrechanger_amount(value);
                                setrechanger_amount_error("");
                              } else {
                                setrechanger_amount_error(
                                  `Amount should be more then ${selected_biller.paymentModesAllowed[0].minLimit} and less then ${selected_biller.paymentModesAllowed[0].maxLimit}`
                                );
                              }
                            } else {
                              if (
                                Number(value) >=
                                selected_biller.paymentModesAllowed[0].minLimit
                              ) {
                                setrechanger_amount(value);
                                setrechanger_amount_error("");
                              } else {
                                setrechanger_amount_error(
                                  `Amount should be more then ${selected_biller.paymentModesAllowed[0].minLimit}`
                                );
                              }
                            }
                          }
                        }}
                        value={rechanger_amount_cnf}
                        autocomplete="off"
                        size="small"
                        inputProps={
                          selected_biller !== ""
                            ? {
                                minLength:
                                  selected_biller.paymentChannelsAllowed[0]
                                    .minLimit,
                                maxLength:
                                  selected_biller.paymentChannelsAllowed[0]
                                    .maxLimit,
                              }
                            : {}
                        }
                        disabled={disabled_step_1}
                      />
                    </div>
                  </>
                )}
                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
                <div className="button_container">
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={(e) => comple_dth_recharge(e)}
                    disabled={disabled_step_1}
                  >
                    {disabled_step_1 ? (
                      <>
                        Loading
                        <LoadingIndicator size={24} />
                      </>
                    ) : (
                      "dth reacharge"
                    )}
                  </LoadingButton>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="inputs_container">
                <div className="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">NAME :</span>
                    <span className="value_details">
                      {payment_success.name}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">NUMBER :</span>
                    <span className="value_details">
                      {payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">AMOUNT :</span>
                    <span className="value_details">
                      {payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">BILLER NAME:</span>
                    <span className="value_details">
                      {payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">CATEGORY NAME:</span>
                    <span className="value_details">
                      {payment_success.category}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">PAYMENT MODE :</span>
                    <span className="value_details">
                      {payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">PAYMENT STATUS :</span>
                    <span className="value_details">
                      {payment_success.status}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">DATE :</span>
                    <span className="value_details">
                      {payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">REFERANCE ID :</span>
                    <span className="value_details">
                      {payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">TRANSACTION ID :</span>
                    <span className="value_details">
                      {payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div className="success_input_box">
                  <div className="name_box">
                    <span className="name_details">
                      MESSAGE / FAILED REASON :{" "}
                      <span className="value_details class_capitalize">
                        {payment_success.status_description}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="thank_note">
                  <span className="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
                <div className="profile_co_header_right">
                  <div
                    className="header_box width2 currsor_po"
                    onClick={() => handlePrint()}
                  >
                    <span>Print Receipt</span>
                  </div>
                  <div
                    className="header_box width1 currsor_po"
                    onClick={() => handlePrint()}
                  >
                    <span>Download Receipt</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DTH_Services;
