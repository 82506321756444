import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <section
        className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"
        data-bg-color="#16243E"
        style={{ background: "#6222CC" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title">Contact</h3>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <div className="breadcrumb__list text-center text-sm-end">
                  <span>
                    <Link to="/">Home</Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa-regular fa-angle-right" />
                  </span>
                  <span>Contact</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-contact-breadcrumb-area pt-120 pb-120">
        <div className="container">
          <div className="tp-contact-breadcrumb-box">
            {/* <div className="tp-contact-breadcrumb-social">
              <a to="#">
                <i className="fab fa-facebook-f" />
              </a>
              <a to="#">
                <i className="fab fa-twitter" />
              </a>
              <a to="#">
                <i className="fa-brands fa-instagram" />
              </a>
            </div> */}
            <div className="row">
              <div className="col-lg-7 mt-lg-5">
                <div className="tp-contact-breadcrumb-content">
                  <div className="main">
                    <img src="assets/img/contact/EnquiryNow.png" alt />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="tp-contact-breadcrumb-wrapper">
                  <div className="tp-contact-breadcrumb-item mb-40 d-flex">
                    <div className="tp-contact-breadcrumb-item-icon">
                      <span>
                        <i className="fa-solid fa-location-dot" />
                      </span>
                    </div>
                    <div className="tp-contact-breadcrumb-item-content">
                      <h3 className="tp-contact-breadcrumb-item-title">
                        Address
                      </h3>
                      <h6>SUPER REX PRODUCTS Private Ltd</h6>
                      <h6>pay2pay.in</h6>
                      <p>
                        12-4-83/A, Pragathi nagar line 5, Upper sri rama bike
                        point, Near sri Laxmi Narasimha swamy temple, Moosapet,
                        Hyderabad , Telengana - 500018
                      </p>
                    </div>
                  </div>
                  <div className="tp-contact-breadcrumb-item mb-40 d-flex">
                    <div className="tp-contact-breadcrumb-item-icon">
                      <span>
                        <i className="fa-solid fa-envelope" />
                      </span>
                    </div>
                    <div className="tp-contact-breadcrumb-item-content">
                      <h3 className="tp-contact-breadcrumb-item-title">
                        Email
                      </h3>
                      <a
                        href="mailto:info@pay2pay.in?body=My custom mail body"
                        target="_blank"
                      >
                        <span
                          className="__cf_email__"
                          data-cfemail="96e5f7e4f7b8f5e4e3ecd6f3eef7fbe6faf3b8f5f9fb"
                        >
                          info@pay2pay.in
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="tp-contact-breadcrumb-item d-flex">
                    <div className="tp-contact-breadcrumb-item-icon">
                      <span>
                        <i className="fa-solid fa-phone" />
                      </span>
                    </div>
                    <div className="tp-contact-breadcrumb-item-content">
                      <h3 className="tp-contact-breadcrumb-item-title">
                        Phone
                      </h3>
                      <a href="tel:917013914767">(+91) 7013914767</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
