import React from 'react'

const YearsExperience = () => {
    return (
        <>

            <div className="container-xxl fact py-5 wow fadeInUp" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp',backgroundColor:"rgb(98, 34, 204)" }}>
                <div className="container py-5 px-lg-5">
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeIn' }}>
                            <i className="fa fa-certificate fa-3x mb-3" style={{color:"white"}} />
                            <h1 className="text-white mb-2" data-toggle="counter-up">1234</h1>
                            <p className="text-white mb-0" style={{textAlign:"center"}}>Years Experience</p>
                        </div>
                        <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeIn' }}>
                            <i className="fa fa-users-cog fa-3x  mb-3" style={{color:"white"}}/>
                            <h1 className="text-white mb-2" data-toggle="counter-up">1234</h1>
                            <p className="text-white mb-0" style={{textAlign:"center"}}>Team Members</p>
                        </div>
                        <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeIn' }}>
                            <i className="fa fa-users fa-3x  mb-3" style={{color:"white"}}/>
                            <h1 className="text-white mb-2" data-toggle="counter-up">1234</h1>
                            <p className="text-white mb-0" style={{textAlign:"center"}}>Satisfied Clients</p>
                        </div>
                        <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s" style={{ visibility: 'visible', animationDelay: '0.7s', animationName: 'fadeIn' }}>
                            <i className="fa fa-check fa-3x  mb-3" style={{color:"white"}}/>
                            <h1 className="text-white mb-2" data-toggle="counter-up">1234</h1>
                            <p className="text-white mb-0" style={{textAlign:"center"}}>Compleate Projects</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default YearsExperience