import React from "react";
import HomeBanner from "./home/HomeBanner";
import HomeAbout from "./home/HomeAbout";
import ServicesBBPS from "./home/ServicesBBPS";

import { useDispatch, useSelector } from "react-redux";
import YearsExperience from "./home/YearsExperience";

const Home = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  return (
    <>
      <HomeBanner />
      <HomeAbout />
      <YearsExperience />
      {/* <ServicesBBPS /> */}
    </>
  );
};

export default Home;
