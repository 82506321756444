import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
    bbps_services_list: JSON.parse(localStorage.getItem("bbps_services_list"))
        ? JSON.parse(localStorage.getItem("bbps_services_list")).sort((a, b) =>
            a > b ? 1 : -1
        )
        : "",
    isServiceLoading: true,

    bbps_circle_list: JSON.parse(localStorage.getItem("bbps_circle_list"))
        ? JSON.parse(localStorage.getItem("bbps_circle_list"))
        : "",
    isCircleLoading: true,

    bill_transations: localStorage.getItem("bill_transations")
        ? JSON.parse(localStorage.getItem("bill_transations"))
        : [],
    bill_transationsLoading: true,
    isbill_transationsAvailable: localStorage.getItem("bill_transations")
        ? true
        : false,
};

export const getBbps_services = createAsyncThunk(
    "services/getBbps_services",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/admin/biller-list`;
            const resp = await axios(url);
            return resp.data.billerlist;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);
export const get_circle_list = createAsyncThunk(
    "services/get_circle_list",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/admin/circle-list`;
            const resp = await axios(url);
            return resp.data.circlesInfo;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);
export const get_single_biller_info = createAsyncThunk(
    "services/get_single_biller_info",
    async (biller, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/admin/single-biller-details/${biller}`;
            const resp = await axios(url);
            return resp.data.biller;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);
export const get_plance_by_number = createAsyncThunk(
    "services/get_plance_by_number",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/admin/single-number-pans/${number}`;
            const resp = await axios(url);
            return resp.data.plans;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);

export const get_all_transactions = createAsyncThunk(
    "services/get_all_transactions",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/transactions/all-transactions`;
            const resp = await axios(url);
            return resp.data.transactions;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);
export const get_user_transactions = createAsyncThunk(
    "services/get_user_transactions",
    async (userid, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/transactions/user-transactions-by-id/${userid}`;
            const resp = await axios(url);
            return resp.data.transactions;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);

export const pay_bill_payments = createAsyncThunk(
    "services/pay_bill_payments",
    async (formData, thunkAPI) => {
        try {
            const config = {
                maxBodyLength: Infinity,
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/admin/bill-payment`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("student Not create");
        }
    }
);
export const paybill_plan = createAsyncThunk(
    "services/paybill_plan",
    async (formData, thunkAPI) => {
        try {
            const config = {
                maxBodyLength: Infinity,
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/admin/payment-single-pans`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("student Not create");
        }
    }
);
export const fetch_bill = createAsyncThunk(
    "services/fetch_bill",
    async (formData, thunkAPI) => {
        try {
            const config = {
                maxBodyLength: Infinity,
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/admin/fetch_bill`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("student Not create");
        }
    }
);

export const generate_aadhar_otp = createAsyncThunk(
    "services/generate_aadhar_otp",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "Content-Type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/admin/generate-aadhar-otp`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("category Not create");
        }
    }
);
export const verify_aadhar_otp = createAsyncThunk(
    "services/verify_aadhar_otp",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "Content-Type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/admin/verify-aadhar-otp`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("category Not create");
        }
    }
);
export const verify_pancard_number = createAsyncThunk(
    "services/verify_pancard_number",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "Content-Type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/admin/verify-pancard-number`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("category Not create");
        }
    }
);

const servicesSlice = createSlice({
    name: "services",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getBbps_services.pending, (state) => {
                state.isServiceLoading = true;
            })
            .addCase(getBbps_services.fulfilled, (state, action) => {
                state.bbps_services_list = action.payload.sort((a, b) =>
                    a > b ? 1 : -1
                );
                localStorage.setItem("bbps_services_list", JSON.stringify(state.bbps_services_list));
                state.isServiceLoading = false;
            })
            .addCase(getBbps_services.rejected, (state) => {
                state.isServiceLoading = true;
            });
        builder
            .addCase(get_circle_list.pending, (state) => {
                state.isCircleLoading = true;
            })
            .addCase(get_circle_list.fulfilled, (state, action) => {
                state.bbps_circle_list = action.payload.sort((a, b) =>
                    a.circleName > b.circleName ? 1 : -1
                );
                localStorage.setItem("bbps_circle_list", JSON.stringify(state.bbps_circle_list));
                state.isCircleLoading = false;
            })
            .addCase(get_circle_list.rejected, (state) => {
                state.isCircleLoading = true;
            });
        builder
            .addCase(paybill_plan.pending, (state) => {
                state.bill_transationsLoading = true;
            })
            .addCase(paybill_plan.fulfilled, (state, action) => {
                state.bill_transations = [...state.bill_transations, action.payload.transactions]
                localStorage.setItem("bill_transations", JSON.stringify(state.bill_transations));
                state.bill_transationsLoading = false;
            })
            .addCase(paybill_plan.rejected, (state) => {
                state.bill_transationsLoading = true;
            });
        builder
            .addCase(pay_bill_payments.pending, (state) => {
                state.bill_transationsLoading = true;
            })
            .addCase(pay_bill_payments.fulfilled, (state, action) => {
                state.bill_transations = [...state.bill_transations, action.payload.transactions]
                localStorage.setItem("bill_transations", JSON.stringify(state.bill_transations));
                state.bill_transationsLoading = false;
            })
            .addCase(pay_bill_payments.rejected, (state) => {
                state.bill_transationsLoading = true;
            });
        builder
            .addCase(get_user_transactions.pending, (state) => {
                state.bill_transationsLoading = true;
            })
            .addCase(get_user_transactions.fulfilled, (state, action) => {
                state.bill_transations = action.payload
                localStorage.setItem("bill_transations", JSON.stringify(state.bill_transations));
                state.bill_transationsLoading = false;
                state.isbill_transationsAvailable = true;
            })
            .addCase(get_user_transactions.rejected, (state) => {
                state.bill_transationsLoading = true;
            });
    }
});

export const { } =
    servicesSlice.actions;
export default servicesSlice.reducer;
