import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactionByUserId: [].slice().reverse(),
  transactionTotalAmount: 0,
  commissionHistory: [],
  paymentGatewayHistory: [],
  rechargeHistory: [],
  moneyTransferHistory: [],
  mainWalletHistory: [],
  Loading: false,
};

const TransactionSlice = createSlice({
  name: "Transaction",
  initialState,
  reducers: {
    transactionbyClientId(state, action) {
      state.transactionByUserId = action.payload.slice().reverse();

      state.transactionTotalAmount = 0;

      for (let i = 0; i < state.transactionByUserId.length; i++) {
        state.transactionTotalAmount =
          Number(state.transactionTotalAmount) +
          Number(state.transactionByUserId[i].amountafterDeduction);
      }

      state.transactionLoading = true;
    },
    removetransactionbyClientId(state, action) {
      state.transactionByUserId = []
      state.transactionTotalAmount = 0
      state.commissionHistory = []
      state.paymentGatewayHistory = []
      state.rechargeHistory = []
      state.moneyTransferHistory = []
      state.mainWalletHistory = []
    },

    newtransaction(state, action) {
      state.transactionByUserId = [
        ...state.transactionByUserId,
        action.payload,
      ];
      state.commissionHistory = state.transactionByUserId.filter(
        (transation) => transation.statustext === "Referral Deposite"
      );
      state.paymentGatewayHistory = state.transactionByUserId.filter(
        (transation) => transation.statustext === "Deposite"
      );

      state.rechargeHistory = state.transactionByUserId.filter(
        (transation) => transation.statustext === "Recharge Deposite"
      );

      state.moneyTransferHistory = state.transactionByUserId.filter(
        (transation) => transation.statustext === "Withdrawl"
      );

      state.mainWalletHistory = state.transactionByUserId.filter(
        (transation) => transation.statustext === "Deposite"
      );

      state.transactionTotalAmount = 0;

      for (let i = 0; i < state.transactionByUserId.length; i++) {
        state.transactionTotalAmount =
          Number(state.transactionTotalAmount) +
          Number(state.transactionByUserId[i].amountafterDeduction);
      }
      state.bankDetailsLoading = true;
    },
  },
});

export const { transactionbyClientId, newtransaction, removetransactionbyClientId } =
  TransactionSlice.actions;
export default TransactionSlice.reducer;
