import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import moment from "moment/moment";

const Agent_Certificates = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
              .letter_content {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1vh;
}
.certificates_content {
  width: 95%;
  height: 40vh;
  padding: 2rem 0.5rem;
  border: 5px solid goldenrod;
  display: flex;
  justify-content: center;
  align-items: center;
}
.certificates_box {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.certificates_header {
  color: goldenrod;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
  width: 100%;
  margin-top: 1vh;
}
.certificates_disc {
  color: #00324d;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
  width: 100%;
  margin: 2vh 0;
}
.certificates_approved {
  width: 100%;
  height: 15vh;
  margin: 2vh 0;
  border-top: 1px dashed #00324d;
  border-bottom: 1px dashed #00324d;
  display: flex;
  flex-direction: column;
}
.certificates_approved_header {
  color: #006eb4;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
  padding: 2vh 0;
}
.certificates_approved_descr {
  color: #00324d;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
  padding: 1vh 0;
  text-transform: uppercase;
}
.trans_capi {
  text-transform: uppercase;
}

.cerificate_footer {
  width: 100%;
  height: 12.5vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.certi_footer_box {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}


.certi_footer_box p {
  color: #00324d;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
}
.certi_footer_box1 {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
   position: relative;
}
  .certi_footer_box1 img {
  position: absolute;
  z-index: 99;
  bottom: 0;
  height: 4vh;
  margin-bottom: 2vh;
}
.certi_footer_box1 p {
  color: #00324d;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
}
              </style>
            </head>
            <body>
            <div class="letter_content">
 <div class="certificates_content">
            <div class="certificates_box">
              <span class="certificates_header">
                Certificate of Authorization
              </span>
              <span class="certificates_header">CSP Point</span>
              <span class="certificates_disc">This is to Certify that</span>
              <span class="certificates_header trans_capi">
                ${loginData.Full_Name}
              </span>
              <div class="certificates_approved">
                <span class="certificates_approved_header">
                  Is Appointed as the ${loginData.role} of
                </span>
                <span class="certificates_approved_descr">
                  ${loginData.Complete_Address}
                </span>
              </div>
              <div class="cerificate_footer">
               <div class="certi_footer_box">
                  <p>${moment(loginData.createdAt).format("DD-MM-YYYY")}</p>
                  <p>Issued Date</p>
                </div>
                <div class="certi_footer_box">
                  <p>${loginData.userName}</p>
                  <p>CSP Code</p>
                </div>
               <div class="certi_footer_box1">
                 <img src="/assets/img/logo/sign.png" alt="" />
                  <p>Authorized Signature</p>
                </div>
              </div>
            </div>
          </div>
          </div>
            </body>
          </html>
        `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>
              {loginData.Full_Name} | {loginData.userName}
            </span>
          </div>
          <div className="profile_co_header_right">
            <div
              className="header_box width2 currsor_po"
              onClick={() => handlePrint()}
            >
              <span>
                <FaPrint className="mx-1" />
                Print
              </span>
            </div>
          </div>
        </div>
        <div className="letter_content">
          <div className="certificates_content">
            <div className="certificates_box">
              <span className="certificates_header">
                Certificate of Authorization
              </span>
              <span className="certificates_header">CSP Point</span>
              <span className="certificates_disc">This is to Certify that</span>
              <span className="certificates_header trans_capi">
                {loginData.Full_Name}{" "}
              </span>
              <div className="certificates_approved">
                <span className="certificates_approved_header">
                  Is Appointed as the {loginData.role} of
                </span>
                <span className="certificates_approved_descr">
                  {loginData.Complete_Address}
                </span>
              </div>
              <div className="cerificate_footer">
                <div className="certi_footer_box">
                  <p>{moment(loginData.createdAt).format("DD-MM-YYYY")}</p>
                  <p>Issued Date</p>
                </div>
                <div className="certi_footer_box">
                  <p>{loginData.userName}</p>
                  <p>CSP Code</p>
                </div>
                <div className="certi_footer_box1">
                  <img src="/assets/img/logo/sign.png" alt="" />
                  <p>Authorized Signature</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Agent_Certificates;
