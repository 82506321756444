import React, { useState, useEffect, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  new_user_Update,
  setRemove_new_user_details,
  set_aadhar_back,
  set_aadhar_back_loading,
  set_cancelCheck,
  set_cancelCheck_loading,
  set_pan_card,
  set_pan_card_loading,
  set_shop_aadress,
  set_shop_aadress_loading,
  set_shop_photo,
  set_shop_photo_loading,
  set_user_selfie,
  set_user_selfie_loading,
  setaadhar_front,
  setaadhar_front_loading,
  user_image_uploade,
} from "../../redux/authentication/AuthenticationSlice";

const ImageUploade = () => {
  const dispatch = useDispatch();

  const {
    new_user_registation,
    aadhar_front,
    isaadhar_frontLoading,
    aadhar_back,
    isaadhar_backLoading,
    pan_card,
    ispan_cardLoading,
    shop_aadress,
    isshop_aadressLoading,
    shop_photo,
    isshop_photoLoading,
    cancelCheck,
    iscancelCheckLoading,
    user_selfie,
    isuser_selfieLoading,
  } = useSelector((store) => store.Authentication);

  const [aadhar_frontError, setaadhar_frontError] = useState("");
  const [aadhar_backError, setaadhar_backError] = useState("");
  const [pan_cardError, setpan_cardError] = useState("");
  const [shop_aadressError, setshop_aadressError] = useState("");
  const [shop_photoError, setshop_photoError] = useState("");
  const [cancelCheckError, setcancelCheckError] = useState("");
  const [user_selfieError, setuser_selfieError] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  const aadhar_frontRef = useRef(null);
  const aadhar_backRef = useRef(null);
  const pan_cardRef = useRef(null);
  const shop_address_proofRef = useRef(null);
  const shop_photoRef = useRef(null);
  const user_photoRef = useRef(null);
  const cancel_chequeRef = useRef(null);

  useEffect(() => {
    if (btnPress === true) {
      if (aadhar_front === "") {
        setaadhar_frontError("Required");
      } else {
        setaadhar_frontError("");
      }
      if (aadhar_front === "") {
        setaadhar_frontError("Required");
      } else {
        setaadhar_frontError("");
      }
      if (aadhar_back === "") {
        setaadhar_backError("Required");
      } else {
        setaadhar_backError("");
      }
      if (pan_card === "") {
        setpan_cardError("Required");
      } else {
        setpan_cardError("");
      }
      if (shop_aadress === "") {
        setshop_aadressError("Required");
      } else {
        setshop_aadressError("");
      }
      if (shop_photo === "") {
        setshop_photoError("Required");
      } else {
        setshop_photoError("");
      }
      if (cancelCheck === "") {
        setcancelCheckError("Required");
      } else {
        setcancelCheckError("");
      }
      if (user_selfie === "") {
        setuser_selfieError("Required");
      } else {
        setuser_selfieError("");
      }
    }
  }, [
    btnPress,
    aadhar_front,
    aadhar_back,
    pan_card,
    shop_aadress,
    shop_photo,
    cancelCheck,
    user_selfie,
  ]);

  const aadharFrontClick = () => {
    aadhar_frontRef.current.click();
  };
  const aadhar_back_click = () => {
    aadhar_backRef.current.click();
  };
  const pan_click = () => {
    pan_cardRef.current.click();
  };
  const ahop_address_click = () => {
    shop_address_proofRef.current.click();
  };
  const shop_photoclick = () => {
    shop_photoRef.current.click();
  };
  const user_photo_click = () => {
    user_photoRef.current.click();
  };
  const cancel_check = () => {
    cancel_chequeRef.current.click();
  };

  const userSelfi_ImageChange = (e) => {
    dispatch(set_user_selfie_loading());
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const uploade_image = await dispatch(
            user_image_uploade({ shopimage: reader.result })
          );
          if (uploade_image.payload.success) {
            const image_get = uploade_image.payload.shopimage;
            dispatch(set_user_selfie(image_get));
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const cancel_checkImageChange = (e) => {
    dispatch(set_cancelCheck_loading());
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const uploade_image = await dispatch(
            user_image_uploade({ shopimage: reader.result })
          );
          if (uploade_image.payload.success) {
            const image_get = uploade_image.payload.shopimage;
            dispatch(set_cancelCheck(image_get));
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const shopImageChange = (e) => {
    dispatch(set_shop_photo_loading());
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const uploade_image = await dispatch(
            user_image_uploade({ shopimage: reader.result })
          );
          if (uploade_image.payload.success) {
            const image_get = uploade_image.payload.shopimage;
            dispatch(set_shop_photo(image_get));
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const shopAddressImageChange = (e) => {
    dispatch(set_shop_aadress_loading());
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const uploade_image = await dispatch(
            user_image_uploade({ shopimage: reader.result })
          );
          if (uploade_image.payload.success) {
            const image_get = uploade_image.payload.shopimage;
            dispatch(set_shop_aadress(image_get));
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const pancardImageChange = (e) => {
    dispatch(set_pan_card_loading());
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const uploade_image = await dispatch(
            user_image_uploade({ shopimage: reader.result })
          );
          if (uploade_image.payload.success) {
            const image_get = uploade_image.payload.shopimage;
            dispatch(set_pan_card(image_get));
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const aadharBackImageChange = (e) => {
    dispatch(set_aadhar_back_loading());
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const uploade_image = await dispatch(
            user_image_uploade({ shopimage: reader.result })
          );
          if (uploade_image.payload.success) {
            const image_get = uploade_image.payload.shopimage;
            dispatch(set_aadhar_back(image_get));
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const aadharFrontImageChange = (e) => {
    dispatch(setaadhar_front_loading());
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const uploade_image = await dispatch(
            user_image_uploade({ shopimage: reader.result })
          );
          if (uploade_image.payload.success) {
            const image_get = uploade_image.payload.shopimage;
            dispatch(setaadhar_front(image_get));
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const user_Update_click = async () => {
    setbtnLoading(true);
    setbtnPress(true);

    if (
      aadhar_front !== "" &&
      aadhar_back !== "" &&
      pan_card !== "" &&
      shop_aadress !== "" &&
      shop_photo !== "" &&
      cancelCheck !== "" &&
      user_selfie !== ""
    ) {
      const form_data = {
        aadhar_front: aadhar_front,
        aadhar_back: aadhar_back,
        pan_card: pan_card,
        shop_address_proof: shop_aadress,
        shop_photo: shop_photo,
        user_photo: user_selfie,
        cancel_cheque: cancelCheck,
        formStep: 4,
        userId: new_user_registation._id,
      };
      const new_user_Updates = await dispatch(new_user_Update(form_data));
      if (new_user_Updates.payload.success) {
        dispatch(set_aadhar_back(""));
        dispatch(set_cancelCheck(""));
        dispatch(set_pan_card(""));
        dispatch(set_shop_aadress(""));
        dispatch(set_shop_photo(""));
        dispatch(set_user_selfie(""));
        dispatch(setaadhar_front(""));

        dispatch(setRemove_new_user_details(""));

        setbtnLoading(false);
        setbtnPress(false);
      }
    } else {
      setbtnLoading(false);
    }
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      <div className="inputs_container">
        <div
          className="dn_input_box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6>Aadhar Front Side</h6>
          {isaadhar_frontLoading === false ? (
            <>
              <div className="image_div_box">
                <div className="loading_image">
                  <span>File Uploading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {aadhar_front === "" ? (
                <>
                  <div
                    className={
                      aadhar_frontError !== ""
                        ? "input_style input_container_div_error"
                        : "input_style input_container_div"
                    }
                  >
                    <button
                      onClick={aadharFrontClick}
                      className="uploade_image"
                    >
                      <i
                        className="fa fa-upload"
                        aria-hidden="true"
                        style={{ color: "rgb(98, 34, 204)" }}
                      ></i>{" "}
                      Upload File
                    </button>
                    {aadhar_frontError !== "" && (
                      <span>{aadhar_frontError}</span>
                    )}

                    <input
                      type="file"
                      ref={aadhar_frontRef}
                      style={{ display: "none" }}
                      onChange={(e) => aadharFrontImageChange(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="image_div_box" onClick={aadharFrontClick}>
                    <img src={aadhar_front} className="uploade_image_box" />
                    <input
                      type="file"
                      ref={aadhar_frontRef}
                      style={{ display: "none" }}
                      onChange={(e) => aadharFrontImageChange(e)}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div
          className="dn_input_box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6>Aadhar Back Side</h6>
          {isaadhar_backLoading === false ? (
            <>
              <div className="image_div_box">
                <div className="loading_image">
                  <span>File Uploading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {aadhar_back === "" ? (
                <>
                  <div
                    className={
                      aadhar_backError !== ""
                        ? "input_style input_container_div_error"
                        : "input_style input_container_div"
                    }
                  >
                    <button
                      onClick={aadhar_back_click}
                      className="uploade_image"
                    >
                      <i
                        className="fa fa-upload"
                        aria-hidden="true"
                        style={{ color: "rgb(98, 34, 204)" }}
                      ></i>{" "}
                      Upload File
                    </button>
                    {aadhar_backError !== "" && (
                      <span>{aadhar_backError}</span>
                    )}
                    <input
                      type="file"
                      ref={aadhar_backRef}
                      style={{ display: "none" }}
                      onChange={(e) => aadharBackImageChange(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="image_div_box" onClick={aadhar_back_click}>
                    <img src={aadhar_back} className="uploade_image_box" />
                    <input
                      type="file"
                      ref={aadhar_backRef}
                      style={{ display: "none" }}
                      onChange={(e) => aadharBackImageChange(e)}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div
          className="dn_input_box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6>Pan Card</h6>
          {ispan_cardLoading === false ? (
            <>
              <div className="image_div_box">
                <div className="loading_image">
                  <span>File Uploading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {pan_card === "" ? (
                <>
                  <div
                    className={
                      pan_cardError !== ""
                        ? "input_style input_container_div_error"
                        : "input_style input_container_div"
                    }
                  >
                    <button onClick={pan_click} className="uploade_image">
                      <i
                        className="fa fa-upload"
                        aria-hidden="true"
                        style={{ color: "rgb(98, 34, 204)" }}
                      ></i>{" "}
                      Upload File
                    </button>
                    {pan_cardError !== "" && (
                      <span>{pan_cardError}</span>
                    )}
                    <input
                      type="file"
                      ref={pan_cardRef}
                      style={{ display: "none" }}
                      onChange={(e) => pancardImageChange(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="image_div_box" onClick={pan_click}>
                    <img src={pan_card} className="uploade_image_box" />
                    <input
                      type="file"
                      ref={pan_cardRef}
                      style={{ display: "none" }}
                      onChange={(e) => pancardImageChange(e)}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div
          className="dn_input_box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6>Shop Address</h6>
          {isshop_aadressLoading === false ? (
            <>
              <div className="image_div_box">
                <div className="loading_image">
                  <span>File Uploading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {shop_aadress === "" ? (
                <>
                  <div
                    className={
                      shop_aadressError !== ""
                        ? "input_style input_container_div_error"
                        : "input_style input_container_div"
                    }
                  >
                    <button
                      onClick={ahop_address_click}
                      className="uploade_image"
                    >
                      <i
                        className="fa fa-upload"
                        aria-hidden="true"
                        style={{ color: "rgb(98, 34, 204)" }}
                      ></i>{" "}
                      Upload File
                    </button>
                    {shop_aadressError !== "" && (
                      <span>{shop_aadressError}</span>
                    )}
                    <input
                      type="file"
                      ref={shop_address_proofRef}
                      style={{ display: "none" }}
                      onChange={(e) => shopAddressImageChange(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="image_div_box" onClick={ahop_address_click}>
                    <img src={shop_aadress} className="uploade_image_box" />
                    <input
                      type="file"
                      ref={shop_address_proofRef}
                      style={{ display: "none" }}
                      onChange={(e) => shopAddressImageChange(e)}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div
          className="dn_input_box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6>Shop Photo</h6>
          {isshop_photoLoading === false ? (
            <>
              <div className="image_div_box">
                <div className="loading_image">
                  <span>File Uploading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {shop_photo === "" ? (
                <>
                  <div
                    className={
                      shop_photoError !== ""
                        ? "input_style input_container_div_error"
                        : "input_style input_container_div"
                    }
                  >
                    <button onClick={shop_photoclick} className="uploade_image">
                      <i
                        className="fa fa-upload"
                        aria-hidden="true"
                        style={{ color: "rgb(98, 34, 204)" }}
                      ></i>{" "}
                      Upload File
                    </button>
                    {shop_photoError !== "" && (
                      <span>{shop_photoError}</span>
                    )}
                    <input
                      type="file"
                      ref={shop_photoRef}
                      style={{ display: "none" }}
                      onChange={(e) => shopImageChange(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="image_div_box" onClick={shop_photoclick}>
                    <img src={shop_photo} className="uploade_image_box" />
                    <input
                      type="file"
                      ref={shop_photoRef}
                      style={{ display: "none" }}
                      onChange={(e) => shopImageChange(e)}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div
          className="dn_input_box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6>Agent Selfiee</h6>
          {isuser_selfieLoading === false ? (
            <>
              <div className="image_div_box">
                <div className="loading_image">
                  <span>File Uploading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {user_selfie === "" ? (
                <>
                  <div
                    className={
                      user_selfieError !== ""
                        ? "input_style input_container_div_error"
                        : "input_style input_container_div"
                    }
                  >
                    <button
                      onClick={user_photo_click}
                      className="uploade_image"
                    >
                      <i
                        className="fa fa-upload"
                        aria-hidden="true"
                        style={{ color: "rgb(98, 34, 204)" }}
                      ></i>{" "}
                      Upload File
                    </button>
                    {user_selfieError !== "" && (
                      <span>{user_selfieError}</span>
                    )}
                    <input
                      type="file"
                      ref={user_photoRef}
                      style={{ display: "none" }}
                      onChange={(e) => userSelfi_ImageChange(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="image_div_box" onClick={user_photo_click}>
                    <img src={user_selfie} className="uploade_image_box" />
                    <input
                      type="file"
                      ref={user_photoRef}
                      style={{ display: "none" }}
                      onChange={(e) => userSelfi_ImageChange(e)}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div
          className="dn_input_box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6>Cancel Cheque</h6>
          {iscancelCheckLoading === false ? (
            <>
              <div className="image_div_box">
                <div className="loading_image">
                  <span>File Uploading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {cancelCheck === "" ? (
                <>
                  <div
                    className={
                      cancelCheckError !== ""
                        ? "input_style input_container_div_error"
                        : "input_style input_container_div"
                    }
                  >
                    <button onClick={cancel_check} className="uploade_image">
                      <i
                        className="fa fa-upload"
                        aria-hidden="true"
                        style={{ color: "rgb(98, 34, 204)" }}
                      ></i>{" "}
                      Upload File
                    </button>
                    {cancelCheckError !== "" && (
                      <span>{cancelCheckError}</span>
                    )}
                    <input
                      type="file"
                      ref={cancel_chequeRef}
                      style={{ display: "none" }}
                      onChange={(e) => cancel_checkImageChange(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="image_div_box" onClick={cancel_check}>
                    <img src={cancelCheck} className="uploade_image_box" />
                    <input
                      type="file"
                      ref={cancel_chequeRef}
                      style={{ display: "none" }}
                      onChange={(e) => cancel_checkImageChange(e)}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className="dn_input_box">
          <div className="input_style"></div>
        </div>
        <div className="button_container">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={(e) => user_Update_click(e)}
            disabled={btnLoading}
          >
            {btnLoading ? (
              <>
                LOading
                <LoadingIndicator size={24} />
              </>
            ) : (
              "Submit"
            )}
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default ImageUploade;
