
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBbps_services, get_circle_list, get_user_transactions } from './redux/services/ServiceSlice';
import MainLayOut from './components/MainLayOut';
import Home from './pages/Home';
import Services from './pages/Services';
import Service from './pages/home/Service';
import About from './pages/About';
import Contact from './pages/Contact';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CancellationRefund from './pages/CancellationRefund';
import Login from './pages/Login';
import Dashboard_Layout from './components/dashboard/Dashboard_Layout';
import Dashboard from './pages/Dashboard';
import Mobile_Prepaid_services from './pages/Mobile_Prepaid_services';
import Electrical_Services from './pages/Electrical_Services';
import Credit_card_bill_pay from './pages/Credit_card_bill_pay';
import Fastag_Services from './pages/Fastag_Services';
import DTH_Services from './pages/DTH_Services';
import LOan_Repay from './pages/LOan_Repay';
import My_Profile_Details from './pages/My_Profile_Details';
import Agent_Registation from './pages/Agent_Registation';
import BbpsTransactions from './pages/BbpsTransactions';
import WelcomeLetter from './pages/WelcomeLetter';
import ID_Card from './pages/ID_Card';
import ChangwPassword from './pages/ChangwPassword';
import ChangeMpin from './pages/ChangeMpin';
import Agent_Certificates from './pages/Agent_Certificates';
import Bbps_Bill_Info from "./pages/Bbps_Bill_Info";
import Documents from "./pages/Documents";
import PostpaidMobile from "./pages/PostpaidMobile";
import Verify_Mpin from "./pages/Verify_Mpin";
import MyTeam_member from "./pages/MyTeam_member";
import FindRemitter from "./pages/FindRemitter";
import TransferList from "./pages/TransferList";
import PendingAgents from "./pages/PendingAgents";
import { get_pending_users } from "./redux/authentication/AuthenticationSlice";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const { loginData,
    isAuth,
    pending_users,
    pending_usersLoading,
    ispending_usersAvailable
  } = useSelector((state) => state.Authentication);
  const {
    bill_transationsLoading,
    bill_transations,
    isbill_transationsAvailable } = useSelector((state) => state.services);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isbill_transationsAvailable || bill_transations.length === 0) {
      dispatch(get_user_transactions(loginData._id));
    }
    if (!ispending_usersAvailable || pending_users.length === 0) {
      dispatch(get_pending_users(loginData.userName));
    }
  }, []);
  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (isAuth === true) {

        if (bill_transationsLoading) {
          dispatch(get_user_transactions(loginData._id));
        }

        if (pending_usersLoading) {
          dispatch(get_pending_users(loginData.userName));
        }
        
      }

    }, 20000);
  }, [isAuth]);
  useEffect(() => {
    dispatch(getBbps_services())
    dispatch(get_circle_list())
  }, []);

  useEffect(() => {
    if (
      window.location.href === "http://pay2pay.in/" ||
      window.location.href === "http://pay2pay.in" ||
      window.location.href === "http://pay2pay.in/" ||
      window.location.href === "pay2pay.in" ||
      window.location.href === "pay2pay.in/" ||
      window.location.href === "http://pay2pay.in"
      // ||
      // window.location.href === "http://localhost:3000/#/" ||
      // window.location.href === "http://localhost:3000/"
    ) {
      window.location.replace(`https://pay2pay.in/`);
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/veriy-m-pin"
            element={<Verify_Mpin />}
          />
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            
            <Route path="/services" element={<Services />} />
            <Route path="/service" element={<Service />} />

            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route
              path="/cancellationrefund"
              element={<CancellationRefund />}
            />
          </Route>
          <Route path="/dashboard" exact element={<Dashboard_Layout />}>
            <Route path="/dashboard" index element={<Dashboard />} />
            <Route path="/dashboard/my-profile" element={<My_Profile_Details />} />

            <Route path="/dashboard/registation" element={<Agent_Registation />} />
            <Route path="/dashboard/pending-registation" element={<PendingAgents />} />

            <Route path="/dashboard/welcome-letter" element={<WelcomeLetter />} />
            <Route path="/dashboard/id-card" element={<ID_Card />} />
            <Route path="/dashboard/certificates" element={<Agent_Certificates />} />
            <Route path="/dashboard/documents" element={<Documents />} />

            <Route path="/dashboard/bill_payment/bbps-history" element={<BbpsTransactions />} />
            <Route path="/dashboard/bill_payment-info/:bill_info" element={<Bbps_Bill_Info />} />
            <Route path="/dashboard/my-team" element={<MyTeam_member />} />

            <Route path="/dashboard/change-password" element={<ChangwPassword />} />
            <Route path="/dashboard/change-mpin" element={<ChangeMpin />} />

            <Route path="/dashboard/findremitter" element={<FindRemitter />} />
            <Route path="/dashboard/transferlist" element={<TransferList />} />

            {/* -----------------------bill payments-------------------- */}
            <Route path="/dashboard/bill_payment/prepaid_recharge" element={<Mobile_Prepaid_services />} />
            <Route path="/dashboard/bill_payment/postpaid_recharge" element={<PostpaidMobile />} />
            <Route path="/dashboard/bill_payment/electricity_bill" element={<Electrical_Services />} />
            <Route path="/dashboard/bill_payment/credit_card_bill" element={<Credit_card_bill_pay />} />
            <Route path="/dashboard/bill_payment/fastag_recharge" element={<Fastag_Services />} />
            <Route path="/dashboard/bill_payment/dth_recharge" element={<DTH_Services />} />
            <Route path="/dashboard/bill_payment/loan_repay_bill" element={<LOan_Repay />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
