import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  Space,
  Alert,
  notification,
  Typography,
  message,
} from "antd";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {
  user_Update,
  signIn,
  user_changePin_send_sms,
  user_change_password,
} from "../redux/authentication/AuthenticationSlice";

const { Title } = Typography;

const ChangeMpin = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const [oldpassword, setoldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [currectpassword, setCurrectPassword] = useState("");
  const [cnfPassword, setcnfPassword] = useState("");
  const [mathedPassword, setmathedPassword] = useState("");
  const [btnPress, setbtnPress] = useState(false);

  const [oldshowPassword, setoldShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);

  const [oldpasswordError, setoldPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cnfPasswordError, setcnfPasswordError] = useState("");
  const [errorArray, seterrorArray] = useState([]);
  const [passwordmatchError, setcnfPasswordmatchError] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const [buttonloading, setbuttonloading] = useState(false);

  const [btnPress2, setbtnPress2] = useState(false);

  const [sentOtp, setsentOtp] = useState("");

  const [otp, setotp] = useState("");
  const [currectotp, setCurrectotp] = useState("");
  const [otpError, setotpError] = useState("");
  const [showOtp, setshowOtp] = useState(false);

  const [siablebln1, setsiablebln1] = useState(false);
  const [siablebln2, setsiablebln2] = useState(false);
  const [buttonloading2, setbuttonloading2] = useState(false);

  const handleClickoldShowPassword = () => setoldShowPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCnfPassword = () => setShowCnfPassword((show) => !show);

  useEffect(() => {
    if (btnPress === true) {
      if (oldpassword.length !== 6 && oldpassword !== loginData.mpin) {
        setoldPasswordError("Please enter M-Pin");
      } else {
        setoldPasswordError("");
      }
      if (currectpassword === "") {
        setPasswordError("Please enter M-Pin");
      } else {
        setPasswordError("");
      }
      if (mathedPassword !== currectpassword || currectpassword === "") {
        setcnfPasswordError("Both M-Pin are not matched");
      } else {
        setcnfPasswordError("");
      }
    }
  }, [btnPress, currectpassword, mathedPassword, oldpassword, loginData]);

  useEffect(() => {
    if (btnPress2 === true) {
      if (currectotp === "") {
        setotpError("Required");
      } else {
        setotpError("");
      }
    }
  }, [btnPress2, currectotp]);

  const changePassword = (e) => {
    const currpassword = e.target.value.replace(/\D/g, "");
    setPassword(currpassword);
    if (currpassword.length === 6) {
      setCurrectPassword(currpassword);
      setPasswordError("");
    } else {
      setCurrectPassword("");
      setPasswordError("Please enter must and should 6 characters long");
    }
  };

  const changeConfermPassword = (e) => {
    const currpassword = e.target.value.replace(/\D/g, "");
    setcnfPassword(currpassword);
    if (currectpassword === currpassword) {
      setmathedPassword(currpassword);
      setcnfPasswordError("");
    } else {
      setmathedPassword("");
      setcnfPasswordError("Please Confirm your M-Pin");
    }
  };
  const verify_press = async (e) => {
    e.preventDefault();
    setbtnPress(true);
    setbuttonloading(true);
    setsiablebln1(true);
    if (oldpassword !== loginData.mpin) {
      setbuttonloading(false);
      setoldPasswordError("Old M-Pin does't match");
    } else {
      if (currectpassword === mathedPassword) {
        var digits = "0123456789";
        let OTP = "";
        for (let i = 0; i < 6; i++) {
          OTP += digits[Math.floor(Math.random() * 10)];
        }
        const formData = {
          number: loginData.mobile,
          purpose: "Change MPIN",
          otp: OTP,
        };

        const sent_otp = await dispatch(user_changePin_send_sms(formData));
        if (sent_otp.payload.success) {
          setshowOtp(true);
          setsentOtp(OTP);
          messageApi.open({
            type: "success",
            content: "OTP send to registered mobile number",
          });
        }
      } else {
        setbtnPress(true);
        setbuttonloading(false);
        setsiablebln1(false);
        setcnfPasswordmatchError("M-Pin does't match");
      }
    }

    setbuttonloading(false);
  };
  const passwordClick = async (e) => {
    e.preventDefault();
    setbtnPress2(true);
    setbuttonloading2(true);
    setsiablebln2(true);
    if (sentOtp !== currectotp && currectotp.length !== 6) {
      setbtnPress2(true);
      setbuttonloading2(false);
      setsiablebln2(false);
      setotpError("OTP does't match");
    } else {
      if (currectpassword === mathedPassword) {
        messageApi.open({
          type: "info",
          content: "Your Request has been sent to server wait for responce",
        });
        const formdata = {
          mpin: mathedPassword,
          userId: loginData._id,
        };
        const change_pass = await dispatch(user_Update(formdata));
        if (change_pass.payload.success) {
          messageApi.open({
            type: "success",
            content: "Succefully Changed Your M-Pin",
          });

          await dispatch(signIn(change_pass.payload));

          setbtnPress(false);
          setPassword("");
          setoldPassword("");
          setCurrectPassword("");
          setcnfPassword("");
          setmathedPassword("");

          const timer = setTimeout(() => {
            navigate("/dashboard");
          }, 3000);

          return () => clearTimeout(timer);
        } else {
          setoldPasswordError(change_pass.payload.message);
          setcnfPasswordmatchError("M-Pin does't match");
        }
      } else {
        setbtnPress(true);
        setbuttonloading(false);
        setcnfPasswordmatchError("M-Pin does't match");
      }
    }

    setbuttonloading2(false);
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container">
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="radio_div_container">
              <Title level={5} className="mx-2">
                Change M-Pin
              </Title>
            </div>
            <div className="dn_input_box">
              <TextField
                className="input_style"
                type={oldshowPassword ? "text" : "password"}
                label="Old M-Pin"
                id="outlined-start-adornment"
                error={oldpasswordError !== "" ? true : false}
                helperText={oldpasswordError}
                onChange={(e) => {
                  setoldPassword(e.target.value.replace(/\D/g, ""));
                }}
                value={oldpassword}
                inputProps={{
                  minLength: 6,
                  maxLength: 6,
                }}
                autocomplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={handleClickoldShowPassword}
                      className="currsor_po"
                    >
                      {oldshowPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  ),
                }}
                size="small"
                disabled={siablebln1}
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            <div className="dn_input_box" ref={ref}>
              <TextField
                className="input_style"
                type={showPassword ? "text" : "password"}
                label="New M-Pin"
                id="outlined-start-adornment"
                error={passwordError !== "" ? true : false}
                helperText={passwordError}
                onChange={(e) => {
                  changePassword(e);
                }}
                value={password}
                inputProps={{
                  minLength: 6,
                  maxLength: 6,
                }}
                autocomplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={handleClickShowPassword}
                      className="currsor_po"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  ),
                  minLength: 6,
                  maxLength: 6,
                }}
                size="small"
                disabled={siablebln1}
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            <div className="dn_input_box">
              <TextField
                className="input_style"
                type={showCnfPassword ? "text" : "password"}
                label="Confirm New M-Pin"
                id="outlined-start-adornment"
                error={cnfPasswordError !== "" ? true : false}
                helperText={cnfPasswordError}
                onChange={(e) => {
                  changeConfermPassword(e);
                }}
                value={cnfPassword}
                autocomplete="off"
                inputProps={{
                  minLength: 6,
                  maxLength: 6,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={handleClickShowCnfPassword}
                      className="currsor_po"
                    >
                      {showCnfPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  ),
                  minLength: 6,
                  maxLength: 6,
                }}
                size="small"
                disabled={siablebln1}
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => verify_press(e)}
                disabled={siablebln1}
              >
                {buttonloading ? (
                  <>
                    Loading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Change M-Pin"
                )}
              </LoadingButton>
            </div>
          </div>
          {showOtp === true && (
            <div className="inputs_container">
              <div className="dn_input_box">
                <TextField
                  className="input_style"
                  type="text"
                  label="Enter OTP"
                  id="outlined-start-adornment"
                  error={otpError !== "" ? true : false}
                  helperText={otpError}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setotp(value);
                    if (value.length === 6) {
                      setCurrectotp(value);
                      setotpError("");
                    } else {
                      setCurrectotp("");
                      setotpError("OTP length must and should 6 length char");
                    }
                  }}
                  value={otp}
                  inputProps={{
                    minLength: 6,
                    maxLength: 6,
                  }}
                  autocomplete="off"
                  size="small"
                  disabled={siablebln2}
                />
              </div>
              <div className="dn_input_box">
                <div className="input_style"></div>
              </div>
              <div className="button_container">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => passwordClick(e)}
                  disabled={siablebln2}
                >
                  {buttonloading2 ? (
                    <>
                      Loading
                      <LoadingIndicator size={24} />
                    </>
                  ) : (
                    "Change MPIN"
                  )}
                </LoadingButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChangeMpin;
