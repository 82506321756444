import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import Alert from "@mui/material/Alert";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { Typography, message } from "antd";

import {
  get_plance_by_number,
  get_single_biller_info,
  paybill_plan,
} from "../redux/services/ServiceSlice";
import Service_Plance from "./Srvices_plans/Service_Plance";

const Mobile_Prepaid_services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  const { bbps_services_list, bbps_circle_list } = useSelector(
    (state) => state.services
  );

  const [selected_Services, setselected_Services] = useState("");
  const [selected_Services_Error, setselected_Services_Error] = useState("");

  const [payment_success, setpayment_success] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const [biller_data, setbiller_data] = useState([]);
  const [sellected_biller_id, setsellected_biller_id] = useState("");
  const [sellected_biller_name, setsellected_biller_name] = useState("");
  const [sellected_biller_Error, setsellected_biller_Error] = useState("");

  const [all_plan_data, setAllplan_data] = useState([]);
  const [plan_data, setplan_data] = useState([]);
  const [unique_plan_names, setUnique_plan_names] = useState([]);
  const [sellected_plance, setsellected_plance] = useState("");
  const [circleRefID, setcircleRefID] = useState("");
  const [sellected_plance_Error, setsellected_plance_Error] = useState("");

  const [mobile_number, setmobile_number] = useState("");
  const [mobile_number_confirm, setmobile_number_confirm] = useState("");
  const [mobile_number_Error, setmobile_number_Error] = useState("");

  const [Operator_Circle, setOperator_Circle] = useState("");
  const [Operator_id, setOperator_id] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  const [paramsName, setparamsName] = useState("");
  const [select_plan_name, setselect_plan_name] = useState("");

  const [mPin, setmPin] = useState("");
  const [mPincmf, setmPincnf] = useState("");
  const [mPinError, setmPinError] = useState("");

  const [btnLoading2, setbtnLoading2] = useState(false);
  const [btnPress2, setbtnPress2] = useState(false);
  const [verify_button_show, setverify_button_show] = useState(false);

  const handleChange = (event, newAlignment) => {
    setselect_plan_name(newAlignment);
    const filter_plans = all_plan_data.filter(
      (item) => item.planType === newAlignment
    );
    setplan_data(filter_plans);
  };

  useEffect(() => {
    if (isAuth === false) {
      navigate("/");
    }
  }, [isAuth]);

  useEffect(() => {
    if (btnPress === true) {
      if (selected_Services === "") {
        setselected_Services_Error("Please select the service");
      } else {
        setselected_Services_Error("");
      }
      if (mobile_number_confirm === "") {
        setmobile_number_Error("Please enter the mobile number");
      } else {
        setmobile_number_Error("");
      }
      if (sellected_plance === "") {
        setsellected_plance_Error("Please select a plan");
      } else {
        setsellected_plance_Error("");
      }
    }
  }, [btnPress, selected_Services, mobile_number_confirm, sellected_plance]);

  useEffect(() => {
    if (btnPress2 === true) {
      if (mPincmf === "") {
        setmPinError("Required");
      } else {
        setmPinError("");
      }
    }
  }, [btnPress2, mPincmf]);

  useEffect(() => {
    setselected_Services("MOBILE PREPAID");
    const get_cat = async () => {
      const get_biller_info = await dispatch(
        get_single_biller_info("MOBILE PREPAID")
      );
      if (get_biller_info.meta.requestStatus === "fulfilled") {
        const biller_data = get_biller_info.payload.sort((a, b) =>
          a.billerName > b.billerName ? 1 : -1
        );
        setbiller_data(biller_data);
      }
    };
    get_cat();
  }, []);

  const select_biller_press = (value) => {
    const findi_biller = biller_data.find((data) => data.billerId === value);
    setsellected_biller_id(value);
    setsellected_biller_name(findi_biller.billerName);
  };

  const validateMobile = async (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    setmobile_number(inputValue);
    setAllplan_data([]);
    setplan_data([]);
    setUnique_plan_names([]);
    if (inputValue.length !== 10) {
      setmobile_number_Error("*Error! you have entered invalid mobile number.");
    } else {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });
      const get_plance = await dispatch(get_plance_by_number(inputValue));
      if (get_plance.meta.requestStatus === "fulfilled") {
        messageApi.open({
          type: "success",
          content: "Succefully Fetched Your Plan Details",
        });

        const plance_data = get_plance.payload[0];
        const plans_ino = plance_data.circleWisePlanLists[0];
        setOperator_Circle(plans_ino.circleName);
        setOperator_id(plance_data.operatorId);
        setcircleRefID(plans_ino.circleId);
        setAllplan_data(plans_ino.plansInfo);

        if (plans_ino.plansInfo.length > 0) {
          const plan_names = plans_ino.plansInfo.map((item) => item.planType);
          const unique_plan_name = new Set(plan_names);
          setUnique_plan_names([...unique_plan_name]);
          const values = [...unique_plan_name];
          const name = values[0];
          setselect_plan_name(name);
          const filter_plans = plans_ino.plansInfo.filter(
            (item) => item.planType === name
          );
          setplan_data(filter_plans);
        }
        if (biller_data.length > 0) {
          const get_biller = biller_data.find(
            (data) => data.billerName === plance_data.operatorName
          );
          console.log(get_biller.customerParams[0].paramName, "get_biller");
          setsellected_biller_id(get_biller.billerId);
          setsellected_biller_name(get_biller.billerName);
          setparamsName(get_biller.customerParams[0].paramName);
        }
      } else {
      }
      setmobile_number_confirm(inputValue);
      setmobile_number_Error("");
    }
  };
  const get_plan = (value) => {
    setsellected_plance(value);
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));
  const getCurrentTime = () => {
    const now = new Date();
    return now
      .toISOString()
      .replace(/[-:.TZ]/g, "")
      .slice(0, 14); // Format time as YYYYMMDDHHMMSS
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  const pay_pill = async (e) => {
    // e.preventdefault();
    setbtnPress(true);
    setbtnPress2(true);
    setbtnLoading(true);

    const currentTime = getCurrentTime();
    const randomCharacter = generateRandomString(21);
    const newString = `${currentTime}${randomCharacter}`;
    if (
      selected_Services !== "" &&
      mobile_number_confirm !== "" &&
      sellected_plance !== "" &&
      mPincmf !== ""
    ) {
      if (mPincmf === loginData.mpin) {
        messageApi.open({
          type: "info",
          content: "Your Request has been sent to server wait for responce",
        });
        const form_data = {
          amount: sellected_plance.price,
          billerId: sellected_biller_id,
          billerName: sellected_biller_name,
          category: selected_Services,
          circleRefID: circleRefID,
          customerEmailId: loginData.email,
          customerMobileNo: mobile_number_confirm,
          customerName: loginData.Full_Name,
          fetchRefId: newString,
          merchantTrxnRefId: newString,
          operatorCode: Operator_id,
          optional: "Transaction Note",
          paramName: paramsName,
          paramValue: mobile_number_confirm,
          paymentInfo: "",
          paymentMode: "WALLET",
          planId: "Enter planID",
          quickPay: "true",
          rechargeType: selected_Services,
          remark: "Payment remark",
          serviceName: selected_Services,
          splitPay: "",
          splitPayAmount: "",
          userid: loginData._id,
        };
        const paying_new_plan = await dispatch(paybill_plan(form_data));
        if (paying_new_plan.payload.success) {
          setpayment_success(paying_new_plan.payload.transactions);
          messageApi.open({
            type: "success",
            content: "Your Reacharge done successfully",
          });

          setTimeout(() => {
            navigate("/dashboard");
          }, 2000);

          setselected_Services("");
          setselected_Services_Error("");
          setbiller_data([]);
          setAllplan_data([]);
          setplan_data([]);
          setUnique_plan_names([]);
          setsellected_biller_id("");
          setsellected_biller_name("");
          setsellected_biller_Error("");
          setsellected_plance("");
          setcircleRefID("");
          setsellected_plance_Error("");
          setmobile_number("");
          setmobile_number_confirm("");
          setmobile_number_Error("");
          setOperator_Circle("");
          setOperator_id("");
          setsellected_biller_id("");
          setparamsName("");
          setbtnLoading(false);
          setbtnPress(false);
          setbtnPress2(false);
        } else {
          setbtnLoading(false);
          setsellected_plance_Error(paying_new_plan.payload.massage);
          messageApi.open({
            type: "error",
            content: `${paying_new_plan.payload.massage}`,
          });
        }
      } else {
        setmPinError("MPIN Doesm't matched");
      }
      setbtnLoading(false);
    } else {
      setbtnLoading(false);
    }
  };

  const get_mpin_verify = (e) => {
    setbtnPress(true);
    setbtnLoading2(true);
    if (
      selected_Services !== "" &&
      mobile_number_confirm !== "" &&
      sellected_plance !== ""
    ) {
      setverify_button_show(true);
    } else {
      setbtnLoading2(false);
    }
  };

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
          .image_suss_box {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_suss_box img {
  width: 20%;
  height: 50%;
}
  .class_capitalize {
  text-transform: lowercase !important;
}
  .inputs_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2vh;
  padding-top: 3vh;
  display: flex;
  justify-content: center;
  padding-bottom: 3vh;
}
.inputs_container .dn_input_box {
  width: 48%;
  display: flex;
  justify-content: center;
}
  .dn_input_box .name_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vh;
  border-bottom: 1px solid gainsboro;
}
.inputs_container .success_input_box {
  width: 98%;
  display: flex;
  justify-content: center;
}
  .name_details {
  color: #060264;
  font-size: 1rem;
  font-weight: 600;
}
.value_details {
  color: #0c0c10;
  font-size: 1rem;
  font-weight: 600;
}
         </style>
        </head>
        <body>
         <div class="main_fragnent_container">
        <div class="main_recha_container">
          <div class="inputs_container">
                <div class="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NAME :</span>
                    <span class="value_details">
                      ${payment_success.name}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NUMBER :</span>
                    <span class="value_details">
                    ${payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">AMOUNT :</span>
                    <span class="value_details">
                     ${payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">BILLER NAME:</span>
                    <span class="value_details">
                      ${payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">CATEGORY NAME:</span>
                    <span class="value_details">
                      ${payment_success.category}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT MODE :</span>
                    <span class="value_details">
                      ${payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT STATUS :</span>
                    <span class="value_details">
                      ${payment_success.status}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">DATE :</span>
                    <span class="value_details">
                      ${payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">REFERANCE ID :</span>
                    <span class="value_details">
                      ${payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">TRANSACTION ID :</span>
                    <span class="value_details">
                      ${payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div class="success_input_box">
                  <div class="name_box">
                    <span class="name_details">
                      MESSAGE / FAILED REASON :
                      <span class="value_details class_capitalize">
                        TRANSACTION IS PROCESSED AND THE MONEY HAS BEEN CREDITED
                        INTO THE BENEFICIARY ACCOUNT.
                      </span>
                    </span>
                  </div>
                </div>
                <div class="thank_note">
                  <span class="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
          
              </div>
              </div>
              </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container">
        <div className="main_recha_container">
          {payment_success === "" ? (
            <>
              {verify_button_show === false ? (
                <>
                  <div className="inputs_container">
                    <div className="dn_input_box">
                      <TextField
                        label="Mobile Number"
                        type="text"
                        id="text"
                        className="input_style"
                        error={mobile_number_Error !== "" ? true : false}
                        helperText={mobile_number_Error}
                        onChange={(e) => validateMobile(e)}
                        value={mobile_number}
                        autocomplete="off"
                        size="small"
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                        }}
                        disabled={selected_Services !== "" ? false : true}
                      />
                    </div>
                    <div className="dn_input_box">
                      <div className="input_style"></div>
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="Select biller"
                        select
                        className="input_style"
                        error={sellected_biller_Error !== "" ? true : false}
                        helperText={sellected_biller_Error}
                        value={sellected_biller_id}
                        onChange={(e) => select_biller_press(e.target.value)}
                        size="small"
                        disabled={true}
                      >
                        {biller_data &&
                          biller_data.map((data, index) => (
                            <MenuItem key={index} value={data.billerId}>
                              {data.billerName}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        label="Operator Circle"
                        type="email"
                        id="text"
                        className="input_style"
                        value={Operator_Circle}
                        disabled={true}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="radio_div_container">
                      <ToggleButtonGroup
                        color="primary"
                        value={select_plan_name}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                      >
                        {unique_plan_names &&
                          unique_plan_names.map((data, index) => (
                            <ToggleButton value={data} key={index}>
                              {data}
                            </ToggleButton>
                          ))}
                      </ToggleButtonGroup>
                    </div>
                    {sellected_plance_Error !== "" && (
                      <div className="radio_div_container">
                        <span className="radio_title">
                          {sellected_plance_Error}
                        </span>
                      </div>
                    )}

                    <div className="button_container">
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => get_mpin_verify(e)}
                        disabled={btnLoading2}
                      >
                        {btnLoading2 ? (
                          <>
                            LOading
                            <LoadingIndicator size={24} />
                          </>
                        ) : (
                          "Verify"
                        )}
                      </LoadingButton>
                    </div>
                    <div className="radio_div_container">
                      <Service_Plance
                        plan_data={plan_data}
                        sellected_plance={sellected_plance}
                        get_plan={get_plan}
                        select_plan_name={select_plan_name}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="inputs_container">
                    <div className="dn_input_box">
                      <TextField
                        label="MPIN"
                        type="text"
                        id="text"
                        className="input_style"
                        error={mPinError !== "" ? true : false}
                        helperText={mPinError}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          setmPin(value);
                          if (value.length === 6) {
                            setmPincnf(value);
                            setmPinError("");
                          } else {
                            setmPincnf("");
                            setmPinError(
                              "Please enter must and should 6 characters long"
                            );
                          }
                        }}
                        value={mPin}
                        inputProps={{
                          minLength: 6,
                          maxLength: 6,
                        }}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="dn_input_box">
                      <div className="input_style"></div>
                    </div>
                    <div className="button_container">
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => pay_pill(e)}
                        disabled={btnLoading}
                      >
                        {btnLoading ? (
                          <>
                            LOading
                            <LoadingIndicator size={24} />
                          </>
                        ) : (
                          "Pay bill"
                        )}
                      </LoadingButton>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="inputs_container">
                <div className="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">NAME :</span>
                    <span className="value_details">
                      {payment_success.name}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">NUMBER :</span>
                    <span className="value_details">
                      {payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">AMOUNT :</span>
                    <span className="value_details">
                      {payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">BILLER NAME:</span>
                    <span className="value_details">
                      {payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">CATEGORY NAME:</span>
                    <span className="value_details">
                      {payment_success.category}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">PAYMENT MODE :</span>
                    <span className="value_details">
                      {payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">PAYMENT STATUS :</span>
                    <span className="value_details">
                      {payment_success.status}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">DATE :</span>
                    <span className="value_details">
                      {payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">REFERANCE ID :</span>
                    <span className="value_details">
                      {payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">TRANSACTION ID :</span>
                    <span className="value_details">
                      {payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div className="success_input_box">
                  <div className="name_box">
                    <span className="name_details">
                      MESSAGE / FAILED REASON :{" "}
                      <span className="value_details class_capitalize">
                        TRANSACTION IS PROCESSED AND THE MONEY HAS BEEN CREDITED
                        INTO THE BENEFICIARY ACCOUNT.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="thank_note">
                  <span className="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
                <div className="profile_co_header_right">
                  <div
                    className="header_box width2 currsor_po"
                    onClick={() => handlePrint()}
                  >
                    <span>Print Receipt</span>
                  </div>
                  <div
                    className="header_box width1 currsor_po"
                    onClick={() => handlePrint()}
                  >
                    <span>Download Receipt</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Mobile_Prepaid_services;
