import React from "react";

const Dashboard_Footer = () => {
  return (
    <>
      <div className="dashboard_footer_container">
        <span>Powered by Super Rex Products Pvt.Ltd. © 2024 All Rights Reserved.</span>
      </div>
    </>
  );
};

export default Dashboard_Footer;
