import React, { useState, useEffect } from "react";
import { FaMoneyBill, FaAngleDoubleRight } from "react-icons/fa";
import { Skeleton } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const navigate = useNavigate();

  const { loginData } = useSelector((state) => state.Authentication);
  return (
    <>
      <div className="main_fragnent_container">
        <div className="guidLine_container">
          <div className="guidLine_box_header">
            <span className="header_dash_tit">
              Welcome {loginData.Full_Name}
              {/* , {loginData.Shop_Name}, {loginData.role} */}
            </span>
          </div>
          <div className="guidLine_box_content">
            <ul>
              <li>
                For information Related to the panel or query, call the given
                number or send whatsApp massage
              </li>
              <li>Timing : 10:AM - 06:00 PM (Monday to saturday only)</li>
              <li>Contact No : +91-8336098336 (WhatsApp & Call Both)</li>
            </ul>
          </div>
        </div>

        <div className="service_container">
          <div
            className="service_card lnbackg"
            onClick={() => navigate("/dashboard/bill_payment/credit_card_bill")}
          >
            <div className="card_image">
              <img src="./assets/img/hero/credit_card_bill.png" alt="" />
            </div>
            <div className="card_title">
              <span>Credit card bill</span>
            </div>
          </div>
          <div
            className="service_card lnbackg"
            onClick={() => navigate("/dashboard/bill_payment/dth_recharge")}
          >
            <div className="card_image">
              <img src="./assets/img/hero/dth_recharge.png" alt="" />
            </div>
            <div className="card_title">
              <span>dth recharge</span>
            </div>
          </div>
          <div
            className="service_card lnbackg"
            onClick={() => navigate("/dashboard/bill_payment/electricity_bill")}
          >
            <div className="card_image">
              <img src="./assets/img/hero/electricity.png" alt="" />
            </div>
            <div className="card_title">
              <span>Electricity Bill</span>
            </div>
          </div>
          <div
            className="service_card lnbackg"
            onClick={() => navigate("/dashboard/bill_payment/prepaid_recharge")}
          >
            <div className="card_image">
              <img src="./assets/img/hero/smartphone.png" alt="" />
            </div>
            <div className="card_title">
              <span>Mobile Rechage</span>
            </div>
          </div>
          <div
            className="service_card lnbackg"
            onClick={() =>
              navigate("/dashboard/bill_payment/postpaid_recharge")
            }
          >
            <div className="card_image">
              <img src="./assets/img/hero/postpaid_bill.png" alt="" />
            </div>
            <div className="card_title">
              <span>Postpaid Rechage</span>
            </div>
          </div>
          <div
            className="service_card lnbackg"
            onClick={() => navigate("/dashboard/bill_payment/fastag_recharge")}
          >
            <div className="card_image">
              <img src="./assets/img/hero/toll.png" alt="" />
            </div>
            <div className="card_title">
              <span>Recharge Fastag</span>
            </div>
          </div>
          <div
            className="service_card lnbackg"
            onClick={() => navigate("/dashboard/bill_payment/loan_repay_bill")}
          >
            <div className="card_image">
              <img src="./assets/img/hero/car.png" alt="" />
            </div>
            <div className="card_title">
              <span>Loan Repay</span>
            </div>
          </div>

          {/* <div className="service_card lnbackg">
            <div className="card_image">
              <img src="./assets/img/hero/comming_soon.png" alt="" />
            </div>
            <div className="card_title">
              <span>Coming Soon</span>
            </div>
          </div> */}
        </div>

        <div className="main_amount_container">
          <div className="mount_container_left">
            <div className="amount_box lnbackg2">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>0</h5>
                    <span>Wallet Balance</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>LAST UPDATED AT 26-06-2024 12:28:24 PM</span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
            <div className="amount_box lnbackg3">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>0</h5>
                    <span>Total Members</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>LAST UPDATED AT 26-06-2024 12:28:24 PM</span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
            <div className="amount_box lnbackg4">
              <div className="amount_box_top">
                <div className="amount_box_top_one">
                  <div className="box_top_one_left">
                    <FaMoneyBill className="icon" />
                  </div>
                  <div className="box_top_one_right">
                    <h5>0</h5>
                    <span>Active Members</span>
                  </div>
                </div>
                <div className="amount_box_top_two">
                  <span>LAST UPDATED AT 26-06-2024 12:28:24 PM</span>
                </div>
              </div>
              <div className="amount_box_bottom">
                <span>
                  View More <FaAngleDoubleRight />
                </span>
              </div>
            </div>
          </div>
          <div className="mount_container_right">
            <div className="notice_bord_container">
              <div className="notice_bord_container_top">
                <span>NOTICE BOARD</span>
              </div>
              <div className="notice_bord_container_Bottom">
                <Skeleton active />
              </div>
            </div>
          </div>
        </div>
        <div className="guidLine_container">
          <div className="guidLine_box_header">
            <p>There are recent guidelines issued by the regulatory body</p>
            <p>రెగ్యులేటరీ బాడీ జారీ చేసిన ఇటీవలి మార్గదర్శకాలు ఉన్నాయి</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
