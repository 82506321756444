import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";
import axios from "axios";
import { SignJWT, jwtVerify } from 'jose';

const initialState = {
  isAuth: JSON.parse(localStorage.getItem("loginData"))?.isAuth
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,
  loginData: JSON.parse(localStorage.getItem("loginData"))
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
  isUserLoading: true,

  new_user_registation: JSON.parse(localStorage.getItem("new_user_registation"))
    ? JSON.parse(localStorage.getItem("new_user_registation"))
    : "",
  isNewUserLoading: true,
  pageStatus: JSON.parse(localStorage.getItem("new_user_registation"))?.formStep
    ? JSON.parse(localStorage.getItem("new_user_registation")).formStep
    : 1,
  formSteps_status: JSON.parse(localStorage.getItem("new_user_registation"))?.formStep
    ? JSON.parse(localStorage.getItem("new_user_registation")).formStep
    : 1,

  secreat_key: "a8374e69d81a30a6834b441ea8a2818b47f2f106a19938e5944252b156beeccb9befa8d175a175fa164db09bdbe1be9d4dc27ac246fa56b2eeee6c3c831938c2",

  shopImages: "",
  isShopImageLoading: true,

  aadhar_front: JSON.parse(localStorage.getItem("aadhar_front"))
    ? JSON.parse(localStorage.getItem("aadhar_front"))
    : "",
  isaadhar_frontLoading: true,

  aadhar_back: JSON.parse(localStorage.getItem("aadhar_back"))
    ? JSON.parse(localStorage.getItem("aadhar_back"))
    : "",
  isaadhar_backLoading: true,

  pan_card: JSON.parse(localStorage.getItem("pan_card"))
    ? JSON.parse(localStorage.getItem("pan_card"))
    : "",
  ispan_cardLoading: true,

  shop_aadress: JSON.parse(localStorage.getItem("shop_aadress"))
    ? JSON.parse(localStorage.getItem("shop_aadress"))
    : "",
  isshop_aadressLoading: true,

  shop_photo: JSON.parse(localStorage.getItem("shop_photo"))
    ? JSON.parse(localStorage.getItem("shop_photo"))
    : "",
  isshop_photoLoading: true,

  cancelCheck: JSON.parse(localStorage.getItem("cancelCheck"))
    ? JSON.parse(localStorage.getItem("cancelCheck"))
    : "",
  iscancelCheckLoading: true,

  user_selfie: JSON.parse(localStorage.getItem("user_selfie"))
    ? JSON.parse(localStorage.getItem("user_selfie"))
    : "",
  isuser_selfieLoading: true,

  current_login_time: JSON.parse(localStorage.getItem("current_login_time"))
    ? JSON.parse(localStorage.getItem("current_login_time"))
    : "",
  temperory_login_data: "",

  pending_users: localStorage.getItem("pending_users")
    ? JSON.parse(localStorage.getItem("pending_users"))
    : [],
  pending_usersLoading: true,
  ispending_usersAvailable: localStorage.getItem("pending_users")
    ? true
    : false,

};

export const get_pending_users = createAsyncThunk(
  "Authentication/get_pending_users",
  async (username, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/pendinguser/pending_users/${username}`;
      const resp = await axios(url);
      return resp.data.users;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const user_Regisattion = createAsyncThunk(
  "Authentication/user_Regisattion",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/pendinguser/register`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

export const user_changePin_send_sms = createAsyncThunk(
  "Authentication/user_changePin_send_sms",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/user/send_change_otp`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);
export const user_Login = createAsyncThunk(
  "Authentication/user_Login",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/user/login_with_user_password`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);
export const login_with_JWS = createAsyncThunk(
  "Authentication/login_with_JWS",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/user/login-with-jsw`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);
export const get_projected_data_jws = createAsyncThunk(
  "Authentication/get_projected_data_jws",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "Content-Type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/user/login-with-protected-data`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);



export const new_user_Update = createAsyncThunk(
  "Authentication/new_user_Update",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/pendinguser/userupdate/${formData.userId}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("user Not create");
    }
  }
);
export const user_Update = createAsyncThunk(
  "Authentication/user_Update",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/user/userupdate/${formData.userId}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("user Not create");
    }
  }
);

export const validate_User_Email = createAsyncThunk(
  "Authentication/validate_User_Email",
  async (email, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/pendinguser/email/${email}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
export const validate_User_Number = createAsyncThunk(
  "Authentication/validate_User_Number",
  async (mobile, thunkAPI) => {
    let resp = {
      success: false,
      message: "new mobile",
    };
    try {
      const url = `${Baseurl}/api/v1/pendinguser/mobile/${mobile}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
export const validate_User_Aadhar = createAsyncThunk(
  "Authentication/validate_User_Aadhar",
  async (aadhar, thunkAPI) => {
    let resp = {
      success: false,
      message: "new aadhar",
    };
    try {
      const url = `${Baseurl}/api/v1/pendinguser/aadhar/${aadhar}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
export const validate_User_Pancard = createAsyncThunk(
  "Authentication/validate_User_Aadhar",
  async (pancard, thunkAPI) => {
    let resp = {
      success: false,
      message: "new pancard",
    };
    try {
      const url = `${Baseurl}/api/v1/pendinguser/pancard/${pancard}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const userShopImage = createAsyncThunk(
  "Authentication/userShopImage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl2}/api/v1/pendinguser/shopimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("image Icon Not create");
    }
  }
);
export const user_image_uploade = createAsyncThunk(
  "Authentication/user_image_uploade",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl2}/api/v1/pendinguser/shopimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("image Icon Not create");
    }
  }
);

export const user_change_password = createAsyncThunk(
  "Authentication/user_change_password",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/user/change_password`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("student Not create");
    }
  }
);



const AuthenticationSlice = createSlice({
  name: "Authentication",
  initialState,
  reducers: {
    LoginOtp(state, action) {
      state.mob = action.payload.number;
      state.otp = action.payload.otp;
      state.pageStatus = 1;
    },
    signIn(state, action) {
      const loginDetails = {
        ...action.payload.user,
        isAuth: true,
      };
      state.loginData = loginDetails;
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
      state.isAuth = true;
    },
    setcurrent_login_time(state, action) {
      state.current_login_time = action.payload;
      localStorage.setItem("current_login_time", JSON.stringify(state.current_login_time));
    },
    signInwithsession(state, action) {
      const loginDetails = {
        ...action.payload.user,
        isAuth: true,
      };
      state.loginData = loginDetails;
      state.isAuth = true;

    },
    setForm_status(state, action) {
      state.formSteps_status = action.payload;
    },
    settemperory_login_data(state, action) {
      state.temperory_login_data = action.payload;
    },
    signout(state, action) {
      state.pageStatus = 1;
      state.formSteps_status = 1;
      state.loginData = "";
      localStorage.removeItem("loginData");
      state.isAuth = false;

      state.current_login_time = "";
      localStorage.setItem("current_login_time", JSON.stringify(state.current_login_time));
      state.new_user_registation = "";
      localStorage.setItem("new_user_registation", JSON.stringify(state.new_user_registation));
      state.aadhar_front = "";
      localStorage.setItem("aadhar_front", JSON.stringify(state.aadhar_front));
      state.aadhar_back = "";
      localStorage.setItem("aadhar_back", JSON.stringify(state.aadhar_back));
      state.pan_card = "";
      localStorage.setItem("pan_card", JSON.stringify(state.pan_card));
      state.shop_aadress = "";
      localStorage.setItem("shop_aadress", JSON.stringify(state.shop_aadress));
      state.shop_photo = "";
      localStorage.setItem("shop_photo", JSON.stringify(state.shop_photo));
      state.cancelCheck = "";
      localStorage.setItem("cancelCheck", JSON.stringify(state.cancelCheck));
      state.user_selfie = "";
      localStorage.setItem("user_selfie", JSON.stringify(state.user_selfie));
      state.pending_users = [];
      localStorage.setItem("pending_users", JSON.stringify(state.pending_users));
    },
    set_remove_new_registation_details(state, action) {
      state.pageStatus = 1;
      state.formSteps_status = 1;
      state.new_user_registation = "";
      localStorage.setItem("new_user_registation", JSON.stringify(state.new_user_registation));
      state.aadhar_front = "";
      localStorage.setItem("aadhar_front", JSON.stringify(state.aadhar_front));
      state.aadhar_back = "";
      localStorage.setItem("aadhar_back", JSON.stringify(state.aadhar_back));
      state.pan_card = "";
      localStorage.setItem("pan_card", JSON.stringify(state.pan_card));
      state.shop_aadress = "";
      localStorage.setItem("shop_aadress", JSON.stringify(state.shop_aadress));
      state.shop_photo = "";
      localStorage.setItem("shop_photo", JSON.stringify(state.shop_photo));
      state.cancelCheck = "";
      localStorage.setItem("cancelCheck", JSON.stringify(state.cancelCheck));
      state.user_selfie = "";
      localStorage.setItem("user_selfie", JSON.stringify(state.user_selfie));
    },
    setaadhar_front(state, action) {
      state.aadhar_front = action.payload;
      localStorage.setItem("aadhar_front", JSON.stringify(state.aadhar_front));
      state.isaadhar_frontLoading = true;
    },
    setaadhar_front_loading(state, action) {
      state.isaadhar_frontLoading = false;
    },
    set_aadhar_back(state, action) {
      state.aadhar_back = action.payload;
      localStorage.setItem("aadhar_back", JSON.stringify(state.aadhar_back));
      state.isaadhar_backLoading = true;
    },
    set_aadhar_back_loading(state, action) {
      state.isaadhar_backLoading = false;
    },
    set_pan_card(state, action) {
      state.pan_card = action.payload;
      localStorage.setItem("pan_card", JSON.stringify(state.pan_card));
      state.ispan_cardLoading = true;
    },
    set_pan_card_loading(state, action) {
      state.ispan_cardLoading = false;
    },
    set_shop_aadress(state, action) {
      state.shop_aadress = action.payload;
      localStorage.setItem("shop_aadress", JSON.stringify(state.shop_aadress));
      state.isshop_aadressLoading = true;
    },
    set_shop_aadress_loading(state, action) {
      state.isshop_aadressLoading = false;
    },
    set_shop_photo(state, action) {
      state.shop_photo = action.payload;
      localStorage.setItem("shop_photo", JSON.stringify(state.shop_photo));
      state.isshop_photoLoading = true;
    },
    set_shop_photo_loading(state, action) {
      state.isshop_photoLoading = false;
    },
    set_cancelCheck(state, action) {
      state.cancelCheck = action.payload;
      localStorage.setItem("cancelCheck", JSON.stringify(state.cancelCheck));
      state.iscancelCheckLoading = true;
    },
    set_cancelCheck_loading(state, action) {
      state.iscancelCheckLoading = false;
    },
    set_user_selfie(state, action) {
      state.user_selfie = action.payload;
      localStorage.setItem("user_selfie", JSON.stringify(state.user_selfie));
      state.isuser_selfieLoading = true;
    },
    set_user_selfie_loading(state, action) {
      state.isuser_selfieLoading = false;
    },
    setRemove_new_user_details(state, action) {
      state.new_user_registation = "";
      state.pageStatus = 1;
      state.formSteps_status = 1;
      localStorage.setItem("new_user_registation", JSON.stringify(state.new_user_registation));
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(user_Regisattion.pending, (state) => {
        state.isNewUserLoading = true;
      })
      .addCase(user_Regisattion.fulfilled, (state, action) => {
        state.new_user_registation = action.payload.user;
        state.pageStatus = action.payload.user.formStep;
        state.formSteps_status = action.payload.user.formStep;
        localStorage.setItem("new_user_registation", JSON.stringify(state.new_user_registation));
        state.pending_users = [...state.pending_users, action.payload.user];
        localStorage.setItem("pending_users", JSON.stringify(state.pending_users));
        state.isNewUserLoading = false;
      })
      .addCase(user_Regisattion.rejected, (state) => {
        state.isNewUserLoading = true;
      })
    builder
      .addCase(new_user_Update.pending, (state) => {
        state.isNewUserLoading = true;
      })
      .addCase(new_user_Update.fulfilled, (state, action) => {
        state.new_user_registation = action.payload.user;
        state.pageStatus = action.payload.user.formStep;
        state.formSteps_status = action.payload.user.formStep;
        localStorage.setItem("new_user_registation", JSON.stringify(state.new_user_registation));
        state.isNewUserLoading = false;
      })
      .addCase(new_user_Update.rejected, (state) => {
        state.isNewUserLoading = true;
      })
    builder
      .addCase(get_projected_data_jws.pending, (state) => {
        state.isUserLoading = true;
      })
      .addCase(get_projected_data_jws.fulfilled, (state, action) => {
        if (action.payload.success) {
          const loginDetails = {
            ...action.payload.user,
            isAuth: true,
          };
          state.loginData = loginDetails;
          localStorage.setItem("loginData", JSON.stringify(state.loginData));
          state.isAuth = true;
        }
        state.isUserLoading = false;
      })
      .addCase(get_projected_data_jws.rejected, (state) => {
        state.isUserLoading = true;
      })
    builder
      .addCase(get_pending_users.pending, (state) => {
        state.pending_usersLoading = true;
      })
      .addCase(get_pending_users.fulfilled, (state, action) => {
        state.pending_users = action.payload;
        localStorage.setItem("pending_users", JSON.stringify(state.pending_users));
        state.pending_usersLoading = false;
        state.ispending_usersAvailable = true;
      })
      .addCase(get_pending_users.rejected, (state) => {
        state.pending_usersLoading = true;
      })
  }

});

export const {
  LoginOtp,
  signout,
  signIn,
  setForm_status,
  setaadhar_front,
  setaadhar_front_loading,
  set_aadhar_back,
  set_aadhar_back_loading,
  set_pan_card,
  set_pan_card_loading,
  set_shop_aadress,
  set_shop_aadress_loading,
  set_shop_photo,
  set_shop_photo_loading,
  set_cancelCheck,
  set_cancelCheck_loading,
  set_user_selfie,
  set_user_selfie_loading,
  setRemove_new_user_details,
  setcurrent_login_time,
  settemperory_login_data,
  set_remove_new_registation_details
} = AuthenticationSlice.actions;
export const authActions = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer;
