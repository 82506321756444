import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  return (
    <>
      <footer
        className="tp-footer-area pt-20 p-relative z-index-1"
        data-bg-color="#16243E"
        style={{ background: "white" }}
      >
        {isAuth ? (
          <></>
        ) : (
          <>
            <div className="tp-footer-main-area tp-footer-border">
              <div className="container">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="tp-footer-widget tp-footer-col-1 mb-50">
                      <div className="tp-footer-logo mb-20">
                        <Link to="/">
                          <img src="/assets/img/logo/logo_rbg.png" alt />
                        </Link>
                      </div>
                      <div className="tp-footer-widget-content">
                        <p style={{ color: "black" }}>
                          Payment processing is a critical component of any
                          business that sells goods or se Payment processing is
                          a critical component of any.
                        </p>
                        {/* <div className="tp-footer-widget-social">
                      <Link to="#">
                        <i className="fab fa-facebook-f" />
                      </Link>
                      <Link to="#">
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link to="#">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                      <Link to="#">
                        <i className="fa-brands fa-pinterest" />
                      </Link>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="tp-footer-widget tp-footer-col-2 mb-50">
                      <h3
                        className="tp-footer-widget-title"
                        style={{ color: "black" }}
                      >
                        Quick links
                      </h3>
                      <div className="tp-footer-widget-content">
                        <ul style={{ color: "black" }}>
                          <li>
                            <Link to="/" style={{ color: "black" }}>
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link to="/about" style={{ color: "black" }}>
                              About us
                            </Link>
                          </li>
                          {/* <li>
                        <Link to="/services">Services</Link>
                      </li> */}
                          <li>
                            <Link
                              to="/termsconditions"
                              style={{ color: "black" }}
                            >
                              Terms & Conditions
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/privacypolicy"
                              style={{ color: "black" }}
                            >
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/cancellationrefund"
                              style={{ color: "black" }}
                            >
                              Cancellation & Refund
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact" style={{ color: "black" }}>
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-6">
                    <div className="tp-footer-widget tp-footer-col-4 mb-50">
                      <h3
                        className="tp-footer-widget-title"
                        style={{ color: "black" }}
                      >
                        Contact us
                      </h3>
                      <div className="tp-footer-widget-content">
                        <div className="tp-footer-widget-contact">
                          <div className="tp-footer-widget-contact-inner">
                            <div>
                              {/* <i className="fa-sharp fa-solid fa-location-dot" /> */}
                              <h6 style={{ color: "black" }}>
                                SUPER REX PRODUCTS Private Ltd
                              </h6>
                              <h6 style={{ color: "black" }}>pay2pay.in</h6>
                              <p style={{ color: "black" }}>
                                12-4-83/A, Pragathi nagar line 5, Upper sri rama
                                bike point, Near sri Laxmi Narasimha swamy
                                temple, Moosapet, Hyderabad , Telengana - 500018
                              </p>
                            </div>
                          </div>
                          <div className="tp-footer-widget-contact-inner">
                            <a
                              href="tel:917013914767"
                              style={{ color: "black" }}
                            >
                              <i
                                className="fa-solid fa-phone"
                                style={{ color: "black" }}
                              />{" "}
                              (+91) 7013914767
                            </a>
                          </div>
                          <div className="tp-footer-widget-contact-inner">
                            <a href="mailto:info@pay2pay.in.com?body=My custom mail body">
                              <i className="fa-solid fa-envelope" />{" "}
                              <span
                                className="__cf_email__"
                                data-cfemail="365f58505976534e575b465a531855595b"
                                style={{ color: "black" }}
                              >
                                info@pay2pay.in
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className="tp-footer-copyright-area p-relative"
          style={{ backgroundColor: "#6222CC" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="tp-footer-copyright-inner">
                  <p>© 2024, Pay2Pay | Design by Dexterous Technology</p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="tp-footer-copyright-inner text-lg-end">
                  <Link to="/termsconditions">Terms & Conditions</Link>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                  <Link to="/cancellationrefund">Cancellation & Refund</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
