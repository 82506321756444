import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { IoMdEye } from "react-icons/io";
import { Modal } from "antd";

const Documents = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const [info_model, setinfo_model] = useState(false);
  const [selected_image, setselected_image] = useState("");
  return (
    <>
      <Modal
        title=""
        open={info_model}
        // onOk={() => setinfo_model(false)}
        onCancel={() => setinfo_model(false)}
        footer={null}
        maskClosable={false}
        style={{
          top: 20,
        }}
        width={1000}
      >
        <div className="model_image_box">
          <img src={selected_image} alt="" />
        </div>
      </Modal>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>
              {loginData.Full_Name} | {loginData.userName}
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="letter_content">
          <div className="documents_conatiner">
            <div className="document_image_box">
              <img
                src={loginData.user_photo}
                alt=""
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.user_photo);
                }}
              />
              <IoMdEye
                className="docum_eye"
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.user_photo);
                }}
              />
              <span className="image_name">Selfie</span>
            </div>
            <div className="document_image_box">
              <img
                src={loginData.aadhar_front}
                alt=""
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.aadhar_front);
                }}
              />
              <IoMdEye
                className="docum_eye"
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.aadhar_front);
                }}
              />
              <span className="image_name">Aadhar front side</span>
            </div>
            <div className="document_image_box">
              <img
                src={loginData.aadhar_back}
                alt=""
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.aadhar_back);
                }}
              />
              <IoMdEye
                className="docum_eye"
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.aadhar_back);
                }}
              />
              <span className="image_name">Aadhar back side</span>
            </div>
            <div className="document_image_box">
              <img
                src={loginData.pan_card}
                alt=""
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.pan_card);
                }}
              />
              <IoMdEye
                className="docum_eye"
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.pan_card);
                }}
              />
              <span className="image_name">pan card</span>
            </div>
            <div className="document_image_box">
              <img
                src={loginData.shop_address_proof}
                alt=""
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.shop_address_proof);
                }}
              />
              <IoMdEye
                className="docum_eye"
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.shop_address_proof);
                }}
              />
              <span className="image_name">Shop address proof</span>
            </div>
            <div className="document_image_box">
              <img
                src={loginData.shop_photo}
                alt=""
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.shop_photo);
                }}
              />
              <IoMdEye
                className="docum_eye"
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.shop_photo);
                }}
              />
              <span className="image_name">shop image</span>
            </div>

            <div className="document_image_box">
              <img
                src={loginData.cancel_cheque}
                alt=""
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.cancel_cheque);
                }}
              />
              <IoMdEye
                className="docum_eye"
                onClick={() => {
                  setinfo_model(true);
                  setselected_image(loginData.cancel_cheque);
                }}
              />
              <span className="image_name">cancel cheque</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
