import React from "react";
import { Link } from "react-router-dom";
import ServicesBBPS from "./home/ServicesBBPS";

const Services = () => {
  return (
    <div>
      <section
        className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"
        data-bg-color="#16243E"
        style={{ background: "#ffff" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title" style={{color:"black"}}>Service</h3>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <div className="breadcrumb__list text-center text-sm-end">
                  <span>
                    <Link to="/" style={{color:"black"}}>Home</Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa-regular fa-angle-right" />
                  </span>
                  <span style={{color:"black"}}>Service</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="my-3"></div>
      {/* <MoneyTransferBox /> */}
      <ServicesBBPS />
    </div>
  );
};

export default Services;
