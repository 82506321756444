import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <section
        className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"
        data-bg-color="#16243E"
        style={{ background: "#6222CC" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title" >About</h3>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <div className="breadcrumb__list text-center text-sm-end">
                  <span>
                    <Link to="/" >Home</Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa-regular fa-angle-right" />
                  </span>
                  <span >About</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="tp-about-area p-relative pt-130 pb-210">
        <div className="tp-about-shape">
          <img src="assets/img/about/shape-3.png" alt />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-thumb-wrapper p-relative wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <div className="main">
                  <img src="assets/img/project/home-3/img-4.jpg" alt />
                </div>
                <img
                  className="shape-1"
                  src="assets/img/project/project-details/img-2.jpg"
                  alt
                />
                <img
                  className="shape-2"
                  src="assets/img/about/shape-1.png"
                  alt
                />
                <img
                  className="shape-3"
                  src="assets/img/about/shape-2.png"
                  alt
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-wrapper">
                <div className="tp-about-title-wrapper">
                  <span className="tp-section-title-pre">
                    ABOUT US 
                  </span>
                </div>

                <p>
                  Utility bill payments are an essential part of modern life.
                  Whether it is electricity, gas, water, or other necessary
                  services, we do rely on them. To ensure uninterrupted access
                  to these services, timely payment of bills is crucial.
                  Understanding the various modes of payment and their
                  associated advantages and disadvantages is important to ensure
                  efficient and secure bill payments. Most utility providers
                  offer payment options for those who prefer automatic payments,
                  including online, direct debit, phone, and traditional mail
                  payments.
                </p>
                <p>
                  It is important to be proactive when it comes to utility bill
                  payments. Establishing a regular payment schedule, utilizing
                  online payment methods, and reviewing bills thoroughly are
                  ways to ensure timely and accurate payments. Being mindful of
                  the consequences of late payments and establishing a
                  record-keeping system can also help avoid potential disputes
                  and penalties. By keeping records individuals and businesses
                  can ensure uninterrupted access to essential services and
                  maintain control over their financial obligations.
                </p>
                <p>
                  Continue reading and learn more about utility bill payments,
                  the possibility of paying utility bills online, how you can
                  pay business bills online, and the benefits of paying bills
                  online.
                </p>
                <div className="tp-about-title-wrapper">
                  <span className="tp-section-title-pre">
                    Why utility bill payments?at do you mean b
                  </span>
                </div>

                <p>
                  A utility bill is a regular payment to a service provider for
                  their essential services. Utility bill payment refers to
                  paying these bills to the respective service providers.
                  Utility bills are typically sent out monthly or bi-monthly and
                  can be paid in various ways, such as by mail, online, mobile
                  apps, or in person at authorized payment centers. Payment
                  options and methods can vary depending on the service provider
                  and the location. Paying your business bills on time is
                  crucial to prevent late fees, or service disconnections.
                </p>
                <div className="tp-about-title-wrapper">
                  <span className="tp-section-title-pre">
                    Is it possible to pay utility bills online?
                  </span>
                </div>

                <p>
                  Yes, it is possible to pay business utility bills online. Many
                  service providers offer online payment options for businesses,
                  which can be a convenient and efficient way to manage and pay
                  multiple utility bills simultaneously. Business owners can
                  typically use the same payment platforms and methods available
                  to individual customers, such as the service provider’s
                  website, mobile apps, or third-party platforms.
                </p>
                <p>
                  For completing utility bill payment online, you must create a
                  business account with the service provider or payment platform
                  and provide information about your business. After creating an
                  account, you can access and manage your business bills, check
                  your account balance, and make payments using your computer or
                  mobile device.
                </p>
                <p>
                  Using a secure and reliable payment platform when you pay
                  bills online is essential to protect your business’s financial
                  information. Always verify the website’s security features,
                  such as SSL encryption, before entering sensitive information.
                </p>
                <div className="tp-about-title-wrapper">
                  <span className="tp-section-title-pre">
                    How can you pay business utility bills with SUPER REX PRODUCTS Private Ltd(pay2pay.in) ?
                  </span>
                </div>

                <p>
                  If you are looking forward to paying your business utility
                  bill payment through your SUPER REX PRODUCTS Private Ltd(pay2pay.in) account, then follow the
                  steps mentioned below:
                </p>
                <ul className="ml-2">
                  <li>
                    <p>
                      Go to the utility bill payment option on the left side of
                      the dashboard
                    </p>
                  </li>
                  <li>
                    <p>
                      Click on create a bill and select categories from the
                      dropdown list, such as electricity bill pay, gas bill
                      pays, etc.
                    </p>
                  </li>
                  <li>
                    <p>
                      Select state, biller name, registered branch, and consumer
                      number
                    </p>
                  </li>
                  <li>
                    <p>
                      You can also enable the auto-fetch option and register the
                      biller with an SUPER REX PRODUCTS Private Ltd(pay2pay.in) account so that it auto-fetches
                      the details next time and sends reminders
                    </p>
                  </li>
                  <li>
                    <p>
                      After entering all the required details, click on the view
                      bill button to see the status of your bill
                    </p>
                  </li>
                  <li>
                    <p>You can also upload a PDF copy of the physical bill</p>
                  </li>
                  <li>
                    <p>
                      Once you submit the bill, it will be added to SUPER REX PRODUCTS Private Ltd(pay2pay.in)
                      for payments
                    </p>
                  </li>
                  <li>
                    <p>
                      All the bills must be approved by the designated person as
                      per the approval metrics
                    </p>
                  </li>
                  <li>
                    <p>
                      Once the approval is done, click on pay online button and
                      pay the bill
                    </p>
                  </li>
                </ul>
                <div className="tp-about-title-wrapper">
                  <span className="tp-section-title-pre">
                    Benefits Of Paying Utility Bills With SUPER REX PRODUCTS Private Ltd(pay2pay.in)
                  </span>
                </div>
                <p>
                  There are several benefits of completing utility bill payment
                  with SUPER REX PRODUCTS Private Ltd(pay2pay.in)
                </p>
                <p>including:</p>
                <p>
                  Convenience: Paying business utility bills online is a
                  convenient and efficient way to manage and pay bills online
                  from a central location. This eliminated the need to visit
                  various payment centers or mail payments
                </p>
                <p>
                  Time saving: Online payment options can save time by
                  eliminating the need to write checks, fill out payment forms,
                  and mail payments
                </p>
                <p>
                  Cost-saving: Some service providers may offer discounts or
                  rewards for online payments, which can help businesses save
                  money on utility bills. Additionally, online payments can
                  reduce administrative costs for managing and processing paper
                  bills and payments
                </p>
                <p>
                  Automatic payments: Many online payment platforms offer the
                  option to set up automatic payments, which can help businesses
                  avoid late fees and penalties by ensuring timely payments
                </p>
                <p>
                  Access to billing information: Online payment platforms
                  typically provide businesses with detailed billing
                  information, including payment history, billing statements,
                  and usage reports. This helps enterprises to
                </p>
                <p>
                  track their expenses and make informed decisions about their
                  utility usage
                </p>
                <p>
                  Security: Online payment platforms often use encryption to
                  protect sensitive business and financial information. This can
                  help enterprises to reduce the risk of fraud and identity
                  theft associated with traditional paper-based payment methods
                </p>
                <p>
                  By understanding the various payment options available to you
                  and taking advantage of technology like online payments you
                  can make utility bill payment more convenient and efficient.
                  Connect with our platform SUPER REX PRODUCTS Private Ltd(pay2pay.in) today, a single platform
                  for all your bill payment needs. It helps you retrieve all
                  utility bills automatically to make the bill payments easier
                  and ensure bill payments are on time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}


      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ visibility: 'hidden', animationDelay: '0.1s', animationName: 'none' }}>
              <p className="section-title"> <b>About Us</b><span /></p>
              <h1 className="mb-5" style={{fontSize:"35px"}}> <b> who can offer Assisted Digital services Aadhar Enabled Payments(Aadhar), Utility Bill
                Payments, Mobile &amp; DTH Recharges, Payment services by using the one comprehensive pay2pay Platform.</b></h1>
              <p className="mb-4">pay2pay is a Fintech Company setup in by a team of Professionals having deep insight in
                Digital Services, We operate on B2C model, where we partner with retail stores.</p>
              <div className="skill mb-4">
                <div className="d-flex justify-content-between">
                  {/* <p class="mb-2">Digital Marketing</p>
                          <p class="mb-2">85%</p> */}
                </div>
                {/* <div class="progress">
                          <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
              </div>
              <div className="skill mb-4">
                <div className="d-flex justify-content-between">
                  {/* <p class="mb-2">SEO & Backlinks</p>
                          <p class="mb-2">90%</p> */}
                </div>
                {/* <div class="progress">
                          <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
              </div>
              <div className="skill mb-4">
                <div className="d-flex justify-content-between">
                  {/* <p class="mb-2">Design & Development</p>
                          <p class="mb-2">95%</p> */}
                </div>
                {/* <div class="progress">
                          <div class="progress-bar bg-dark" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
              </div>
              {/* <Link to="/about" className="btn btn-primary py-sm-3 px-sm-5 rounded-pill mt-3">Read More</Link> */}
            </div>
            <div className="col-lg-6">
              <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="./assets/img/about.png" style={{ visibility: 'hidden', animationDelay: '0.5s', animationName: 'none' }} />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default About;
