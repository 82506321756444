import React from "react";
import { Link } from "react-router-dom";


const HomeAbout = () => {
  return (
    <>
      {/* <section className="tp-about-area p-relative pt-130 pb-130">
        <div className="tp-about-shape">
          <img src="assets/img/about/shape-3.png" alt />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-thumb-wrapper p-relative wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <div className="main">
                  <img src="assets/img/about/img-1.jpg" alt />
                </div>
                <img className="shape-1" src="assets/img/about/img-2.jpg" alt />
                <img
                  className="shape-2"
                  src="assets/img/about/shape-1.png"
                  alt
                />
                <img
                  className="shape-3"
                  src="assets/img/about/shape-2.png"
                  alt
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-wrapper">
                <div className="tp-about-title-wrapper">
                  <span className="tp-section-title-pre">ABOUT US</span>
                </div>
                <p>
                pay2pay is the brand of SUPER REX PRODUCTS Private Ltd
                </p>
                <p>
                who can offer Assisted Digital services Aadhar Enabled Payments(Aadhar), Utility Bill Payments, Mobile & DTH Recharges, Payment services by using the one comprehensive pay2pay Platform.
                </p>
                <p>
                pay2pay is a Fintech Company setup in by a team of Professionals having deep insight in Digital Services, We operate on B2C model, where we partner with retail stores.
                </p>
                <div className="tp-about-btn">
                  <Link className="tp-btn" to="/about">
                    About Us
                    <span>
                      <i className="fa-regular fa-plus" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}



      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ visibility: 'hidden', animationDelay: '0.1s', animationName: 'none' }}>
              <p className="section-title"> <b>About Us</b><span /></p>
              <h1 className="mb-5" style={{fontSize:"35px"}}> <b> who can offer Assisted Digital services Aadhar Enabled Payments(Aadhar), Utility Bill
                Payments, Mobile &amp; DTH Recharges, Payment services by using the one comprehensive pay2pay Platform.</b></h1>
              <p className="mb-4">pay2pay is a Fintech Company setup in by a team of Professionals having deep insight in
                Digital Services, We operate on B2C model, where we partner with retail stores.</p>
              <div className="skill mb-4">
                <div className="d-flex justify-content-between">
                  {/* <p class="mb-2">Digital Marketing</p>
                          <p class="mb-2">85%</p> */}
                </div>
                {/* <div class="progress">
                          <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
              </div>
              <div className="skill mb-4">
                <div className="d-flex justify-content-between">
                  {/* <p class="mb-2">SEO & Backlinks</p>
                          <p class="mb-2">90%</p> */}
                </div>
                {/* <div class="progress">
                          <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
              </div>
              <div className="skill mb-4">
                <div className="d-flex justify-content-between">
                  {/* <p class="mb-2">Design & Development</p>
                          <p class="mb-2">95%</p> */}
                </div>
                {/* <div class="progress">
                          <div class="progress-bar bg-dark" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
              </div>
              <Link to="/about" className="btn btn-primary py-sm-3 px-sm-5 rounded-pill mt-3">Read More</Link>
            </div>
            <div className="col-lg-6">
              <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="./assets/img/about.png" style={{ visibility: 'hidden', animationDelay: '0.5s', animationName: 'none' }} />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default HomeAbout;
