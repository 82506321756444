import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetch_bill,
  get_single_biller_info,
  pay_bill_payments,
  paybill_plan,
} from "../redux/services/ServiceSlice";
import moment from "moment/moment";
import { Typography, message } from "antd";

const { Title } = Typography;

const LOan_Repay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  const { bbps_services_list, bbps_circle_list } = useSelector(
    (state) => state.services
  );

  const [biller_list_data, setbiller_list_data] = useState([]);
  const [selected_biller, setselected_biller] = useState("");
  const [biller_id, setbiller_id] = useState("");
  const [biller_error, setbiller_error] = useState("");

  const [custome_params, setcustome_params] = useState([]);
  const [bill_details, setbill_details] = useState("");
  const [disabled_step_1, setdisabled_step_1] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [registation_number, setregistation_number] = useState("");
  const [registation_number_error, setregistation_number_error] = useState("");

  const [register_mobile, setregister_mobile] = useState("");
  const [register_mobile_error, setregister_mobile_error] = useState("");

  const [creditcard_number, setcreditcard_number] = useState("");
  const [creditcard_number_error, setcreditcard_number_error] = useState("");

  const [rechanger_amount, setrechanger_amount] = useState("");
  const [rechanger_amount_error, setrechanger_amount_error] = useState("");
  const [disabled_step_2, setdisabled_step_2] = useState(false);

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  const [btnLoading2, setbtnLoading2] = useState(false);
  const [btnPress2, setbtnPress2] = useState(false);

  const [payment_success, setpayment_success] = useState("");

  useEffect(() => {
    const get_cat = async () => {
      const get_biller_info = await dispatch(get_single_biller_info("LOAN"));
      if (get_biller_info.meta.requestStatus === "fulfilled") {
        const biller_data = get_biller_info.payload.sort((a, b) =>
          a.billerName > b.billerName ? 1 : -1
        );
        setbiller_list_data(biller_data);
      }
    };
    get_cat();
  }, []);

  const select_biller_press = (value) => {
    setbiller_id(value);
    const find_biller = biller_list_data.find(
      (data) => data.billerId === value
    );
    setselected_biller(find_biller);

    if (find_biller !== "") {
      let custome_params = [];

      for (let index = 0; index < find_biller.customerParams.length; index++) {
        let element = find_biller.customerParams[index];
        let obj = {
          ...element,
          [element.paramName]: "",
          values: "",
          conf_values: "",
          error: "",
        };
        custome_params = [...custome_params, obj];
      }
      setcustome_params([...custome_params]);
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now
      .toISOString()
      .replace(/[-:.TZ]/g, "")
      .slice(0, 14);
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };
  const fetching_fastag_bill = async (e) => {
    setdisabled_step_1(true);
    setbtnLoading(true);
    setbtnPress(true);

    const currentTime = getCurrentTime();
    const randomCharacter = generateRandomString(21);
    const newString = `${currentTime}${randomCharacter}`;

    if (selected_biller === "" && biller_id === "") {
      setbiller_error("Require");
    } else {
      setbiller_error("");
    }
    let array = [];

    for (let index = 0; index < custome_params.length; index++) {
      let element = custome_params[index];
      if (element.conf_values === "") {
        element.error = "Required";
      }
      array = [...array, element];
    }
    setcustome_params([...array]);

    if (
      selected_biller !== "" &&
      biller_id !== "" &&
      array.every((data) => data.conf_values !== "")
    ) {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });

      let params_get = {};

      for (let index = 0; index < array.length; index++) {
        let element = array[index];
        params_get = {
          ...params_get,
          [element.paramName]: element.conf_values,
        };
      }

      const form_data = {
        billerId: selected_biller.billerId,
        category: "LOAN",
        customerParams: params_get,
        merchantTrxnRefId: newString,
        mobileNo: loginData.mobile,
      };
      const get_bill = await dispatch(fetch_bill(form_data));
      if (get_bill.payload.success === true) {
        setbill_details(get_bill.payload.get_bill);
        messageApi.open({
          type: "success",
          content: "Succefully Fetched Your Bill Details",
        });
      } else {
        messageApi.open({
          type: "error",
          content: `${get_bill.payload.get_bill.reason}`,
        });
        setdisabled_step_1(false);
      }
      setbtnLoading(false);
    } else {
      setbtnLoading(false);
      setdisabled_step_1(false);
    }
  };

  const comple_card_bill = async (e) => {
    setdisabled_step_2(true);
    setbtnLoading2(true);
    setbtnPress2(true);

    const data_new = new Date();
    const use_date = moment(data_new).format("YYYY-MM-DD");

    if (
      custome_params.every((data) => data.conf_values !== "") &&
      selected_biller !== ""
    ) {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });

      let params_get = {};

      for (let index = 0; index < custome_params.length; index++) {
        let element = custome_params[index];
        params_get = {
          ...params_get,
          [element.paramName]: element.conf_values,
        };
      }
      const billpay = {
        additionalInfo: "",
        amount: rechanger_amount,
        billerId: selected_biller.billerId,
        billerName: selected_biller.billerName,
        category: selected_biller.category,
        customerMobile: loginData.mobile,
        customerMobileNo: loginData.mobile,
        customerName: loginData.Full_Name,
        customerEmailId: loginData.email,
        customerParams: params_get,
        merchantTrxnRefId: bill_details.refId,
        billerReferenceNumber: bill_details.refId,
        paymentId: "FidyPay",
        paymentMode: "WALLET",
        quickPay: "false",
        remark: "Bill Payment",
        splitPay: "",
        splitPayAmount: "",
        userid: loginData._id,
        bill_date: use_date,
      };
      const pay_bill = await dispatch(pay_bill_payments(billpay));
      if (pay_bill.payload.success === true) {
        const responce = pay_bill.payload.transactions;
        setpayment_success(pay_bill.payload.transactions);
        if (responce.code === "0x0200" || responce.code === "200") {
          messageApi.open({
            type: "success",
            content: "Recharge Succefully Done",
          });
        } else {
          messageApi.open({
            type: "error",
            content: `${pay_bill.payload.massage}`,
          });
        }
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } else {
        messageApi.open({
          type: "error",
          content: `${pay_bill.payload.massage}`,
        });
        setdisabled_step_2(false);
        setdisabled_step_1(false);
        setbill_details("");
        setrechanger_amount("");
      }
      setbtnLoading2(false);
      setdisabled_step_2(false);
    } else {
      setbtnLoading2(false);
      setdisabled_step_2(false);
    }
  };

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
          .image_suss_box {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_suss_box img {
  width: 20%;
  height: 50%;
}
  .class_capitalize {
  text-transform: lowercase !important;
}
  .inputs_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2vh;
  padding-top: 3vh;
  display: flex;
  justify-content: center;
  padding-bottom: 3vh;
}
.inputs_container .dn_input_box {
  width: 48%;
  display: flex;
  justify-content: center;
}
  .dn_input_box .name_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vh;
  border-bottom: 1px solid gainsboro;
}
.inputs_container .success_input_box {
  width: 98%;
  display: flex;
  justify-content: center;
}
  .name_details {
  color: #060264;
  font-size: 1rem;
  font-weight: 600;
}
.value_details {
  color: #0c0c10;
  font-size: 1rem;
  font-weight: 600;
}
         </style>
        </head>
        <body>
         <div class="main_fragnent_container">
        <div class="main_recha_container">
          <div class="inputs_container">
                <div class="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NAME :</span>
                    <span class="value_details">
                      ${payment_success.name}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NUMBER :</span>
                    <span class="value_details">
                    ${payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">AMOUNT :</span>
                    <span class="value_details">
                     ${payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">BILLER NAME:</span>
                    <span class="value_details">
                      ${payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">CATEGORY NAME:</span>
                    <span class="value_details">
                      ${payment_success.category}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT MODE :</span>
                    <span class="value_details">
                      ${payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT STATUS :</span>
                    <span class="value_details">
                      ${payment_success.status}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">DATE :</span>
                    <span class="value_details">
                      ${payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">REFERANCE ID :</span>
                    <span class="value_details">
                      ${payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">TRANSACTION ID :</span>
                    <span class="value_details">
                      ${payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div class="success_input_box">
                  <div class="name_box">
                    <span class="name_details">
                      MESSAGE / FAILED REASON :
                      <span class="value_details class_capitalize">
                        TRANSACTION IS PROCESSED AND THE MONEY HAS BEEN CREDITED
                        INTO THE BENEFICIARY ACCOUNT.
                      </span>
                    </span>
                  </div>
                </div>
                <div class="thank_note">
                  <span class="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
          
              </div>
              </div>
              </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container">
        <div className="main_recha_container">
          {payment_success === "" ? (
            <>
              <div className="inputs_container">
                <div className="radio_div_container">
                  <Title level={5} className="mx-2">
                    Loan Finance provider Details
                  </Title>
                </div>

                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Your Loan Finance provider"
                    select
                    className="input_style"
                    error={biller_error !== "" ? true : false}
                    helperText={biller_error}
                    value={biller_id}
                    onChange={(e) => {
                      select_biller_press(e.target.value);
                    }}
                    size="small"
                    disabled={disabled_step_1}
                  >
                    {biller_list_data &&
                      biller_list_data.map((data, index) => (
                        <MenuItem key={index} value={data.billerId}>
                          {data.billerName}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>

                {selected_biller !== "" && (
                  <>
                    {custome_params &&
                      custome_params.map((data, index) => (
                        <div className="dn_input_box" key={index}>
                          <TextField
                            label={`${data.paramName}`}
                            type="text"
                            id="text"
                            className="input_style"
                            error={data.error !== "" ? true : false}
                            helperText={data.error}
                            onChange={(e) => {
                              const value = e.target.value;
                              let array = [...custome_params];
                              array[index].values = value;
                              setcustome_params([...array]);

                              if ("maxLength" in data) {
                                if (
                                  value.length >= data.minLength &&
                                  data.maxLength >= value.length
                                ) {
                                  array[index].conf_values = value;
                                  setcustome_params([...array]);
                                  array[index].error = "";
                                } else {
                                  array[
                                    index
                                  ].error = `number should be more then ${data.minLength} and less then ${data.maxLength}`;
                                  setcustome_params([...array]);
                                }
                              } else {
                                if (value.length >= data.minLength) {
                                  array[index].conf_values = value;
                                  setcustome_params([...array]);
                                  array[index].error = "";
                                } else {
                                  array[
                                    index
                                  ].error = `number should be more then ${data.minLength}`;
                                  setcustome_params([...array]);
                                }
                              }
                            }}
                            value={data?.values}
                            autocomplete="off"
                            size="small"
                            inputProps={
                              selected_biller !== ""
                                ? {
                                    minLength: data.minLength,
                                    maxLength: data.maxLength,
                                  }
                                : {}
                            }
                            disabled={disabled_step_1}
                          />
                        </div>
                      ))}
                  </>
                )}

                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
                <div className="button_container">
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={(e) => fetching_fastag_bill(e)}
                    disabled={disabled_step_1}
                  >
                    {btnLoading ? (
                      <>
                        Loading
                        <LoadingIndicator size={24} />
                      </>
                    ) : (
                      "Fetch FASTAG Bill"
                    )}
                  </LoadingButton>
                </div>
              </div>
              {bill_details !== "" && (
                <div className="inputs_container">
                  <div className="radio_div_container">
                    <Title level={5} className="mx-2">
                      FASTag Details
                    </Title>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Amount :</span>
                      <span className="value_details">
                        {bill_details.amount}
                      </span>
                    </div>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Name :</span>
                      <span className="value_details">
                        {bill_details.accountHolderName}
                      </span>
                    </div>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Due Date :</span>
                      <span className="value_details">
                        {bill_details.dueDate === ""
                          ? "NULL"
                          : bill_details.dueDate}
                      </span>
                    </div>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">
                        Maximum Bill Amount :
                      </span>
                      <span className="value_details">NULL</span>
                    </div>
                  </div>

                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Message / Reason :</span>
                      <span className="value_details">
                        Bill Fetched Success
                      </span>
                    </div>
                  </div>

                  <div className="dn_input_box">
                    <div className="input_style"></div>
                  </div>
                  <div className="button_container">
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={(e) => comple_card_bill(e)}
                      disabled={disabled_step_2}
                    >
                      {btnLoading2 ? (
                        <>
                          Loading
                          <LoadingIndicator size={24} />
                        </>
                      ) : (
                        "Complete Bill payment"
                      )}
                    </LoadingButton>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="inputs_container">
                <div className="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">NAME :</span>
                    <span className="value_details">
                      {payment_success.name}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">NUMBER :</span>
                    <span className="value_details">
                      {payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">AMOUNT :</span>
                    <span className="value_details">
                      {payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">BILLER NAME:</span>
                    <span className="value_details">
                      {payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">CATEGORY NAME:</span>
                    <span className="value_details">
                      {payment_success.category}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">PAYMENT MODE :</span>
                    <span className="value_details">
                      {payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">PAYMENT STATUS :</span>
                    <span className="value_details">
                      {payment_success.status}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">DATE :</span>
                    <span className="value_details">
                      {payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">REFERANCE ID :</span>
                    <span className="value_details">
                      {payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">TRANSACTION ID :</span>
                    <span className="value_details">
                      {payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div className="success_input_box">
                  <div className="name_box">
                    <span className="name_details">
                      MESSAGE / FAILED REASON :{" "}
                      <span className="value_details class_capitalize">
                        {payment_success.status_description}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="thank_note">
                  <span className="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
                <div className="profile_co_header_right">
                  <div
                    className="header_box width2 currsor_po"
                    onClick={() => handlePrint()}
                  >
                    <span>Print Receipt</span>
                  </div>
                  <div
                    className="header_box width1 currsor_po"
                    onClick={() => handlePrint()}
                  >
                    <span>Download Receipt</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LOan_Repay;
