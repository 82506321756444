import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { CSVLink } from "react-csv";
import {
  DownOutlined,
  CaretDownOutlined,
  PlusOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { DatePicker, Input, Select, Button } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#006fa9",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&td, &th": {
    border: 0,
  },
}));

const BbpsTransactions = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  const { bill_transations } = useSelector((state) => state.services);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filter_data, setfilter_data] = useState([]);
  const [export_data, setexport_data] = useState([]);
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    let filter_trans = bill_transations;
    if (search !== "") {
      filter_trans = filter_trans.filter((transaction) => {
        return transaction.mobile.toLowerCase().match(search.toLowerCase());
      });
    }
    if (end_date !== "" && start_date !== "") {
      filter_trans = filter_trans.filter(
        (transaction) =>
          moment(transaction.createdAt).format("YYYY-MM-DD") >= start_date &&
          moment(transaction.createdAt).format("YYYY-MM-DD") <= end_date
      );
    }
    setfilter_data([...filter_trans]);
  }, [search, end_date, start_date, bill_transations]);

  useEffect(() => {
    let export_trabs = [];
    for (let i = 0; i < filter_data.length; i++) {
      let trans_element = filter_data[i];
      let form_data = {};
      form_data = {
        Date: moment(trans_element.createdAt).format("YYYY-MM-DD"),
        Ref_Number: trans_element.txnReferenceId,
        Ack_Number: trans_element.trxn_id,
        Operator_Name: trans_element.billerName,
        Mobile_Number: trans_element.mobile,
        Recharge_Amount: trans_element.amount,
        Status: trans_element.status,
        Message: "",
        Merchant: "",
        Remarks: "",
      };
      export_trabs = [...export_trabs, form_data];
    }
    setexport_data([...export_trabs]);
  }, [filter_data]);

  const onChange = (date, dateString) => {
    setstart_date(dateString[0]);
    setend_date(dateString[1]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>
              {loginData.Full_Name} | {loginData.userName}
            </span>
          </div>
          <div className="profile_co_header_right">
            {/* <div className="header_box width2 currsor_po">
              <span>
                <FaPrint className="mx-1" />
                Back to Profile
              </span>
            </div> */}
          </div>
        </div>
        <div className="transaction_header">
          <RangePicker onChange={onChange} />

          <Input
            placeholder="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "50vh",
            }}
          />
          <TablePagination
            component="div"
            count={0}
            page={0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            labelRowsPerPage="Rows:"
            labelDisplayedRows={() => null}
            SelectProps={{
              inputProps: { "aria-label": "rows" },
              native: true,
            }}
            ActionsComponent={() => null}
          />
          <CSVLink
            data={export_data}
            filename={"remitters-data.csv"}
            onClick={() => {}}
          >
            <Button
              icon={<FileExcelOutlined />}
              style={{
                background: "forestgreen",
                border: "1px solid forestgreen",
                color: "#fff",
                fontWeight: "500",
              }}
            >
              EXPORT TO EXCEL
            </Button>
          </CSVLink>
        </div>
        <div className="letter_content">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper} className="customScrollbar">
              <Table
                sx={{ minWidth: 700, border: "none" }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ border: "none" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Mobile
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Amount
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Provider
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Category
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Bill Date
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter_data
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ border: "none" }}
                        >
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          {row.mobile}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          {row.amount}
                        </StyledTableCell>
                        <StyledTableCell
                          width={120}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {row.billerName}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          {row.category}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          {row.status}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          {row.billDate}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          <Link
                            to={`/dashboard/bill_payment-info/${row._id}`}
                            style={{ color: "#006fa9" }}
                          >
                            Print
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter_data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};

export default BbpsTransactions;
