import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bankDetailsByUserId: [],
  selectBankAccount: "",
  bankDetailsLoading: false,
};

const BankDetailsSlice = createSlice({
  name: "BankDetails",
  initialState,
  reducers: {
    bankDetailsbyClientId(state, action) {
      state.bankDetailsByUserId = action.payload;
      state.bankDetailsLoading = true;
    },

    newBankDetails(state, action) {
      state.bankDetailsByUserId = [
        ...state.bankDetailsByUserId,
        action.payload,
      ];
      state.bankDetailsLoading = true;
    },
    setSelectBankAccount(state, action) {
      state.selectBankAccount = action.payload;
    },
    deleteBankAccount(state, action) {
      console.log(action.payload);
      state.bankDetailsByUserId = state.bankDetailsByUserId.filter(
        (bank) => bank._id !== action.payload
      );
    },
  },
});

export const {
  bankDetailsbyClientId,
  newBankDetails,
  setSelectBankAccount,
  deleteBankAccount,
} = BankDetailsSlice.actions;
export default BankDetailsSlice.reducer;
