import React, { useState, useLayoutEffect, useEffect } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  authActions,
  get_projected_data_jws,
  login_with_JWS,
  setcurrent_login_time,
  signIn,
  user_Login,
} from "../redux/authentication/AuthenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { PoweroffOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";

const Verify_Mpin = () => {
  const { temperory_login_data } = useSelector((store) => store.Authentication);
  const [mPin, setmPin] = useState("");
  const [mPincmf, setmPincnf] = useState("");
  const [mPinError, setmPinError] = useState("");

  const [buttonLoading, setbuttonLoading] = useState(false);
  const [buttonPress, setbuttonPress] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (temperory_login_data === "") {
      navigate("/login");
    }
  }, [temperory_login_data]);

  useEffect(() => {
    if (buttonPress === true) {
      if (mPincmf === "") {
        setmPinError("Required");
      } else {
        setmPinError("");
      }
    }
  }, [buttonPress, mPincmf]);

  const loginPress = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);
    setmPinError("");
    const date = new Date();
    if (mPincmf !== "" && temperory_login_data !== "") {
      const token = sessionStorage.getItem("token");
      if (token) {
        if (mPincmf === temperory_login_data.user.mpin) {
          dispatch(signIn(temperory_login_data));
          dispatch(setcurrent_login_time(date));
          navigate("/dashboard");
          setmPin("");
        } else {
          setmPinError("MPIN Doesm't matched");
          setbuttonLoading(false);
        }
      } else {
        navigate("/login");
        setmPin("");
      }
    } else {
      setbuttonLoading(false);
    }
  };

  return (
    <>
      <div className="maincontainer_log">
        <div className="imagecontainer">
          <img src="./assets/img/hero/paybg3.png" alt="" />
        </div>
        <div className="loginbox">
          <div className="login_div_cont">
            <div className="log_main">
              <span className="login_tobler_name">
                <img src="/assets/img/logo/logo_rbg.png" alt="" />
              </span>
              <h6>Back to login</h6>
              <h5>VALIDATE MPIN</h5>

              <div className="margin_top">
                <TextField
                  label="MPIN"
                  className="textfield"
                  type="text"
                  id="text"
                  style={{ width: "100%" }}
                  error={mPinError !== "" ? true : false}
                  helperText={mPinError}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setmPin(value);
                    if (value.length === 6) {
                      setmPincnf(value);
                      setmPinError("");
                    } else {
                      setmPincnf("");
                      setmPinError(
                        "Please enter must and should 6 characters long"
                      );
                    }
                  }}
                  value={mPin}
                  inputProps={{
                    minLength: 6,
                    maxLength: 6,
                  }}
                  autocomplete="off"
                  size="small"
                />
              </div>

              <div className="margin_top">
                <Link>
                  <span className="forget_pass">Forget MPIN?</span>
                </Link>
              </div>
              <div className="margin_top">
                <Button
                  type="primary"
                  onClick={(e) => loginPress(e)}
                  className="log_btn"
                  //   icon={<PoweroffOutlined />}
                  loading={buttonLoading}
                >
                  PROCEED
                </Button>
              </div>
              <div className="margin_top">
                <span className="righr_logs">Powered by </span>
                <Link>
                  <span className="forget_pass">
                    Super Rex Products Pvt.Ltd.
                  </span>
                </Link>
                <span className="righr_logs">. All rights reserved. </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify_Mpin;
