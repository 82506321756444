import React from "react";
import { Link } from "react-router-dom";

const TermsConditions = () => {
  return (
    <>
      <section
        className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"
        data-bg-color="#16243E"
        style={{ background: "#ffff" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title" style={{ fontSize: "35px", color:"black" }}>
                  Terms & Conditions
                </h3>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <div className="breadcrumb__list text-center text-sm-end">
                  <span>
                    <Link to="/" style={{color:"black"}}>Home</Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa-regular fa-angle-right" />
                  </span>
                  <span style={{color:"black"}}>Terms & Conditions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-lg-4">
        <div class="tp-project-details-subtitle-wrapper">
          <h3 class="tp-project-details-subtitle text-center">
            Terms & Conditions for pay2pay
          </h3>
          <p>
            The Access To And Use Of This Website Is Subject To The Following
            Terms And Conditions Of Use ("Terms"). Please Read The Terms Very
            Carefully As They Shall Apply To All Users Of This Website. A User
            May Opt For Services Through This Website Only If He Accepts And
            Abides By All Of The Terms. SUPER REX PRODUCTS PRIVATE LIMITED
            (pay2pay) May Periodically Change These Terms Without.
          </p>
        </div>
        <section className="tp-faq-breadcrumb-area pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                  <div className="accordion" id="general_accordion">
                    <div className="accordion-item tp-faq-active">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type=""
                          data-bs-toggle=""
                          data-bs-target="#"
                          aria-expanded="true"
                          aria-controls=""
                        >
                          Notice
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#general_accordion"
                      >
                        <h4>Definitions</h4>
                        <div className="accordion-body">
                          <p>
                            For The Purpose Of These Terms, The Following Words
                            And Phrases Shall Have The Meaning Assigned To Them
                            Under This Article. "Services" Means The Service
                            Provided By SUPER REX PRODUCTS PRIVATE LIMITED And
                            Service Providers To Users, Whereby A User Can make
                            Payment To Suppliers, Beneficiaries Or Other Payees.
                            That May Be Accepted By SUPER REX PRODUCTS PRIVATE
                            LIMITED(Pay2Pay) From Time To Time In These Terms.
                          </p>
                        </div>
                        <h4>User</h4>

                        <div className="accordion-body">
                          <p>
                            Shall Mean Any Person Who Is Boarded On BRAINUM
                            SUPER REX PRODUCTS PRIVATE LIMITED(Pay2Pay) Platform
                            For Using This Website And/Or Who Transacts Or
                            Avails Of The Services Through This Website, To Make
                            Payments To Third Parties. The Term "User" Shall
                            Also Cover Such Personnel Of Any User Who Are
                            Authorized To Avail Of The Services On Behalf Of The
                            User.
                          </p>
                          <h4>Payee</h4>
                          <p>
                            Means Any Person To Whom A Payment Is Made, Using
                            The Services, (And The Term Includes A User Who Uses
                            The Services To Receive Payment).
                          </p>
                          <h4>Payee</h4>
                          <p>
                            Means Any Person Who Makes A Payment, Using The
                            Services (And The Term Includes A User Who Uses The
                            Services To Make Payment).
                          </p>
                          <h4>Products</h4>
                          <p>
                            Mean Any Services Or Products Which Are Purchased/
                            Offered For Sale By A User To Third Parties From
                            Time To Time, Using These Services. Products Shall
                            Not Include Those Banned Products And Services That
                            Are Listed In Annexure A.
                          </p>
                          <h4>Service Provider</h4>
                          <p>
                            Means A Bank, Association, Facility Provider, Card
                            Issuing Institution, Acquiring Bank, Other Financial
                            Institution, Card Processor, Clearing House Networks
                            Whose Facilities Or Services Are Utilized In The
                            Provision Of These Services.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        We process all bill payments through SUPER REX (Pay2Pay)
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <h4>Transaction</h4>
                      <div className="accordion-body">
                        <p>
                          Means A Payment Instruction That Results In The
                          Successful Transfer Of Monies (A) From A User To A
                          Payee; Or (B) From A Payer To A User.
                        </p>
                      </div>
                      <h4>Transaction Amount</h4>
                      <div className="accordion-body">
                        <p>
                          Means The Total Amount Payable/ Receivable By A
                          User/Payee. This Amount Shall Include All Taxes,
                          Charges, Interest, Delivery Costs, Commissions, Etc.
                        </p>
                      </div>
                      <h4>Eligibility</h4>
                      <div className="accordion-body">
                        <p>
                          The Website And Services Are Intended Solely For
                          Persons Who Can Form Legally Binding Contracts Under
                          Indian Law And Who Are 18 Years Of Age Or Older. Any
                          Access To Or Use Of The Website Or Services By Anyone
                          Under 18 Is Expressly Prohibited. By Using The Website
                          Or Services User Represents And Warrants That He Is 18
                          Or Older And Is Competent To Contract. User Also
                          Agrees That His Payer/ Payee Is Similarly Competent To
                          Contract. A User Represents And Warrants That He Has
                          Full Power And Authority For Using The Services And Is
                          In Strict Compliance Of All Laws. These Terms And
                          Conditions As Accepted By User Shall Be Read Along
                          With And In Addition To Any Terms And Conditions In
                          Force From Time To Time And Issued By Any Service
                          Provider. They Will Also Be Read In Conjunction With
                          The Privacy Policy. A User May Use The Services And
                          This Website Only If He Accepts And Abides By All Of
                          The
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        Following Terms And Conditions.
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <h4>Registration</h4>
                      <div className="accordion-body">
                        <p>
                          In Order To Avail The Services, User Must Be
                          Registered With SUPER REX PRODUCTS PRIVATE
                          LIMITED(Pay2Pay) And The Service Providers. User Shall
                          Provide SUPER REX PRODUCTS PRIVATE LIMITED(Pay2Pay)
                          With All Such Documents As May Be Required. By
                          Registering With SUPER REX PRODUCTS PRIVATE LIMITED
                          (Pay2Pay) , User Agrees To Provide True, Accurate,
                          Current And Complete Information Failing Which SUPER
                          REX PRODUCTS PRIVATE LIMITED(Pay2Pay) May At Any Time
                          Reject User’s Registration And Terminate His Right To
                          Use Or Access The Website And/Or Services. When User
                          Registers With SUPER REX PRODUCTS PRIVATE
                          LIMITED(Pay2Pay) , A User Account Will Be Created, That
                          Is Associated With User’s User Name, Password And
                          Other Identifying Information. User Shall Be
                          Responsible For Anything That Happens Through His User
                        </p>
                      </div>
                      <h4>Account</h4>
                      <h4>Use Of Services</h4>
                      <p>
                        <b>
                          The Services May Be Used By User For Making Payments
                          To Third Parties.
                        </b>
                      </p>
                      <div className="accordion-body">
                        <p>
                          To Enable The User To Use The Services, A Link With
                          Login Credentials Will Be Communicated To The User On
                          His Mobile Phone Or Email Address And The User
                          Irrevocably And Unconditionally Accepts The Sole
                          Responsibility For Use, Confidentiality And Protection
                          Of Said Login Credentials, Including Passwords, As
                          Well As For All Orders And Information Changes Entered
                          Into The Mobile/ E Mail Account Using Such Login
                          Credentials.
                        </p>
                        <p>
                          SUPER REX PRODUCTS PRIVATE LIMITED(Pay2Pay) Has No
                          Obligation To Verify The Authenticity Of The
                          Transaction Other Than By Means Of Verification Of The
                          User’s Basic KYC Information. The User Shall At All
                          Times Take All Appropriate Steps, Including Those As
                          Mentioned Herein, To Maintain The Security And
                          Confidentiality Of The Information. SUPER REX PRODUCTS
                          PRIVATE LIMITED(Pay2Pay) Shall Not Be Liable For Any
                          Mistake Or Misuse Of The Services By Either The User
                          Or By Any Person Authorized By The User, Or By Any
                          Person Gaining Access To The Services Through The
                          User. User Agrees That SUPER REX PRODUCRS PRIVATE
                          LIMITED Accepts All Instructions Originating From His
                          Account In Good Faith And In A Manner Consistent With
                          Commercially Reasonable Security Standards. User Shall
                          Indemnify And Hold SUPER REX PRODUCTS PRIVATE
                          LIMITED(Pay2Pay) Harmless For Direct Or Indirect Losses
                          Sustained As A Result Of The Disclosure Of Sensitive
                          Information By Or Through User.
                        </p>
                        <p>
                          On Receiving The Necessary Details From The Service
                          Provider, SUPER REX PRODUCTS PRIVATE LIMITED(Pay2Pay)
                          Shall Initiate The Required Payment To Payee/User/ As
                          Instructed By User/ Payer. Confirmation Of The
                          Transaction Performed Using Valid Login Credentials
                          Shall Be Conclusive Evidence Of A Transaction Being
                          Effected. User Is Responsible To Furnish SUPER REX
                          PRODUCTS PRIVATE LIMITED(Pay2Pay) With Correct And
                          Current Payee Information. In The Event That The
                          Payment Is In Respect Of A Purchase Of Products By The
                          User/Payee, SUPER REX PRODUCTS PRIVATE LIMITED(Pay2Pay)
                          Shall Not Be Required To Ensure That The Purchased
                          Products Have Been Duly Delivered. In The Event A User
                          Chooses To Complain About A Transaction, The Same
                          Should Be Communicated To SUPER REX PRODUCTS PRIVATE
                          LIMITED(Pay2Pay) Within 1 (one) Day Of The Transaction.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        Each User Shall Be Responsible To:
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <h4>
                        Furnish Correct And Accurate Information Of The Payee As
                        May Be Required, On An Independent Basis;
                      </h4>
                      <div className="accordion-body">
                        <p>
                          User Shall Not Carry Out Any Activity, Which Is
                          Banned, Illegal Or Immoral Use The Services In Any
                          Manner Or In Furtherance Of Any Activity, Which
                          Constitutes A Violation Of Any Law Or Regulation Or
                          Which May Cause BRAINUM  To Be Subject To
                          Investigation, Prosecution Or Legal Action.
                        </p>
                        <p>
                          User Undertakes And Assures To BRAINUM  That Payments
                          Shall Not Be Made In Respect Of Any Products Mentioned
                          In The Banned Items List Set Out In Annexure A Hereto.
                        </p>
                        <p>
                          User Shall Act In Compliance With All Laws, Rules And
                          Regulations And Shall At All Times Comply With The
                          Guidelines Set By Service Providers.
                        </p>
                        <p>
                          User Shall Not Sell, Provide, Exchange, Or Otherwise
                          Disclose To Third Parties Or Use Themselves (Other
                          Than For The Purpose Of Completing A Transaction, Or
                          As Specifically Required By Law) Any Personal
                          Information About Any Third Party, Including The
                          Account Details And Mobile Number, Without Obtaining
                          The Prior Written Consent Of Such Third Party.
                        </p>
                        <p>
                          User Shall Take All Precautions As May Be Feasible Or
                          As May Be Directed By SUPER REX (Pay2Pay)  To Ensure
                          That There Is No Breach Of Security And That The
                          Integrity Of The Link Between Their Systems/ Site, The
                          Website And The Payment Mechanism Is Maintained At All
                          Times. In The Event Of Any Loss Being Caused As A
                          Result Of The Link Being Breached Or As A Consequence
                          Of The Link Being Improper Or Being In Violation Of
                          The Provisions Of This Clause, The Loss Shall Be To
                          The Account Of The User And The User Shall Indemnify
                          And Keep Indemnified BY SUPER REX (Pay2Pay) And The
                          Service Providers From Any Loss As May Be Caused In
                          This Regard. User Shall Bear And Be Responsible For
                          The Payment Of All Relevant Taxes (Including Any
                          Applicable Withholding Taxes) As May Be Due.
                        </p>
                        <p>
                          A User Shall Not At Any Time Require Any Other User To
                          Provide Him With Any Details Of The Accounts Held By
                          The Other Users With Any Banks Including, The
                          Passwords, Account Number, Card Numbers, Mobile Phone
                          Numbers And PIN Which May Be Assigned To Them By The
                          Banks From Time To Time User Shall Use The Information
                          Regarding A Payee/ Payer (Including Name, Address,
                          E-Mail Address, Telephone Numbers And Other Data)
                          Conveyed To Him Whist Using The Services, Only For The
                          Purpose Of Completing The Transaction For Which It Was
                          Furnished, And Not To Sell Or Otherwise Furnish Such
                          Information To Others Unless He Has An Independent
                          Source Of Such Information Or Obtains The Express
                          Consent Of Such Payee/ Payer.
                        </p>
                        <p>
                          A User Shall Inform SUPER REX (Pay2Pay)  Of Any Change
                          In His Email Address, Mobile Number, Address,
                          Ownership Or Legal Status Or His Cessation Of Business
                          In Writing 30 Working Days In Advance Of Such Change.
                          User Shall Not Interfere With Or Damage The Website,
                          Including, Without Limitation, Through The Use Of
                          Viruses, Cancel Bots, Trojan Horses, Harmful Code,
                          Flood Pings, Denial-Of-Service Attacks, Backdoors,
                          Packet Or IP Spoofing, Forged Routing Or Electronic
                          Mail Address Information Or Similar Methods Or
                          Technology.
                        </p>
                        <p>
                          SUPER REX(Pay2Pay)  Has The Right To Investigate And
                          Prosecute Violations Of Any Of The Above To The
                          Fullest Extent Of The Law.
                        </p>
                      </div>
                      <h4>Fees</h4>

                      <div className="accordion-body">
                        <p>
                          In Consideration Of The Services Rendered By SUPER
                          REX(Pay2Pay) To The User, The User Shall Pay To SUPER
                          REX(Pay2Pay  A Fee That Is Agreed Upon In The Fee
                          Schedule Defined In The Merchant Agreement or SUPER
                          REX (Pay2Pay)  website Or Proposed By SUPER REX
                          (Pay2Pay)  Over Email To The Registered Email Address.
                        </p>
                        <p>
                          SUPER REX (Pay2Pay) Shall Deduct Its Transaction Fees
                          Plus Service Tax Per Successful Transaction And Make
                          Payment Of Of The Transaction Amount To User/ Payee’s
                          Designated Bank Account. All Other Taxes, Duties Or
                          Charges Shall Be Borne And Paid By User, Unless
                          Otherwise Agreed Between The Parties.  SUPER REX
                          (Pay2Pay)  Reserves The Right To Alter / Modify /
                          Change The Discount / Commission Rate At Its
                          Discretion.
                        </p>
                        <p>
                          SUPER REX (Pay2Pay) Also Reserves The Right To
                          Forthwith Revise The Transaction Fee Payable In The
                          Event Of Any Revision In The Rates Charges By The
                          Acquiring Banks Or Card Associations Or Guidelines
                          Issued By The RBI From Time To Time. It Is Hereby
                          Agreed And Acknowledged By The Parties That The
                          Transaction Fees Charged By SUPER REX (Pay2Pay) In
                          Respect Of A Transaction That Has Been Confirmed Shall
                          Not Be Returned Or Repaid By SUPER REX (Pay2Pay) To The
                          User Or Any Other Person Irrespective Of The
                          Transaction Being Rejected, Charged Back, Refunded Or
                          Disputed.
                        </p>
                        <h4>Specific Terms</h4>
                        <p>
                          <b>
                            SUPER REX (Pay2Pay) is a payment processor for the
                            client and does not have any control of the
                            activities/actions of the business it is helping
                            process payments for.
                          </b>
                          <p>
                            SUPER REX (Pay2Pay) Will Not Be Responsible For Any
                            Disputes Between The Users And The Payers/ Payees,
                            Including Issues Regarding Prices, Delivery,
                            Non-Delivery Or Delay In Delivery Of The Products,
                            Quality Of Products Or Otherwise. All Such Disputes
                            Will Be Dealt With By And Between The User And The
                            Payer/ Payee Directly, And SUPER REX (Pay2Pay) Shall
                            Not Be A Party To Such Disputes.
                          </p>
                          <p>
                            SUPER REX (Pay2Pay) Shall Not Be A Party To The
                            Agreement Between The User And Any Payee In Any
                            Manner Whatsoever. All Contracts Are Directly
                            Between Users And Their Payees. User Shall Be
                            Responsible For Any Miscommunication Or Incorrect
                            User/ Third Party/ Other Information That May Be
                            Provided To SUPER REX (Pay2Pay) At The Time Of
                            Enabling The Services For The User.
                          </p>
                          <p>
                            Any request from the bank or the legal authorities
                            to block an account or mark a lien on any amount of
                            the client will be passed on to the client and the
                            lien of the said amount or unblocking of the account
                            will only happen once cleared by the bank or by the
                            concerned authorities SUPER REX (Pay2Pay) / Service
                            Provider Reserve The Right To Impose Limits On The
                            Number Of Transactions Which May Be Charged On An
                            Account During Any Time Period, And Reserve The
                            Right To Refuse To Make Payments In Respect Of
                            Transactions Exceeding Such Limit. SUPER REX
                            (Pay2Pay) / Service Provider Also Reserve The Right
                            To Refuse To Make Payments In Respect Of
                            Transactions By Users With A Prior History Of
                            Questionable Charges.
                          </p>
                          <h4>Security</h4>
                          <p>
                            Subject To The Provisions Stated Herein And As
                            Specified By SUPER REX (Pay2Pay) From Time To Time,
                            The User Will Not Hold SUPER REX (Pay2Pay) Liable In
                            Case Of Any Improper/ Fraudulent/Unauthorized/
                            Duplicate/Erroneous Use Of His Mobile And/Or The Web
                            Based Access. SUPER REX (Pay2Pay) Will Also Not Be
                            Liable For Any Consequences Connected With The Use/
                            Misuse Of User’s Mobile/ E Mail Account By Any Third
                            Party. If Any Third Parties Gain Access To The
                            Services, The User Will Be Responsible And Shall
                            Indemnify SUPER REX (Pay2Pay) Against Any Liability,
                            Costs Or Damages Arising Out Of Such Misuse / Use By
                            Third Parties Based Upon Or Relating To Such Access
                            And Use, Or Otherwise.
                          </p>
                          <p>
                            The User Shall Be Fully Liable For: (A) Any
                            Unauthorised Use Of His Mobile/ Email Account;
                            And/Or (B) All Authorised Transactions On His
                            Mobile/Email Account. Without Prejudice To The
                            Remedies Available To SUPER REX (Pay2Pay) And These
                            Terms, SUPER REX (Pay2Pay) Shall Be Under No
                            Liability Whatsoever To The User In Respect Of Any
                            Loss Or Damage Arising Directly Or Indirectly Out
                            Of:
                          </p>
                          <h4>Service Provider</h4>
                          <p>
                            Means A Bank, Association, Facility Provider, Card
                            Issuing Institution, Acquiring Bank, Other Financial
                            Institution, Card Processor, Clearing House Networks
                            Whose Facilities Or Services Are Utilized In The
                            Provision Of These Services. any defect in any
                            Products/Services supplied to them;
                          </p>
                          <p>
                            <b>
                              any inability of a third party to supply or
                              deliver the required Products in the necessary
                              numbers or types;
                            </b>
                            <ul>
                              <li>
                                the refusal of any person (including a Service
                                Provider) to honour or accept a payment;
                              </li>
                              <li>
                                the malfunction of any computer terminal or
                                equipment;
                              </li>
                              <li>
                                the utilization of the Services by any person
                                other than by the User;
                              </li>
                              <li>
                                any mis-statement, error or omission in any
                                details disclosed to SUPER REX (Pay2Pay) .
                              </li>
                            </ul>
                            <h4>Termination</h4>
                            <p>
                              If Either SUPER REX (Pay2Pay) Or Service Provider
                              Suspects, On Reasonable Grounds, That A User Has
                              Committed A Breach Of These Terms Or Any Other
                              Agreement With SUPER REX (Pay2Pay) Or Any Act Of
                              Dishonesty Or Fraud Against SUPER REX (Pay2Pay) /
                              Any Service Provider, SUPER REX (Pay2Pay) Shall Be
                              Entitled To (A) Suspend All Payment Under These
                              Terms; (B) Deactivate/ Suspend Your User Account
                              And Disable Your Password; And (C) Terminate
                              User’s Access To The Website Or The Services;
                              Pending Enquiries By SUPER REX (Pay2Pay) . User May
                              Cancel His User Account At Any Time By Sending
                              SUPER REX (Pay2Pay) An Email. Please Note That Once
                              Your Account Is Cancelled, SUPER REX (Pay2Pay) Does
                              Not Have Any Obligation To Return Any Data Or
                              Information That May Reside On Its Servers Or
                              Other Equipment.
                            </p>
                            <h4>Indemnity</h4>
                            <p>
                              User Shall Keep SUPER REX (Pay2Pay) Indemnified
                              From And Against Any And All Liability (Including
                              But Not Limited To Liabilities, Judgments,
                              Damages, Losses, Claims, Costs And Expenses,) Or
                              Any Other Loss That May Occur, Arising From Or
                              Relating To Any Claim, Suit Or Proceeding Brought
                              Against SUPER REX (Pay2Pay) By Another User/
                              Service Provider/ Third Party For Reasons
                              Including, But Not Limited To (I) Delivery, Non
                              Delivery Or Delay, Deficiency Or Mistake In
                              Respect Of The Products Sold; (Ii) A Breach,
                              Non-Performance, Non Compliance Or Inadequate
                              Performance By The User Of Any Of The Terms,
                              Conditions, Representations, Obligations Or
                              Warranties Made By Him; (Iii) Any Acts, Errors,
                              Misrepresentations, Wilful Misconduct Or
                              Negligence Of The User, Or His Employees,
                              Subcontractors And Agents In Performance Of Their
                              Obligations Under These Terms. The User Shall
                              Comply With All Such Terms And Conditions As SUPER
                              REX (Pay2Pay) Or Any Service Provider May Prescribe
                              From Time To Time With Regard To The Services. All
                              Transactions Effected By Or Through This Website,
                              Shall Constitute Legally Binding And Valid
                              Transactions When Done In Adherence To And In
                              Compliance With The Terms And Conditions
                              Prescribed By SUPER REX (Pay2Pay) Or Seller/
                              Service Provider.
                            </p>
                            <h4>Confidentiality</h4>
                            <p>
                              User Agrees Not To Disclose Or Attempt To Use Or
                              Personally Benefit From Any Non-Public Information
                              That He May Learn On The Website Or Through The
                              Services. This Obligation Shall Continue Until
                              Such Time As The Non-Public Information Has Become
                              Publicly Known Through No Action Of Your Own. If
                              You Are Compelled By Order Of A Court Or Other
                              Governmental Or Legal Body (Or Have Notice That
                              Such An Order Is Being Sought) To Divulge Any Such
                              Non-Public Information, You Agree To Promptly And
                              Diligently Notify SUPER REX (Pay2Pay) And Cooperate
                              Fully With SUPER REX (Pay2Pay) In Protecting Such
                              Information To The Extent Possible Under
                              Applicable Law.
                            </p>
                            <p>
                              SUPER REX (Pay2Pay) May Access, Preserve And
                              Disclose Any Of Your Information If Required To Do
                              So By Law, Or If We Believe In Good Faith That It
                              Is Reasonably Necessary To (I) Respond To Claims
                              Asserted Against SUPER REX (Pay2Pay) Or To Comply
                              With Legal Process, (Ii) For Fraud Prevention,
                              Risk Assessment, Investigation, Customer Support,
                              Product Development And De-Bugging Purposes, Or
                              (Iii) Protect The Rights, Property Or Safety Of
                              SUPER REX (Pay2Pay) , Its Users Or Members Of The
                              Public.
                            </p>
                            <h4>Intellectual Property Rights</h4>
                            <p>
                              The Website Content And The Trademarks, Service
                              Marks And Logos Contained Therein ("Marks") Are
                              Owned By Or Licensed To SUPER REX (Pay2Pay) ,
                              Subject To Copyright And Other Intellectual
                              Property Rights Under The Law And International
                              Conventions. Such Website Content May Not Be
                              Copied, Reproduced, Distributed, Transmitted,
                              Broadcast, Displayed, Sold, Licensed, Uploaded, Or
                              Otherwise Exploited Without The Prior Written
                              Consent Of SUPER REX (Pay2Pay) .
                            </p>
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditions;
