import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import {
  new_user_Update,
  setForm_status,
  validate_User_Aadhar,
} from "../../redux/authentication/AuthenticationSlice";

const Shop_Details = () => {
  const dispatch = useDispatch();
  const { new_user_registation } = useSelector((store) => store.Authentication);

  const [Shop_Name, setShop_Name] = useState("");
  const [shop_reg_date, setshop_reg_date] = useState(null);
  const [noemployes, setnoemployes] = useState("");
  const [qualifications, setqualifications] = useState("");
  const [shop_licence, setshop_licence] = useState("");
  const [shop_licence_confirm, setshop_licence_confirm] = useState("");
  const [shop_Pin_Code, setshop_Pin_Code] = useState("");
  const [shop_Pin_CodeConfirm, setshop_Pin_CodeConfirm] = useState("");
  const [shop_City, setshop_City] = useState("");
  const [shop_District, setshop_District] = useState("");
  const [shop_State, setshop_State] = useState("");
  const [shop_Complete_Address, setshop_Complete_Address] = useState("");

  const [Shop_NameError, setShop_NameError] = useState("");
  const [shop_reg_dateError, setshop_reg_dateError] = useState("");
  const [noEmployiesError, setnoEmployiesError] = useState("");
  const [qualificationsError, setqualificationsError] = useState("");
  const [shop_licenceError, setshop_licenceError] = useState("");
  const [shop_Pin_CodeError, setshop_Pin_CodeError] = useState("");
  const [shop_CityError, setshop_CityError] = useState("");
  const [shop_DistrictError, setshop_DistrictError] = useState("");
  const [shop_StateError, setshop_StateError] = useState("");
  const [shop_Complete_AddressError, setshop_Complete_AddressError] =
    useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  useEffect(() => {
    if (new_user_registation.formStep > 3) {
      setShop_Name(new_user_registation.Shop_Name);
      setshop_reg_date(new_user_registation.shop_reg_date);
      setnoemployes(new_user_registation.no_of_employee);
      setqualifications(new_user_registation.qualifications);
      setshop_licence(new_user_registation.shop_licence);
      setshop_licence_confirm(new_user_registation.shop_licence);
      setshop_Pin_Code(new_user_registation.shop_Pin_Code);
      setshop_Pin_CodeConfirm(new_user_registation.shop_Pin_Code);
      setshop_City(new_user_registation.shop_City);
      setshop_District(new_user_registation.shop_District);
      setshop_State(new_user_registation.shop_State);
      setshop_Complete_Address(new_user_registation.shop_Complete_Address);
    }
  }, [new_user_registation]);

  const validateSho_Licences = async (e) => {
    const inputValue = e.target.value;
    setshop_licence(inputValue);
    const verify_number = await dispatch(validate_User_Aadhar(inputValue));
    if (verify_number.payload.success === true) {
      setshop_licenceError("licence number already exits");
      // setmobile("");
    } else {
      setshop_licence_confirm(inputValue);
      setshop_licenceError("");
    }
  };

  const roles = [
    { role: "Bachelor's Degrees" },
    { role: "Master's Degrees" },
    { role: "Doctorate Degrees" },
    { role: "Chartered and Professional Courses" },
    { role: "Engineering and Technology Diplomas" },
  ];

  useEffect(() => {
    if (btnPress === true) {
      if (Shop_Name === "") {
        setShop_NameError("Required");
      } else {
        setShop_NameError("");
      }
      if (shop_reg_date === "") {
        setshop_reg_dateError("Required");
      } else {
        setshop_reg_dateError("");
      }
      if (noemployes === "") {
        setnoEmployiesError("Required");
      } else {
        setnoEmployiesError("");
      }
      if (qualifications === "") {
        setqualificationsError("Required");
      } else {
        setqualificationsError("");
      }
      if (shop_licence_confirm === "") {
        setshop_licenceError("Required");
      } else {
        setshop_licenceError("");
      }
      if (shop_Pin_CodeConfirm === "") {
        setshop_Pin_CodeError("Required");
      } else {
        setshop_Pin_CodeError("");
      }
      if (shop_City === "") {
        setshop_CityError("Required");
      } else {
        setshop_CityError("");
      }
      if (shop_District === "") {
        setshop_DistrictError("Required");
      } else {
        setshop_DistrictError("");
      }
      if (shop_State === "") {
        setshop_StateError("Required");
      } else {
        setshop_StateError("");
      }
      if (shop_Complete_Address === "") {
        setshop_Complete_AddressError("Required");
      } else {
        setshop_Complete_AddressError("");
      }
    }
  }, [
    btnPress,
    Shop_Name,
    noemployes,
    qualifications,
    shop_licence_confirm,
    shop_Pin_CodeConfirm,
    shop_City,
    shop_District,
    shop_State,
    shop_Complete_Address,
    shop_reg_date,
  ]);

  const user_Update_click = async () => {
    setbtnLoading(true);
    setbtnPress(true);

    if (
      Shop_Name !== "" &&
      noemployes !== "" &&
      qualifications !== "" &&
      shop_licence_confirm !== "" &&
      shop_Pin_CodeConfirm !== "" &&
      shop_City !== "" &&
      shop_District !== "" &&
      shop_State !== "" &&
      shop_Complete_Address !== ""
    ) {
      const form_data = {
        Shop_Name: Shop_Name,
        shop_reg_date: shop_reg_date,
        no_of_employee: noemployes,
        qualifications: qualifications,
        shop_licence: shop_licence_confirm,
        shop_Pin_Code: shop_Pin_CodeConfirm,
        shop_City: shop_City,
        shop_District: shop_District,
        shop_State: shop_State,
        shop_Complete_Address: shop_Complete_Address,
        formStep: 4,
        userId: new_user_registation._id,
        submited_status: true,
      };
      const new_user_Updates = await dispatch(new_user_Update(form_data));
      if (new_user_Updates.payload.success) {
        setbtnLoading(false);
        setbtnPress(false);
      }
    } else {
      setbtnLoading(false);
    }
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));
  return (
    <>
      <div className="inputs_container">
        <div className="dn_input_box">
          <TextField
            label="Shop Name"
            type="text"
            id="text"
            className="input_style"
            error={Shop_NameError !== "" ? true : false}
            helperText={Shop_NameError}
            value={Shop_Name}
            onChange={(e) =>
              setShop_Name(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Shop Registation Date"
              className="input_style"
              size="small"
              // value={shop_reg_date}
              onChange={(e) => {
                const date = JSON.stringify(moment(e).format("DD/MM/YYYY"));
                setshop_reg_date(date);
              }}
              renderInput={(params) => (
                <TextField
                  error={shop_reg_dateError !== "" ? true : false}
                  helperText={shop_reg_dateError}
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="dn_input_box">
          <TextField
            label="No. of Employes"
            type="text"
            id="text"
            className="input_style"
            error={noEmployiesError !== "" ? true : false}
            helperText={noEmployiesError}
            value={noemployes}
            onChange={(e) => setnoemployes(e.target.value.replace(/\D/g, ""))}
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            id="select"
            label="Qualifications"
            select
            className="input_style"
            error={qualificationsError !== "" ? true : false}
            helperText={qualificationsError}
            value={qualifications}
            onChange={(e) => setqualifications(e.target.value)}
            size="small"
          >
            {roles.map((data, index) => (
              <MenuItem key={index} value={data.role}>
                {data.role}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="dn_input_box">
          <TextField
            label="Shop Licence"
            type="text"
            id="text"
            className="input_style"
            error={shop_licenceError !== "" ? true : false}
            helperText={shop_licenceError}
            value={shop_licence}
            onChange={(e) => validateSho_Licences(e)}
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="Pin Code"
            type="text"
            id="text"
            className="input_style"
            error={shop_Pin_CodeError !== "" ? true : false}
            helperText={shop_Pin_CodeError}
            value={shop_Pin_Code}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, "");
              setshop_Pin_Code(inputValue);
              if (inputValue.length !== 6) {
                setshop_Pin_CodeError(
                  "*Error! you have entered invalid Aadhar number."
                );
              } else {
                setshop_Pin_CodeConfirm(inputValue);
                setshop_Pin_CodeError("");
              }
            }}
            inputProps={{
              minLength: 6,
              maxLength: 6,
            }}
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="City"
            type="text"
            id="text"
            className="input_style"
            error={shop_CityError !== "" ? true : false}
            helperText={shop_CityError}
            value={shop_City}
            onChange={(e) =>
              setshop_City(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="District"
            type="text"
            id="text"
            className="input_style"
            error={shop_DistrictError !== "" ? true : false}
            helperText={shop_DistrictError}
            value={shop_District}
            onChange={(e) =>
              setshop_District(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="State"
            type="text"
            id="text"
            className="input_style"
            error={shop_StateError !== "" ? true : false}
            helperText={shop_StateError}
            value={shop_State}
            onChange={(e) =>
              setshop_State(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="Complete Address"
            className="input_style"
            error={shop_Complete_AddressError !== "" ? true : false}
            helperText={shop_Complete_AddressError}
            value={shop_Complete_Address}
            onChange={(e) =>
              setshop_Complete_Address(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            id="outlined-multiline-static"
            multiline
            rows={4}
          />
        </div>
        <div className="button_container">
          {new_user_registation.formStep > 3 ? (
            <>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={() => dispatch(setForm_status(4))}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    Loading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Save"
                )}
              </LoadingButton>
            </>
          ) : (
            <>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => user_Update_click(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    Loading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Shop_Details;
