import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import {
  fetch_bill,
  get_plance_by_number,
  get_single_biller_info,
  pay_bill_payments,
  paybill_plan,
} from "../redux/services/ServiceSlice";

import { Typography, message } from "antd";

const { Title } = Typography;
const PostpaidMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  const { bbps_services_list, bbps_circle_list } = useSelector(
    (state) => state.services
  );

  const [selected_Services, setselected_Services] = useState("");
  const [selected_Services_Error, setselected_Services_Error] = useState("");

  const [states_eletric_boards, setstates_eletric_boards] = useState([]);
  const [selected_board, setselected_board] = useState("");
  const [biller_name, setbiller_name] = useState("");
  const [biller__id, setbiller__id] = useState("");
  const [board_error, setboard_error] = useState("");

  const [selected_state, setselected_state] = useState("");
  const [circle_name, setcircle_name] = useState("");
  const [circle_id, setcircle_id] = useState("");
  const [states_Error, setstates_Error] = useState("");

  const [bill_number, setbill_number] = useState("");
  const [bill_number_Error, setbill_number_Error] = useState("");

  const [paramsName, setparamsName] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  const [btnLoading2, setbtnLoading2] = useState(false);
  const [btnPress2, setbtnPress2] = useState(false);
  const [disabled_step_2, setdisabled_step_2] = useState(false);

  const [bill_details, setbill_details] = useState("");
  const [disabled_step_1, setdisabled_step_1] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [payment_success, setpayment_success] = useState("");

  useEffect(() => {
    if (btnPress === true) {
      if (circle_name === "" || selected_state === "") {
        setstates_Error("Required");
      } else {
        setstates_Error("");
      }
      if (selected_board === "" || biller_name === "" || paramsName === "") {
        setboard_error("Required");
      } else {
        setboard_error("");
      }
      if (bill_number === "") {
        setbill_number_Error("Required");
      } else {
        setbill_number_Error("");
      }
    }
  }, [
    btnPress,
    circle_name,
    selected_state,
    selected_board,
    biller_name,
    bill_number,
    paramsName,
  ]);

  useEffect(() => {
    let current_services = "MOBILE POSTPAID";
    if (current_services) {
      const current_category = current_services;
      setselected_Services(current_category);

      if (current_category !== "") {
        const get_cat = async () => {
          const get_biller_info = await dispatch(
            get_single_biller_info(current_category)
          );
          if (get_biller_info.meta.requestStatus === "fulfilled") {
            const biller_data = get_biller_info.payload.sort((a, b) =>
              a.billerName > b.billerName ? 1 : -1
            );
            const fet_mendetory = biller_data.filter(
              (data) => data.fetchOption === "MANDATORY"
            );
            setstates_eletric_boards(fet_mendetory);
          }
        };
        get_cat();
      }
    }
  }, []);

  const select_state_press = async (value) => {
    setcircle_name("");
    setcircle_id("");
    setselected_state("");
    setselected_state(value);
    const find_single_circle = bbps_circle_list.find(
      (data) => data.circleRefID === value
    );
    setcircle_name(find_single_circle.circleName);
    setcircle_id(find_single_circle.circleRefID);
  };
  const select_electricit_press = (value) => {
    if (value !== "") {
      setselected_board(value);
      const find_board = states_eletric_boards.find(
        (data) => data.billerId === value
      );
      setbiller_name(find_board.billerName);
      setbiller__id(find_board.billerId);
      setparamsName(find_board.customerParams[0].paramName);
    }
  };

  const billnumber_press = async (e) => {
    const value = e.target.value;
    setbill_number(value);
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now
      .toISOString()
      .replace(/[-:.TZ]/g, "")
      .slice(0, 14);
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  const get_bill = async (e) => {
    setdisabled_step_1(true);
    setbtnPress(true);
    setbtnLoading(true);
    const currentTime = getCurrentTime();
    const randomCharacter = generateRandomString(21);
    const newString = `${currentTime}${randomCharacter}`;

    if (
      selected_Services !== "" &&
      biller_name !== "" &&
      circle_name !== "" &&
      bill_number !== "" &&
      paramsName !== ""
    ) {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });

      const bill_form_data = {
        billerId: biller__id,
        category: selected_Services,
        customerParams: {
          [paramsName]: bill_number,
        },
        merchantTrxnRefId: newString,
        mobileNo: loginData.mobile,
        quickPay: "true",
      };
      const get_bill = await dispatch(fetch_bill(bill_form_data));

      if (get_bill.payload.success === true) {
        setbill_details(get_bill.payload.get_bill);
        messageApi.open({
          type: "success",
          content: "Succefully Fetched Your Bill Details",
        });
      } else {
        messageApi.open({
          type: "error",
          content: `${get_bill.payload.get_bill.reason}`,
        });
        setdisabled_step_1(false);
      }
      setbtnLoading(false);
      //0208078096
    } else {
      setbtnLoading(false);
      setdisabled_step_1(false);
    }
  };

  const comple_bill_recharge = async (e) => {
    setdisabled_step_2(true);
    setbtnLoading2(true);
    setbtnPress2(true);

    if (
      // Number(bill_details.amount) > 0 &&
      bill_details !== "" &&
      biller_name !== ""
    ) {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });
      const billpay = {
        additionalInfo: "",
        amount: bill_details.amount,
        billerId: biller__id,
        billerName: biller_name,
        category: selected_Services,
        customerMobile: loginData.mobile,
        customerMobileNo: loginData.mobile,
        customerName: loginData.Full_Name,
        customerEmailId: loginData.email,
        customerParams: {
          [paramsName]: bill_number,
        },
        merchantTrxnRefId: bill_details.refId,
        billerReferenceNumber: bill_details.refId,
        paymentId: "FidyPay",
        paymentMode: "WALLET",
        quickPay: "false",
        remark: "Bill Payment",
        splitPay: "",
        splitPayAmount: "",
        userid: loginData._id,
      };
      const pay_bill = await dispatch(pay_bill_payments(billpay));
      if (pay_bill.payload.success === true) {
        const responce = pay_bill.payload.transactions;
        setpayment_success(pay_bill.payload.transactions);
        if (responce.code === "0x0200" || responce.code === "200") {
          messageApi.open({
            type: "success",
            content: "Recharge Succefully Done",
          });
        } else {
          messageApi.open({
            type: "error",
            content: `${pay_bill.payload.massage}`,
          });
        }
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } else {
        messageApi.open({
          type: "error",
          content: `${pay_bill.payload.massage}`,
        });
        setdisabled_step_2(false);
        setdisabled_step_1(false);
        setbill_details("");
      }
      setbtnLoading2(false);
      setdisabled_step_2(false);
    } else {
      setbtnLoading2(false);
      setdisabled_step_2(false);
    }
  };

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
          .image_suss_box {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_suss_box img {
  width: 20%;
  height: 50%;
}
  .class_capitalize {
  text-transform: lowercase !important;
}
  .inputs_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2vh;
  padding-top: 3vh;
  display: flex;
  justify-content: center;
  padding-bottom: 3vh;
}
.inputs_container .dn_input_box {
  width: 48%;
  display: flex;
  justify-content: center;
}
  .dn_input_box .name_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vh;
  border-bottom: 1px solid gainsboro;
}
.inputs_container .success_input_box {
  width: 98%;
  display: flex;
  justify-content: center;
}
  .name_details {
  color: #060264;
  font-size: 1rem;
  font-weight: 600;
}
.value_details {
  color: #0c0c10;
  font-size: 1rem;
  font-weight: 600;
}
         </style>
        </head>
        <body>
         <div class="main_fragnent_container">
        <div class="main_recha_container">
          <div class="inputs_container">
                <div class="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NAME :</span>
                    <span class="value_details">
                      ${payment_success.name}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NUMBER :</span>
                    <span class="value_details">
                    ${payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">AMOUNT :</span>
                    <span class="value_details">
                     ${payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">BILLER NAME:</span>
                    <span class="value_details">
                      ${payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">CATEGORY NAME:</span>
                    <span class="value_details">
                      ${payment_success.category}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT MODE :</span>
                    <span class="value_details">
                      ${payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT STATUS :</span>
                    <span class="value_details">
                      ${payment_success.status}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">DATE :</span>
                    <span class="value_details">
                      ${payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">REFERANCE ID :</span>
                    <span class="value_details">
                      ${payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">TRANSACTION ID :</span>
                    <span class="value_details">
                      ${payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div class="success_input_box">
                  <div class="name_box">
                    <span class="name_details">
                      MESSAGE / FAILED REASON :
                      <span class="value_details class_capitalize">
                        TRANSACTION IS PROCESSED AND THE MONEY HAS BEEN CREDITED
                        INTO THE BENEFICIARY ACCOUNT.
                      </span>
                    </span>
                  </div>
                </div>
                <div class="thank_note">
                  <span class="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
          
              </div>
              </div>
              </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container">
        <div className="main_recha_container">
          {payment_success === "" ? (
            <>
              <div className="inputs_container">
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Select State"
                    select
                    className="input_style"
                    error={states_Error !== "" ? true : false}
                    helperText={states_Error}
                    value={selected_state}
                    onChange={(e) => select_state_press(e.target.value)}
                    size="small"
                    disabled={disabled_step_1}
                  >
                    {bbps_circle_list &&
                      bbps_circle_list.map((data, index) => (
                        <MenuItem key={index} value={data.circleRefID}>
                          {data.circleName}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Select Operator"
                    select
                    className="input_style"
                    error={board_error !== "" ? true : false}
                    helperText={board_error}
                    value={selected_board}
                    onChange={(e) => select_electricit_press(e.target.value)}
                    size="small"
                    disabled={disabled_step_1}
                  >
                    {states_eletric_boards &&
                      states_eletric_boards.map((data, index) => (
                        <MenuItem key={index} value={data.billerId}>
                          {data.billerName}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Mobile Number"
                    type="text"
                    id="text"
                    className="input_style"
                    error={bill_number_Error !== "" ? true : false}
                    helperText={bill_number_Error}
                    onChange={(e) => billnumber_press(e)}
                    value={bill_number}
                    autocomplete="off"
                    size="small"
                    disabled={disabled_step_1}
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                    }}
                  />
                </div>
                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
                <div className="button_container">
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={(e) => get_bill(e)}
                    disabled={disabled_step_1}
                  >
                    {btnLoading ? (
                      <>
                        LOading
                        <LoadingIndicator size={24} />
                      </>
                    ) : (
                      "Get Bill"
                    )}
                  </LoadingButton>
                </div>
              </div>

              {bill_details !== "" && (
                <div className="inputs_container">
                  <div className="radio_div_container">
                    <Title level={5} className="mx-2">
                      FASTag Details
                    </Title>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Amount :</span>
                      <span className="value_details">
                        {bill_details.amount}
                      </span>
                    </div>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Name :</span>
                      <span className="value_details">
                        {bill_details.accountHolderName}
                      </span>
                    </div>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Due Date :</span>
                      <span className="value_details">
                        {bill_details.dueDate === ""
                          ? "NULL"
                          : bill_details.dueDate}
                      </span>
                    </div>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Bill Mounth:</span>
                      <span className="value_details">
                        {bill_details.additionalParams.BILLMONTH}
                      </span>
                    </div>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Bill Number :</span>
                      <span className="value_details">{bill_number}</span>
                    </div>
                  </div>
                  <div className="dn_input_box">
                    <div className="name_box">
                      <span className="name_details">Message / Reason :</span>
                      <span className="value_details">
                        Bill Fetched Success
                      </span>
                    </div>
                  </div>

                  <div className="dn_input_box">
                    <div className="input_style"></div>
                  </div>
                  <div className="button_container">
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={(e) => comple_bill_recharge(e)}
                      disabled={disabled_step_2}
                    >
                      {btnLoading2 ? (
                        <>
                          Loading
                          <LoadingIndicator size={24} />
                        </>
                      ) : (
                        "Complete Bill payment"
                      )}
                    </LoadingButton>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="inputs_container">
                <div className="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">NAME :</span>
                    <span className="value_details">
                      {payment_success.name}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">NUMBER :</span>
                    <span className="value_details">
                      {payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">AMOUNT :</span>
                    <span className="value_details">
                      {payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">BILLER NAME:</span>
                    <span className="value_details">
                      {payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">CATEGORY NAME:</span>
                    <span className="value_details">
                      {payment_success.category}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">PAYMENT MODE :</span>
                    <span className="value_details">
                      {payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">PAYMENT STATUS :</span>
                    <span className="value_details">
                      {payment_success.status}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">DATE :</span>
                    <span className="value_details">
                      {payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">REFERANCE ID :</span>
                    <span className="value_details">
                      {payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div className="dn_input_box">
                  <div className="name_box">
                    <span className="name_details">TRANSACTION ID :</span>
                    <span className="value_details">
                      {payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div className="success_input_box">
                  <div className="name_box">
                    <span className="name_details">
                      MESSAGE / FAILED REASON :{" "}
                      <span className="value_details class_capitalize">
                        TRANSACTION IS PROCESSED AND THE MONEY HAS BEEN CREDITED
                        INTO THE BENEFICIARY ACCOUNT.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="thank_note">
                  <span className="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
                <div className="profile_co_header_right">
                  <div
                    className="header_box width2 currsor_po"
                    onClick={() => handlePrint()}
                  >
                    <span>Print Receipt</span>
                  </div>
                  <div
                    className="header_box width1 currsor_po"
                    onClick={() => handlePrint()}
                  >
                    <span>Download Receipt</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PostpaidMobile;
