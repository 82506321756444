import React from 'react'

const Service = () => {
  return (
    <>
      <div className='services_containermain mb-30'>
        <div className="services_bannerhead col-lg-12 pt-20">
          <div className="tp-blog-title-wrapper text-center">
            <h4 className="tp-section-title">BBPS Services</h4>
          </div>
        </div>
        <div className='services_banner'>
          <img src="./assets/img/service/comingsoon.png" alt="" />
        </div>
        <div className='banner_subtext'>
          <p>Get ready for the experience as we prepare to launch a services that will reshape the way you live, work.</p>
        </div>
      </div>
    </>
  )
}

export default Service