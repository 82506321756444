import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaShare } from "react-icons/fa";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const My_Profile_Details = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  return (
    <>
      <div className="main_fragnent_container p-2">
        
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>
              {loginData.Full_Name} | {loginData.userName}
            </span>
          </div>
          <div className="profile_co_header_right">
            {/* <div className="header_box width1">
              <span>Add Money To Wallet</span>
            </div>
            <div className="header_box width1">
              <span>Add Money To Sattlement</span>
            </div> */}
            <div className="header_box width2">
              <Link to="/dashboard/welcome-letter">
                <span>Welcome Letter</span>
              </Link>
            </div>
            <div className="header_box width2">
              <Link to="/dashboard/id-card">
                <span>Print ID Card</span>
              </Link>
            </div>
            <div className="header_box width2">
              <Link to="/dashboard/certificates">
                <span>Certificates</span>
              </Link>
            </div>
            <div className="header_box width2">
              <Link to="/dashboard/documents">
                <span>Documents</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="profile_detail_container">
          <div className="profile_detail_left">
            <div className="profile_card">
              <div className="profile_card_header">
                <span>Profile</span>
              </div>
              <div className="proile_image_card">
                <div className="image_card_box">
                  <img src={loginData.user_photo} alt="" />
                  {/* <img
                    src="https://res.cloudinary.com/dexterous-technology/image/upload/v1715781512/sub-collection1_slfywq.jpg"
                    alt=""
                  /> */}
                </div>
                <div className="image_card_detais">
                  <ul>
                    <li>Rank :   </li>
                    <li>Name : {loginData.Full_Name} </li>
                    <li>Code : {loginData.userName} </li>
                    <li>Phone No : {loginData.mobile} </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="personal_card mt-3">
              <div className="profile_card_header">
                <span>Personal Details</span>
              </div>
              <div className="personal_detail">
                <div className="display_personal">
                  <span className="personal_details">Full Name :</span>
                  <span className="personal_value_details">
                    {loginData.Full_Name}
                  </span>
                </div>
                <div className="display_personal">
                  <span className="personal_details">Date Of Birth :</span>
                  <span className="personal_value_details">
                    {loginData.Date_Of_Birth}
                  </span>
                </div>
                <div className="display_personal">
                  <span className="personal_details">Gender :</span>
                  <span className="personal_value_details">
                    {loginData.Gender}
                  </span>
                </div>
                <div className="display_personal">
                  <span className="personal_details">Email :</span>
                  <span className="personal_value_details">
                    {loginData.email}
                  </span>
                </div>
                <div className="display_personal">
                  <span className="personal_details">PAN Number :</span>
                  <span className="personal_value_details">
                    {loginData.Pan_Card_No}
                  </span>
                </div>
                <div className="display_personal">
                  <span className="personal_details">Aadhaar :</span>
                  <span className="personal_value_details">
                    {loginData.Aadhaar_No}
                  </span>
                </div>
                <div className="display_personal">
                  <span className="personal_details">Address :</span>
                  <span className="personal_value_details">
                    {loginData.Complete_Address}
                  </span>
                </div>
                <div className="display_personal">
                  <span className="personal_details">Branch :</span>
                  <span className="personal_value_details">
                    Main Branch <FaShare color="#007bff" className="mx-1" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_detail_right"></div>
        </div>
      </div>
    </>
  );
};

export default My_Profile_Details;
