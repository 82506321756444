import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";

const ID_Card = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
              .letter_content {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1vh;
  border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.card_container {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_box {
  width: 45vh;
  height: 55vh;
  /* background: #00324d; */
  border: 1px solid #000;
  padding: 1vh;
}
.card_box_header {
  width: 100%;
  height: 8%;
  /* background: #00324d; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #000;
}
.card_box_header p {
  width: 100%;
  height: 50%;
  color: #020214;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  line-height: 7px;
}

.card_image_container {
  width: 100%;
  height: 57%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}
.card_image_container img {
  max-height: 98%;
  object-fit: contain;
}

.card_detail {
  width: 100%;
  height: 22%;
  display: flex;
  justify-content: center;
  padding:1vh;
  flex-direction: column;
}
.card_detail p {
  color: #e11212;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
  text-transform: uppercase;
}
.card_detail span {
  color: #020214;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
              </style>
            </head>
            <body>
            <div class="letter_content">
            <div class="card_container">
            <div class="card_box">
              <div class="card_box_header">
               <p>SUPER REX PRODUCTS PRIVATE LIMITED</p>
                <p>U51909AP2021PTC120313</p>
              </div>
              <div class="card_image_container">
                <img src=${loginData.user_photo} alt="" />
              </div>
              <div class="card_detail">
                <p>${loginData.Full_Name}</p>
                <span>BRANCH : MAIN BRANCH</span>
                <span>DESIGNATION : ${loginData.role}</span>
                <span>CSP CODE : ${loginData.userName}</span>
                <span>MOBILE NO. : ${loginData.mobile}</span>
              </div>
            </div>
            </div>
          </div>
            </body>
          </html>
        `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>
              {loginData.Full_Name} | {loginData.userName}
            </span>
          </div>
          <div className="profile_co_header_right">
            <div
              className="header_box width2 currsor_po"
              onClick={() => handlePrint()}
            >
              <span>
                <FaPrint className="mx-1" />
                Print
              </span>
            </div>
          </div>
        </div>
        <div className="letter_content">
          <div className="card_container">
            <div className="card_box">
              <div className="card_box_header">
                <p>SUPER REX PRODUCTS PRIVATE LIMITED</p>
                <p>U51909AP2021PTC120313</p>
              </div>
              <div className="card_image_container">
                <img src={loginData.user_photo} alt="" />
              </div>
              <div className="card_detail">
                <p>{loginData.Full_Name}</p>
                <span>BRANCH : MAIN BRANCH</span>
                <span>DESIGNATION : {loginData.role}</span>
                <span>CSP CODE : {loginData.userName}</span>
                <span>MOBILE NO. : {loginData.mobile}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ID_Card;
