import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Agent_Credentials from "./registation/Agent_Credentials";
import Personal_information from "./registation/Personal_information";
import Credentials_Recheck from "./registation/Credentials_Recheck";
import Shop_Details from "./registation/Shop_Details";
import ImageUploade from "./registation/ImageUploade";
import { useDispatch, useSelector } from "react-redux";
import { setForm_status } from "../redux/authentication/AuthenticationSlice";

const Agent_Registation = () => {
  const dispatch = useDispatch();

  const { pageStatus, formSteps_status } = useSelector(
    (store) => store.Authentication
  );

  const agentsteps = (param) => {
    switch (param) {
      case 1:
        return <Agent_Credentials />;
      case 2:
        return <Personal_information />;
      case 3:
        return <Shop_Details />;
      case 4:
        return <ImageUploade />;
      default:
        return <Agent_Credentials />;
        break;
    }
  };

  return (
    <>
      <div className="main_fragnent_container">
        <div className="main_recha_container">
          {" "}
          <Stepper activeStep={pageStatus - 1} alternativeLabel>
            <Step key={1}>
              <StepLabel
                style={
                  pageStatus >= 1
                    ? {
                        cursor: "pointer",
                      }
                    : {}
                }
                onClick={() => {
                  if (pageStatus >= 1) {
                    dispatch(setForm_status(1));
                  }
                }}
              >
                Agent Credentials
              </StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel
                style={
                  pageStatus >= 2
                    ? {
                        cursor: "pointer",
                      }
                    : {}
                }
                onClick={() => {
                  if (pageStatus >= 2) {
                    dispatch(setForm_status(2));
                  }
                }}
              >
                Personal Information
              </StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel
                style={
                  pageStatus >= 3
                    ? {
                        cursor: "pointer",
                      }
                    : {}
                }
                onClick={() => {
                  if (pageStatus >= 3) {
                    dispatch(setForm_status(3));
                  }
                }}
              >
                Shop Details
              </StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel
                style={
                  pageStatus >= 4
                    ? {
                        cursor: "pointer",
                      }
                    : {}
                }
                onClick={() => {
                  if (pageStatus >= 4) {
                    dispatch(setForm_status(4));
                  }
                }}
              >
                Image Uploade
              </StepLabel>
            </Step>
          </Stepper>
        </div>
        <div className="reg_box">{agentsteps(formSteps_status)}</div>
      </div>
    </>
  );
};

export default Agent_Registation;
