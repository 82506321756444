import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import {
  new_user_Update,
  setForm_status,
  validate_User_Aadhar,
  validate_User_Pancard,
} from "../../redux/authentication/AuthenticationSlice";
import {
  generate_aadhar_otp,
  verify_aadhar_otp,
  verify_pancard_number,
} from "../../redux/services/ServiceSlice";

import { Typography, message } from "antd";

const Personal_information = () => {
  const dispatch = useDispatch();
  const { new_user_registation } = useSelector((store) => store.Authentication);

  const [Full_Name, setFull_Name] = useState("");
  const [Occupation, setOccupation] = useState("");
  const [Father_Name, setFather_Name] = useState("");
  const [Mother_Name, setMother_Name] = useState("");
  const [Gender, setGender] = useState("");
  const [Date_Of_Birth, setDate_Of_Birth] = useState(null);
  const [Aadhaar_No, setAadhaar_No] = useState("");
  const [Aadhaar_No_confirm, setAadhaar_No_confirm] = useState("");
  const [Pan_Card_No, setPan_Card_No] = useState("");
  const [Pan_Card_No_conirm, setPan_Card_No_Confirm] = useState("");
  const [Pin_Code, setPin_Code] = useState("");
  const [Pin_CodeConirm, setPin_CodeConfirm] = useState("");
  const [City, setCity] = useState("");
  const [District, setDistrict] = useState("");
  const [State, setState] = useState("");
  const [Complete_Address, setComplete_Address] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const [Full_NameError, setFull_NameError] = useState("");
  const [OccupationError, setOccupationError] = useState("");
  const [Father_NameError, setFather_NameError] = useState("");
  const [Mother_NameError, setMother_NameError] = useState("");
  const [GenderError, setGenderError] = useState("");
  const [Date_Of_BirthError, setDate_Of_BirthError] = useState("");
  const [Aadhaar_NoError, setAadhaar_NoError] = useState("");
  const [Pan_Card_NoError, setPan_Card_NoError] = useState("");
  const [Pin_CodeError, setPin_CodeError] = useState("");
  const [CityError, setCityError] = useState("");
  const [DistrictError, setDistrictError] = useState("");
  const [StateError, setStateError] = useState("");
  const [Complete_AddressError, setComplete_AddressError] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  const [generate_aadhar_otp_loading, setgenerate_aadhar_otp_loading] =
    useState(false);
  const [generate_aadhar_otp_disable, setgenerate_aadhar_otp_disable] =
    useState(false);
  const [aadhar_otp_marchentId, setaadhar_otp_marchentId] = useState("");
  const [show_aadhar_otp, setshow_aadhar_otp] = useState(false);
  const [aadhar_otp, setaadhar_otp] = useState("");
  const [aadhar_otp_cnf, setaadhar_otp_cnf] = useState("");
  const [aadhar_otp_error, setaadhar_otp_error] = useState("");

  const [verify_aadhar_otp_loading, setverify_aadhar_otp_loading] =
    useState(false);
  const [verify_aadhar_otp_disable, setverify_aadhar_otp_disable] =
    useState(false);

  const [verify_pancard_loading, setverify_pancard_loading] = useState(false);
  const [verify_pancard_disable, setverify_pancard_disable] = useState(false);

  useEffect(() => {
    if (new_user_registation.formStep > 2) {
      setFull_Name(new_user_registation.Full_Name);
      setOccupation(new_user_registation.Occupation);
      setFather_Name(new_user_registation.Father_Name);
      setMother_Name(new_user_registation.Mother_Name);
      setGender(new_user_registation.Gender);
      setDate_Of_Birth(new_user_registation.Date_Of_Birth);
      setAadhaar_No(new_user_registation.Aadhaar_No);
      setAadhaar_No_confirm(new_user_registation.Aadhaar_No);
      setPan_Card_No(new_user_registation.Pan_Card_No);
      setPan_Card_No_Confirm(new_user_registation.Pan_Card_No);
      setPin_Code(new_user_registation.Pin_Code);
      setPin_CodeConfirm(new_user_registation.Pin_Code);
      setCity(new_user_registation.City);
      setDistrict(new_user_registation.District);
      setState(new_user_registation.State);
      setComplete_Address(new_user_registation.Complete_Address);
    }
  }, [new_user_registation]);

  const genders = [{ role: "Male" }, { role: "Female" }, { role: "Other" }];
  const occupations = [
    { role: "Student" },
    { role: "Unemployed" },
    { role: "Bussiness" },
    { role: "Doctor" },
    { role: "Engineer" },
    { role: "Advocate" },
    { role: "Architech" },
    { role: "Aervice Man" },
    { role: "HouseWife or HomeMaker" },
    { role: "Govt. Employee" },
    { role: "Pvt. Employee" },
  ];

  useEffect(() => {
    if (btnPress === true) {
      if (Full_Name === "") {
        setFull_NameError("Required");
      } else {
        setFull_NameError("");
      }
      if (Occupation === "") {
        setOccupationError("Required");
      } else {
        setOccupationError("");
      }
      if (Father_Name === "") {
        setFather_NameError("Required");
      } else {
        setFather_NameError("");
      }
      if (Mother_Name === "") {
        setMother_NameError("Required");
      } else {
        setMother_NameError("");
      }
      if (Gender === "") {
        setGenderError("Required");
      } else {
        setGenderError("");
      }
      if (Date_Of_Birth === null) {
        setDate_Of_BirthError("Required");
      } else {
        setDate_Of_BirthError("");
      }
      if (Aadhaar_No_confirm === "") {
        setAadhaar_NoError("Required");
      } else {
        setAadhaar_NoError("");
      }
      if (Pan_Card_No_conirm === "") {
        setPan_Card_NoError("Required");
      } else {
        setPan_Card_NoError("");
      }
      if (Pin_CodeConirm === "") {
        setPin_CodeError("Required");
      } else {
        setPin_CodeError("");
      }
      if (City === "") {
        setCityError("Required");
      } else {
        setCityError("");
      }
      if (District === "") {
        setDistrictError("Required");
      } else {
        setDistrictError("");
      }
      if (State === "") {
        setStateError("Required");
      } else {
        setStateError("");
      }
      if (Complete_Address === "") {
        setComplete_AddressError("Required");
      } else {
        setComplete_AddressError("");
      }
    }
  }, [
    btnPress,
    Full_Name,
    Occupation,
    Father_Name,
    Gender,
    Aadhaar_No_confirm,
    Pan_Card_No_conirm,
    Pin_CodeConirm,
    City,
    District,
    State,
    Complete_Address,
    Date_Of_Birth,
    Mother_Name,
  ]);

  const validatePancardno = async (e) => {
    const inputValue = e.target.value.toUpperCase();
    const pancardRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    setPan_Card_No(inputValue);
    if (!pancardRegex.test(inputValue)) {
      setPan_Card_NoError("*Error! you have entered invalid pan card.");
    } else {
      const verifyPancard = await dispatch(validate_User_Pancard(inputValue));
      if (verifyPancard.payload.success === true) {
        setPan_Card_NoError("pan card already exits");
      } else {
        setPan_Card_No_Confirm(inputValue);
        setPan_Card_NoError("");
      }
    }
  };

  const validateAadharcard = async (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").toUpperCase();
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    setAadhaar_No(inputValue);
    if (inputValue.length !== 12) {
      setAadhaar_NoError("*Error! you have entered invalid Aadhar number.");
    } else {
      const verify_number = await dispatch(validate_User_Aadhar(inputValue));
      if (verify_number.payload.success === true) {
        setAadhaar_NoError("Aadhar number already exits");
        // setmobile("");
      } else {
        setAadhaar_No_confirm(inputValue);
        setAadhaar_NoError("");
      }
    }
  };
  const validateAadhar_OTP = async (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    setaadhar_otp(inputValue);
    if (inputValue.length !== 6) {
      setaadhar_otp_error("OTP must be 6 digit length");
    } else {
      setaadhar_otp_cnf(inputValue);
      setaadhar_otp_error("");
    }
  };

  const user_Update_click = async () => {
    setbtnLoading(true);
    setbtnPress(true);

    if (
      Full_Name !== "" &&
      Occupation !== "" &&
      Father_Name !== "" &&
      Gender !== "" &&
      Aadhaar_No_confirm !== "" &&
      Pan_Card_No_conirm !== "" &&
      Pin_CodeConirm !== "" &&
      City !== "" &&
      District !== "" &&
      State !== "" &&
      Complete_Address !== "" &&
      // Date_Of_Birth !== null &&
      Mother_Name !== ""
    ) {
      const form_data = {
        Full_Name: Full_Name,
        Occupation: Occupation,
        Father_Name: Father_Name,
        Mother_Name: Mother_Name,
        Gender: Gender,
        Date_Of_Birth: Date_Of_Birth,
        Aadhaar_No: Aadhaar_No_confirm,
        Pan_Card_No: Pan_Card_No_conirm,
        Pin_Code: Pin_CodeConirm,
        City: City,
        District: District,
        State: State,
        Complete_Address: Complete_Address,
        formStep: 3,
        userId: new_user_registation._id,
      };
      const new_user_Updates = await dispatch(new_user_Update(form_data));
      if (new_user_Updates.payload.success) {
        setFull_Name("");
        setbtnLoading(false);
        setbtnPress(false);
      }
    } else {
      setbtnLoading(false);
    }
  };

  const generate_addhar_otp = async () => {
    setgenerate_aadhar_otp_loading(true);
    setgenerate_aadhar_otp_disable(true);
    if (Aadhaar_No_confirm === "") {
      setAadhaar_NoError("Required");
    } else {
      setAadhaar_NoError("");
    }
    if (Aadhaar_No_confirm !== "") {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });
      const form_data = {
        aadhar_number: Aadhaar_No_confirm,
      };
      const generate_otp = await dispatch(generate_aadhar_otp(form_data));
      if (generate_otp.payload.success) {
        const respo = generate_otp.payload.response;
        setaadhar_otp_marchentId(respo.merchantTxnRefId);
        setshow_aadhar_otp(true);
        if (respo.status === "Success") {
          messageApi.open({
            type: "success",
            content:
              "OTP generated and sent successfully on the registered mobile number",
          });
        } else {
          messageApi.open({
            type: "error",
            content: respo.description,
          });

          setgenerate_aadhar_otp_disable(false);
        }
      } else {
      }
      setgenerate_aadhar_otp_loading(false);
    } else {
      setgenerate_aadhar_otp_loading(false);
      setgenerate_aadhar_otp_disable(false);
    }
  };

  const verify_addhar_otp = async () => {
    setverify_aadhar_otp_loading(true);
    setverify_aadhar_otp_disable(true);
    if (aadhar_otp_cnf === "") {
      setaadhar_otp_error("Required");
    } else {
      setaadhar_otp_error("");
    }

    if (aadhar_otp_cnf !== "" && aadhar_otp_marchentId !== "") {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });
      const form_data = {
        merchantTxnRefId: aadhar_otp_marchentId,
        otp: aadhar_otp_cnf,
      };
      const verify_otp = await dispatch(verify_aadhar_otp(form_data));
      if (verify_otp.payload.success) {
        const respo = verify_otp.payload.response;
        if (respo.status === "Success") {
          messageApi.open({
            type: "success",
            content: respo.description,
          });
          const form_data = {
            aadhar_no_verify: true,
            userId: new_user_registation._id,
          };
          const new_user_Updates = await dispatch(new_user_Update(form_data));
        } else {
          messageApi.open({
            type: "error",
            content: respo.description,
          });
          setverify_aadhar_otp_disable(false);
        }
      } else {
      }
    } else {
      setverify_aadhar_otp_loading(false);
      setverify_aadhar_otp_disable(false);
    }
  };
  const verify_pancard = async () => {
    setverify_pancard_loading(true);
    setverify_pancard_disable(true);
    if (Pan_Card_No_conirm === "") {
      setPan_Card_NoError("Required");
    } else {
      setPan_Card_NoError("");
    }

    if (Pan_Card_No_conirm !== "") {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });
      const form_data = {
        pancard: Pan_Card_No_conirm,
      };
      const verify_otp = await dispatch(verify_pancard_number(form_data));
      if (verify_otp.payload.success) {
        const respo = verify_otp.payload.response;
        if (respo.status === "Success") {
          messageApi.open({
            type: "success",
            content: respo.description,
          });
          const form_data = {
            pan_card_verify: true,
            userId: new_user_registation._id,
          };
          const new_user_Updates = await dispatch(new_user_Update(form_data));
        } else {
          messageApi.open({
            type: "error",
            content: respo.description,
          });
          setverify_pancard_disable(false);
        }
      } else {
      }
      setverify_pancard_loading(false);
    } else {
      setverify_pancard_loading(false);
      setverify_pancard_disable(false);
    }
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="inputs_container">
        <div className="dn_input_box">
          <TextField
            label="Full Name"
            type="text"
            id="text"
            className="input_style text_tranfer"
            error={Full_NameError !== "" ? true : false}
            helperText={Full_NameError}
            value={Full_Name}
            onChange={(e) =>
              setFull_Name(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
            style={{
              textTransform: "capitalize",
            }}
          />
        </div>
        <div className="dn_input_box">
          <TextField
            id="select"
            label="Occupation"
            select
            className="input_style"
            error={OccupationError !== "" ? true : false}
            helperText={OccupationError}
            value={Occupation}
            onChange={(e) =>
              setOccupation(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            size="small"
          >
            {occupations.map((data, index) => (
              <MenuItem key={index} value={data.role}>
                {data.role}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="dn_input_box">
          <TextField
            label="Father's Name"
            type="text"
            id="text"
            className="input_style"
            error={Father_NameError !== "" ? true : false}
            helperText={Father_NameError}
            value={Father_Name}
            onChange={(e) =>
              setFather_Name(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="Mother's Name"
            type="text"
            id="text"
            className="input_style"
            error={Mother_NameError !== "" ? true : false}
            helperText={Mother_NameError}
            value={Mother_Name}
            onChange={(e) =>
              setMother_Name(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            id="select"
            label="Gender"
            select
            className="input_style"
            error={GenderError !== "" ? true : false}
            helperText={GenderError}
            value={Gender}
            onChange={(e) =>
              setGender(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            size="small"
          >
            {genders.map((data, index) => (
              <MenuItem key={index} value={data.role}>
                {data.role}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="dn_input_box">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date Of Birth"
              className="input_style"
              size="small"
              // value={Date_Of_Birth}
              onChange={(e) => {
                const date = JSON.stringify(moment(e).format("DD/MM/YYYY"));
                setDate_Of_Birth(date);
              }}
              renderInput={(params) => (
                <TextField
                  error={Date_Of_BirthError !== "" ? true : false}
                  helperText={Date_Of_BirthError}
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="dn_input_box">
          <TextField
            label="Aadhaar No"
            type="text"
            id="text"
            className="input_style"
            error={Aadhaar_NoError !== "" ? true : false}
            helperText={Aadhaar_NoError}
            value={Aadhaar_No}
            onChange={(e) => validateAadharcard(e)}
            autocomplete="off"
            size="small"
            inputProps={{
              minLength: 12,
              maxLength: 12,
            }}
            disabled={generate_aadhar_otp_disable}
          />
        </div>
        <div className="dn_input_box">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={(e) => generate_addhar_otp(e)}
            disabled={generate_aadhar_otp_disable}
          >
            {generate_aadhar_otp_loading ? (
              <>
                Loading
                <LoadingIndicator size={24} />
              </>
            ) : (
              "send otp"
            )}
          </LoadingButton>
        </div>
        {show_aadhar_otp === true && (
          <>
            <div className="dn_input_box">
              <TextField
                label="Aadhar OTP"
                type="text"
                id="text"
                className="input_style"
                error={aadhar_otp_error !== "" ? true : false}
                helperText={aadhar_otp_error}
                value={aadhar_otp}
                onChange={(e) => validateAadhar_OTP(e)}
                autocomplete="off"
                size="small"
                inputProps={{
                  minLength: 6,
                  maxLength: 6,
                }}
                disabled={verify_aadhar_otp_disable}
              />
            </div>
            <div className="dn_input_box">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => verify_addhar_otp(e)}
                disabled={verify_aadhar_otp_disable}
              >
                {verify_aadhar_otp_loading ? (
                  <>
                    Loading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Verify otp"
                )}
              </LoadingButton>
            </div>
          </>
        )}
        <div className="dn_input_box">
          <TextField
            label="Pan Card No"
            type="text"
            id="text"
            className="input_style"
            error={Pan_Card_NoError !== "" ? true : false}
            helperText={Pan_Card_NoError}
            value={Pan_Card_No}
            onChange={(e) => validatePancardno(e)}
            autocomplete="off"
            size="small"
            inputProps={{
              minLength: 10,
              maxLength: 10,
            }}
          />
        </div>
        <div className="dn_input_box">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={(e) => verify_pancard(e)}
            disabled={verify_pancard_disable}
          >
            {verify_pancard_loading ? (
              <>
                Loading
                <LoadingIndicator size={24} />
              </>
            ) : (
              "Verify Pancard"
            )}
          </LoadingButton>
        </div>
        <div className="dn_input_box">
          <TextField
            label="Pin Code"
            type="text"
            id="text"
            className="input_style"
            error={Pin_CodeError !== "" ? true : false}
            helperText={Pin_CodeError}
            value={Pin_Code}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, "");
              setPin_Code(inputValue);
              if (inputValue.length !== 6) {
                setPin_CodeError(
                  "*Error! you have entered invalid Aadhar number."
                );
              } else {
                setPin_CodeConfirm(inputValue);
                setPin_CodeError("");
              }
            }}
            inputProps={{
              minLength: 6,
              maxLength: 6,
            }}
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="City"
            type="text"
            id="text"
            className="input_style"
            error={CityError !== "" ? true : false}
            helperText={CityError}
            value={City}
            onChange={(e) =>
              setCity(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="District"
            type="text"
            id="text"
            className="input_style"
            error={DistrictError !== "" ? true : false}
            helperText={DistrictError}
            value={District}
            onChange={(e) =>
              setDistrict(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="State"
            type="text"
            id="text"
            className="input_style"
            error={StateError !== "" ? true : false}
            helperText={StateError}
            value={State}
            onChange={(e) =>
              setState(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="Complete Address"
            className="input_style"
            error={Complete_AddressError !== "" ? true : false}
            helperText={Complete_AddressError}
            value={Complete_Address}
            onChange={(e) =>
              setComplete_Address(
                e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
              )
            }
            autocomplete="off"
            id="outlined-multiline-static"
            multiline
            rows={4}
          />
        </div>
        <div className="dn_input_box">
          <div className="input_style"></div>
        </div>
        <div className="button_container">
          {new_user_registation.formStep > 2 ? (
            <>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={() => dispatch(setForm_status(3))}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Save"
                )}
              </LoadingButton>
            </>
          ) : (
            <>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => user_Update_click(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    Loading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Personal_information;
