import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setForm_status,
  user_Regisattion,
  validate_User_Email,
  validate_User_Number,
} from "../../redux/authentication/AuthenticationSlice";

const Agent_Credentials = () => {
  const dispatch = useDispatch();

  const { new_user_registation, loginData } = useSelector(
    (store) => store.Authentication
  );

  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [Email_Confirm, setEmail_Confirm] = useState("");
  const [mobile, setmobile] = useState("");
  const [mobile_Confirm, setMobile_Confirm] = useState("");

  const [roleError, setroleError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setmobileError] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  useEffect(() => {
    if (new_user_registation.formStep > 1) {
      setRole(new_user_registation.role);
      setEmail(new_user_registation.email);
      setEmail_Confirm(new_user_registation.email);
      setmobile(new_user_registation.mobile);
      setMobile_Confirm(new_user_registation.mobile);
    }
  }, [new_user_registation]);

  useEffect(() => {
    if (btnPress === true) {
      if (role === "") {
        setroleError("Required");
      } else {
        setroleError("");
      }
      if (Email_Confirm === "") {
        setEmailError("Required");
      } else {
        setEmailError("");
      }
      if (mobile_Confirm === "") {
        setmobileError("Required");
      } else {
        setmobileError("");
      }
    }
  }, [btnPress, role, Email_Confirm, mobile_Confirm]);

  const validateEmail = async (e) => {
    const inputValue = e.target.value;
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    setEmail(inputValue);
    if (!emailRegex.test(inputValue)) {
      setEmailError("*Error! you have entered invalid email.");
    } else {
      const verifyemail = await dispatch(validate_User_Email(inputValue));
      if (verifyemail.payload.success === true) {
        setEmailError("Email already exits");
      } else {
        setEmail_Confirm(inputValue);
        setEmailError("");
      }
    }
  };

  const validateMobile = async (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    setmobile(inputValue);
    if (inputValue.length !== 10) {
      setmobileError("*Error! you have entered invalid mobile number.");
    } else {
      const verify_number = await dispatch(validate_User_Number(inputValue));
      if (verify_number.payload.success === true) {
        setmobileError("Mobile number already exits");
        // setmobile("");
      } else {
        setMobile_Confirm(inputValue);
        setmobileError("");
      }
    }
  };

  const user_Registation_click = async () => {
    setbtnLoading(true);
    setbtnPress(true);

    if (role !== "" && Email_Confirm !== "" && mobile_Confirm !== "") {
      const form_data = {
        role: role,
        email: Email_Confirm,
        mobile: mobile_Confirm,
        password: mobile_Confirm,
        referral_Mobile: loginData.mobile,
        referral_name: loginData.Full_Name,
        referral_code: loginData.userName,
        formStep: 2,
      };
      console.log(form_data, "form_data");
      const new_user_registation = await dispatch(user_Regisattion(form_data));
      if (new_user_registation.payload.success) {
        setRole("");
        setEmail("");
        setEmail_Confirm("");
        setmobile("");
        setMobile_Confirm("");
        setroleError("");
        setEmailError("");
        setmobileError("");
        setbtnLoading(false);
        setbtnPress(false);
      }
    } else {
      setbtnLoading(false);
    }
  };

  const roles = [
    { role: "Retailer" },
    { role: "Distibuter" },
    { role: "Super Distibuter" },
  ];

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));
  return (
    <>
      <div className="inputs_container">
        <div className="dn_input_box">
          <TextField
            id="select"
            label="CSP Type"
            select
            className="input_style"
            error={roleError !== "" ? true : false}
            helperText={roleError}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            size="small"
          >
            {roles.map((data, index) => (
              <MenuItem key={index} value={data.role}>
                {data.role}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="dn_input_box">
          <TextField
            label="Email"
            type="email"
            id="text"
            className="input_style"
            error={emailError !== "" ? true : false}
            helperText={emailError}
            onChange={(e) => validateEmail(e)}
            value={email}
            autocomplete="off"
            size="small"
          />
        </div>
        <div className="dn_input_box">
          <TextField
            label="Mobile"
            type="text"
            id="text"
            className="input_style"
            error={mobileError !== "" ? true : false}
            helperText={mobileError}
            onChange={(e) => validateMobile(e)}
            value={mobile}
            autocomplete="off"
            size="small"
            inputProps={{
              minLength: 10,
              maxLength: 10,
            }}
          />
        </div>
        <div className="dn_input_box">
          <div className="input_style"></div>
        </div>
        <div className="button_container">
          {new_user_registation.formStep > 1 ? (
            <>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={() => dispatch(setForm_status(2))}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Save"
                )}
              </LoadingButton>
            </>
          ) : (
            <>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => user_Registation_click(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Agent_Credentials;
