import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageStatus: 0,
  aadharclientId: "",
  aadharNumber: "",
  kycDetails: "",
  kycLoading: false,
};

const KycSlice = createSlice({
  name: "Kyc",
  initialState,
  reducers: {
    pageStatusChange(state, action) {
      state.pageStatus = action.payload;
    },
    setaadharclientId(state, action) {
      state.aadharclientId = action.payload;
    },
    setaadharNumber(state, action) {
      state.aadharNumber = action.payload;
    },
    sendKyc(state, action) {
      state.kycDetails = action.payload[0];
      state.kycLoading = true
    },
  },
});

export const { pageStatusChange, setaadharclientId, setaadharNumber,sendKyc } =
  KycSlice.actions;
export default KycSlice.reducer;
