import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const Service_Plance = ({ plan_data, sellected_plance, get_plan,select_plan_name }) => {
  const [selected_plan_index, setselected_plan_index] = useState("");

  useEffect(() => {
    setselected_plan_index("");
  }, [select_plan_name]);

  return (
    <>
      <List>
        {plan_data.map((data, index) => (
          <div
            className={
              selected_plan_index === index
                ? "radio_div_container select_plan_bg"
                : "radio_div_container"
            }
            key={index}
            onClick={() => {
              get_plan(data);
              setselected_plan_index(index);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <ListItem alignItems="flex-start">
              {/* <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar> */}
              <ListItemText
                primary={`${data.planName} - ${data.price} - ${data.validity}`}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {data.validityDescription}
                    </Typography>
                    {data.packageDescription}
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        ))}
      </List>
    </>
  );
};

export default Service_Plance;
