import React, { useState } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaShoppingBag } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoPersonCircleOutline, IoReceiptSharp } from "react-icons/io5";
import { AiOutlineTeam } from "react-icons/ai";
import { IoWalletOutline } from "react-icons/io5";
import { FaRegHandshake } from "react-icons/fa6";
import {
  MdAppRegistration,
  MdLockReset,
  MdPassword,
  MdPayments,
} from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { FcMoneyTransfer } from "react-icons/fc";
import { CiCreditCard1 } from "react-icons/ci";
import { GiReceiveMoney } from "react-icons/gi";
import { FaHistory } from "react-icons/fa";
import { LuHistory } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { set_remove_new_registation_details } from "../../redux/authentication/AuthenticationSlice";

const DashboardMenu = ({ menubar_Status, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stateOpenKeys, setStateOpenKeys] = useState(["2", "23"]);

  const items = [
    {
      key: "1",
      icon: <AppstoreOutlined />,
      label: "Dashboard",
      routes: "/dashboard",
    },
    {
      key: "2",
      icon: <IoPersonCircleOutline />,
      label: "My Profile Details",
      routes: "/dashboard/my-profile",
    },
    {
      key: "3",
      icon: <AiOutlineTeam />,
      label: "My Team Member",
      routes: "/dashboard/my-team",
    },
    {
      key: "16",
      icon: <IoWalletOutline />,
      label: "Team Wallet Load",
      routes: "/dashboard",
    },
    {
      key: "4",
      label: "Onbording",
      icon: <FaRegHandshake />,
      children: [
        {
          key: "5",
          label: "New Registation",
          routes: "/dashboard/registation",
          icon: <MdAppRegistration />,
        },
        {
          key: "18",
          label: "Pending Registation",
          routes: "/dashboard/pending-registation",
          icon: <MdOutlinePendingActions />,
        },
      ],
    },
    {
      key: "6",
      label: "Money Tranfer",
      icon: <GiReceiveMoney />,
      children: [
        {
          key: "7",
          label: "Money Tranfer",
          routes: "/dashboard/findremitter",
          icon: <FcMoneyTransfer />,
        },
        {
          key: "17",
          label: "Money Tranfer History",
          routes: "/dashboard/transferlist",
          icon: <FaHistory />,
        },
      ],
    },
    {
      key: "8",
      label: "Credit Card Bill Pay",
      icon: <CiCreditCard1 />,
      children: [
        {
          key: "9",
          label: "Initiate New Payment",
          routes: "/dashboard",
          icon: <MdPayments />,
        },
        {
          key: "10",
          label: "Payment History",
          routes: "/dashboard",
          icon: <LuHistory />,
        },
      ],
    },
    {
      key: "11",
      label: "Recharge & Bill",
      icon: <IoReceiptSharp />,
      children: [
        {
          key: "12",
          label: "Initiate New Payment",
          routes: "/dashboard",
          icon: <MdPayments />,
        },
        {
          key: "13",
          label: "Payment History",
          routes: "/dashboard/bill_payment/bbps-history",
          icon: <LuHistory />,
        },
      ],
    },
    {
      key: "14",
      icon: <MdLockReset />,
      label: "Change Password",
      routes: "/dashboard/change-password",
    },
    {
      key: "15",
      icon: <MdPassword />,
      label: "Change MPin",
      routes: "/dashboard/change-mpin",
    },
  ];

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(items);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const navigationclick = (value) => {
    // console.log(value.item.props.routes, "value");
    const nav_link = value.item.props.routes;
    navigate(`${nav_link}`);
    if (nav_link === "/dashboard/registation") {
      dispatch(set_remove_new_registation_details());
    }
    onClose();
  };

  return (
    <>
      <div className="menu_main_container">
        <Menu
          mode="inline"
          expandIcon={menubar_Status === true ? null : ""}
          defaultSelectedKeys={["231"]}
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          onClick={(e) => navigationclick(e)}
          items={items}
          inlineCollapsed={menubar_Status}
          style={{
            background: "#00324d",
          }}
        />
      </div>
    </>
  );
};

export default DashboardMenu;
