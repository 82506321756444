import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";

const WelcomeLetter = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
          .letter_header {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.letter_header span {
  color: #006eb4;
  font-size: 1.3rem;
  font-weight: 700;
  text-decoration-line: underline;
  font-style: italic;
}
  .letter_grea {
  color: #020214;
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 3vh;
}
.letter_grea_content {
  color: #020214;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 3vh;
  text-align: justify;
}
  table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
  .position_leiier_sign {
  position: relative;
  margin-top: 6vh;
}

.position_leiier_sign img {
  position: absolute;
  z-index: 99;
  bottom: 0;
  height: 5vh;
  margin-bottom: 1.5vh;
}
          </style>
        </head>
        <body>
         <div class="letter_content" id="printablediv">
          <div class="letter_header">
            <span>WELCOME LETTER</span>
          </div>
          <div class="letter_peragrah_box">
            <p class="letter_grea">Dear Clients/Participants & Families,</p>
            <p class="letter_grea_content">
              We would like to welcome you as a New Associate to It is our
              pleasure to be able to work with you or your family member. In
              this sector, we are the fastest growing organization provider with
              the best of our services.
            </p>
            <p class="letter_grea_content">
              This is the personal thanks to you for being a part of our system.
              We hope the service which we are prepared to render will be so
              satisfactory that we shall often have the privilege of meeting
              your requirements, thus allowing us to add your name to our long
              and growing list of friends.
            </p>
            <p class="letter_grea_content">
              You will find, we are sure, the quality of package and the
              advantages of the system exactly as presented by our
              representative. We do hope that our services will lead us more and
              more opportunities to serve you the best of our side, in the near
              future.
            </p>
            <p class="letter_grea_content">
              Please find the below mentioned details of your enrollment with as
              :
            </p>
            <p class="letter_grea_content"></p>

            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>CSP Code</td>
                  <td>${loginData.userName}</td>
                  <td>Associate Name</td>
                  <td>${loginData.Full_Name}</td>
                </tr>
                <tr>
                  <td>CSP Name</td>
                  <td>SUPER REX PRODUCTS PRIVATE LIMITED</td>
                  <td>CSP Email</td>
                  <td>${loginData.email}</td>
                </tr>
                <tr>
                  <td>Mobile</td>
                  <td>${loginData.mobile}</td>
                  <td>Aadhar Number</td>
                  <td>${loginData.Aadhaar_No}</td>
                </tr>
                <tr>
                  <td>CSP Type</td>
                  <td>${loginData.role}</td>
                  <td>Joining Date</td>
                  <td>22-04-2024</td>
                </tr>
                <tr>
                  <td>CSP Address</td>
                  <td colspan="3">${loginData.Complete_Address}</td>
                </tr>
              </tbody>
            </table>
           <p class="letter_grea_content position_leiier_sign">
              <img src="/assets/img/logo/sign.png" alt="" />
              Very truly yours,
            </p>
            <p class="letter_grea_content"></p>
          </div>
        </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>
              {loginData.Full_Name} | {loginData.userName}
            </span>
          </div>
          <div className="profile_co_header_right">
            <div
              className="header_box width2 currsor_po"
              onClick={() => handlePrint()}
              //   onClick={() => Print()}
            >
              <span>
                <FaPrint className="mx-1" />
                Print
              </span>
            </div>
          </div>
        </div>
        <div className="letter_content">
          <div className="letter_header">
            <span>WELCOME LETTER</span>
          </div>
          <div className="letter_peragrah_box">
            <p className="letter_grea">Dear Clients/Participants & Families,</p>
            <p className="letter_grea_content">
              We would like to welcome you as a New Associate to It is our
              pleasure to be able to work with you or your family member. In
              this sector, we are the fastest growing organization provider with
              the best of our services.
            </p>
            <p className="letter_grea_content">
              This is the personal thanks to you for being a part of our system.
              We hope the service which we are prepared to render will be so
              satisfactory that we shall often have the privilege of meeting
              your requirements, thus allowing us to add your name to our long
              and growing list of friends.
            </p>
            <p className="letter_grea_content">
              You will find, we are sure, the quality of package and the
              advantages of the system exactly as presented by our
              representative. We do hope that our services will lead us more and
              more opportunities to serve you the best of our side, in the near
              future.
            </p>
            <p className="letter_grea_content">
              Please find the below mentioned details of your enrollment with as
              :
            </p>
            <p className="letter_grea_content"></p>

            <table className="table_respo" style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>CSP Code</td>
                  <td>{loginData.userName}</td>
                  <td>Associate Name</td>
                  <td>{loginData.Full_Name}</td>
                </tr>
                <tr>
                  <td>CSP Name</td>
                  <td>SUPER REX PRODUCTS PRIVATE LIMITED</td>
                  <td>CSP Email</td>
                  <td>{loginData.email}</td>
                </tr>
                <tr>
                  <td>Mobile</td>
                  <td>{loginData.mobile}</td>
                  <td>Aadhar Number</td>
                  <td> {loginData.Aadhaar_No}</td>
                </tr>
                <tr>
                  <td>CSP Type</td>
                  <td>{loginData.role}</td>
                  <td>Joining Date</td>
                  <td>22-04-2024</td>
                </tr>
                <tr>
                  <td>CSP Address</td>
                  <td colspan="3">{loginData.Complete_Address}</td>
                </tr>
              </tbody>
            </table>
            <p className="letter_grea_content position_leiier_sign">
              <img src="/assets/img/logo/sign.png" alt="" />
              Very truly yours,
            </p>
            <p className="letter_grea_content"></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeLetter;
