import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import Alert from "@mui/material/Alert";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { Typography, message } from "antd";

const Bbps_Bill_Info = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { bill_transations } = useSelector((state) => state.services);

  const [payment_success, setpayment_success] = useState("");

  useEffect(() => {
    const slugurl = params.bill_info;
    const find_info = bill_transations.find((data) => data._id === slugurl);
    setpayment_success(find_info);
  }, [bill_transations]);

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
          .image_suss_box {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_suss_box img {
  width: 20%;
  height: 50%;
}
  .class_capitalize {
  text-transform: lowercase !important;
}
  .inputs_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2vh;
  padding-top: 3vh;
  display: flex;
  justify-content: center;
  padding-bottom: 3vh;
}
.inputs_container .dn_input_box {
  width: 48%;
  display: flex;
  justify-content: center;
}
  .dn_input_box .name_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vh;
  border-bottom: 1px solid gainsboro;
}
.inputs_container .success_input_box {
  width: 98%;
  display: flex;
  justify-content: center;
}
  .name_details {
  color: #060264;
  font-size: 1rem;
  font-weight: 600;
}
.value_details {
  color: #0c0c10;
  font-size: 1rem;
  font-weight: 600;
}
         </style>
        </head>
        <body>
         <div class="main_fragnent_container">
        <div class="main_recha_container">
          <div class="inputs_container">
                <div class="image_suss_box">
                  <img src="/assets/img/logo/bill_logo.png" alt="" />
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NAME :</span>
                    <span class="value_details">
                      ${payment_success.name}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">NUMBER :</span>
                    <span class="value_details">
                    ${payment_success.mobile}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">AMOUNT :</span>
                    <span class="value_details">
                     ${payment_success.paid_amount}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">BILLER NAME:</span>
                    <span class="value_details">
                      ${payment_success.billerName}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">CATEGORY NAME:</span>
                    <span class="value_details">
                      ${payment_success.category}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT MODE :</span>
                    <span class="value_details">
                      ${payment_success.paymentMode}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">PAYMENT STATUS :</span>
                    <span class="value_details">
                      ${payment_success.status}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">DATE :</span>
                    <span class="value_details">
                      ${payment_success.billDate}
                    </span>
                  </div>
                </div>
                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">REFERANCE ID :</span>
                    <span class="value_details">
                      ${payment_success.txnReferenceId}
                    </span>
                  </div>
                </div>

                <div class="dn_input_box">
                  <div class="name_box">
                    <span class="name_details">TRANSACTION ID :</span>
                    <span class="value_details">
                      ${payment_success.trxn_id}
                    </span>
                  </div>
                </div>

                <div class="success_input_box">
                  <div class="name_box">
                    <span class="name_details">
                      MESSAGE / FAILED REASON :
                      <span class="value_details class_capitalize">
                        TRANSACTION IS PROCESSED AND THE MONEY HAS BEEN CREDITED
                        INTO THE BENEFICIARY ACCOUNT.
                      </span>
                    </span>
                  </div>
                </div>
                <div class="thank_note">
                  <span class="value_details class_capitalize">
                    THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                    MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                  </span>
                </div>
          
              </div>
              </div>
              </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };
  return (
    <>
      {payment_success !== "" && (
        <div className="main_fragnent_container">
          <div className="main_recha_container">
            <div className="inputs_container">
              <div className="image_suss_box">
                <img src="/assets/img/logo/bill_logo.png" alt="" />
              </div>
              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">NAME :</span>
                  <span className="value_details">{payment_success.name}</span>
                </div>
              </div>
              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">NUMBER :</span>
                  <span className="value_details">
                    {payment_success.mobile}
                  </span>
                </div>
              </div>
              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">AMOUNT :</span>
                  <span className="value_details">
                    {payment_success.paid_amount}
                  </span>
                </div>
              </div>

              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">BILLER NAME:</span>
                  <span className="value_details">
                    {payment_success.billerName}
                  </span>
                </div>
              </div>
              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">CATEGORY NAME:</span>
                  <span className="value_details">
                    {payment_success.category}
                  </span>
                </div>
              </div>
              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">PAYMENT MODE :</span>
                  <span className="value_details">
                    {payment_success.paymentMode}
                  </span>
                </div>
              </div>

              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">PAYMENT STATUS :</span>
                  <span className="value_details">
                    {payment_success.status}
                  </span>
                </div>
              </div>
              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">DATE :</span>
                  <span className="value_details">
                    {payment_success.billDate}
                  </span>
                </div>
              </div>
              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">REFERANCE ID :</span>
                  <span className="value_details">
                    {payment_success.txnReferenceId}
                  </span>
                </div>
              </div>

              <div className="dn_input_box">
                <div className="name_box">
                  <span className="name_details">TRANSACTION ID :</span>
                  <span className="value_details">
                    {payment_success.trxn_id}
                  </span>
                </div>
              </div>

              <div className="success_input_box">
                <div className="name_box">
                  <span className="name_details">
                    MESSAGE / FAILED REASON :{" "}
                    <span className="value_details class_capitalize">
                      TRANSACTION IS PROCESSED AND THE MONEY HAS BEEN CREDITED
                      INTO THE BENEFICIARY ACCOUNT.
                    </span>
                  </span>
                </div>
              </div>
              <div className="thank_note">
                <span className="value_details class_capitalize">
                  THANK YOU FOR YOUR BUSINESS !! PAYMENT IS EXPECTED WITHIN 10
                  MINUTES IN YOUR ACCOUNT. FOR MOR DETAIL CONNECT TO ADMIN.
                </span>
              </div>
              <div className="profile_co_header_right">
                <div
                  className="header_box width2 currsor_po"
                  onClick={() => handlePrint()}
                >
                  <span>Print Receipt</span>
                </div>
                <div
                  className="header_box width1 currsor_po"
                  onClick={() => handlePrint()}
                >
                  <span>Download Receipt</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Bbps_Bill_Info;
